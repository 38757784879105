import React, {
  useState,
  useEffect,
} from 'react';
import {
  isMapOpen, getPolygonData
} from '../../services/database.service'

export default function Myframe(props) {
  const { url, width = '100%', src, openMap } = props;

  useEffect(() => {
    const mapClick = () => {
      let mapBtn = document.querySelectorAll('.fileMapButton');

      Array.from(mapBtn).map(async (bt) => {
        bt.addEventListener('click', function (e) {
          openMap();
          let interval = setInterval(() => {
            if (!isMapOpen()) {
              let polygon = getPolygonData();
              if(polygon){
                bt.parentNode.querySelector('textarea').value = JSON.stringify(polygon)
              }
              //console.log('obrigado por fechar', polygon);
              clearInterval(interval);
            }
          }, 1000)
        })
      })

    }

    mapClick();
    document.querySelector('.reload')?.click();

  }, [])

  return (<>
    <div className={`form-container flex-fill w-100 m-0`} dangerouslySetInnerHTML={{ __html: src ? src : "Sem pré-visualização" }}>
    </div>
  </>)
};