import React, { useState, useEffect, useRef } from "react";

import api from "../../services/api";
import { addFormData } from "../../services/database.service";
import { resetCurrentPolygon } from "../../services/database.service";
import validator from "../../services/validator.service";
import ConfirmBox from "../../components/ConfirmBox";
import DATATABLE from "../../components/DataTable/dataTable";
import disable from "../../utils/disableInputs";
import Box from "@material-ui/core/Box";
import { Col, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Actions from "../../components/Actions";
import Myframe from "../Myframe";
import fundo_base_vertical from "../../assets/images/fundo_base-vertical.png";
import s_image from "../../assets/images/s30.png";
import StringMessage from "../../utils/string-messages";
import { holdManyFiles } from "../../utils/html";
import AddButton from "../../components/AddButton";
import sleep from "../../utils/sleep";

const columns = [
  {
    label: "#",
    field: "num",
    sort: "asc",
    width: 10,
  },
  {
    label: "Modelo",
    field: "nome",
    sort: "asc",
    width: 300,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 300,
  },
  {
    label: "Acções",
    field: "action",
    sort: "asc",
    width: 300,
  },
];

const userApiService = new api();
const validation = new validator();

export default function FormBuilder(props) {
  const [iframeTitle, setIframeTitle] = useState("Formulário");
  const [formPreview, setFormPreview] = useState(false);
  const [currentForm, setCurrentForm] = useState([]);
  const [openForm, setOpenForm] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [formSubmit, setFormSubmit] = useState();
  const [isWizard, setIsWizard] = useState();
  const [wizardSize, setWizardSize] = useState(1);
  const [formPage, setFormPage] = useState(1);
  const [codigo, setCodigo] = useState("");
  const [wizardNext, setWizardNext] = useState(null);
  const [wizardPrev, setWizardPrev] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [tableData, setTableData] = useState({});
  const [formsList, setFormsList] = useState([]);
  const [list, setList] = useState([]);
  const [createAnotherForm, setCreateAnotherForm] = useState(false);
  const [hasIframeContent, setHasIframeContent] = useState(false);
  var [_contentWindow, setContentWindow] = useState(null);
  const checkboxRef = useRef();
  const [back, setBack] = useState("");
  const [cancel, setCancel] = useState("");
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  var parent = null;
  // var _contentWindow = null;

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    async function getSortedList() {
      let list = (
        (await userApiService.getSortedFormBuilderForms()) ?? []
      ).reverse();

      setFormsList(list);
      loadTable(list);
    }

    getSortedList();

    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);

    var formSaveButton = document.getElementById("formSaveButton");
    if (formSaveButton) {
      //console.log("formSaveButton", formSaveButton);
    }

    window.addEventListener("message", async (event) => {
      const { data, source } = event;
      // //console.log("DATA RECEIVED FROM IFRAME", data)
      if (data !== "form-builder-saved") {
        return;
      }

      if (parent === null) {
        parent = source;
      }

      const dataReceived = JSON.stringify(data, null, 2);
      // //console.log("REAL DATA", dataReceived)
      // //console.log("CHECKBOXREF", checkboxRef?.current?.checked)

      await sleep(1500);
      if (checkboxRef?.current?.checked) {
        var iframe = document.getElementById("ifrFormBuilder");
        if (iframe) {
          iframe.src = iframe?.src;
        }
        // //console.log("SRC", _contentWindow)
        setCreateAnotherForm(false);
      } else {
        loadTable();
        setOpenForm(0);
        setCreateAnotherForm(false);
      }
    });

    return () => {
      window.removeEventListener("message");
    };
  }, []);

  const setData = (form = null) => {
    if (form) setCodigo(form?.codigo);
  };

  const sendMessage = (message) => {
    _contentWindow.postMessage(message, "*");
    // //console.log("POSTED")
  };

  const handleRemoveFieldHtml = async (obj) => {
    const form = obj;
    //console.log("handleRemoveFieldHtml", form.name);
    let resp = await userApiService.getFormBuilderFormByName(
      `${form.name.nome}`
    );
    console.warn("handleHTMLCSS============================0");
    console.warn(resp);

    let styleFormBuilder = `<style id="formbuilderstyle" type="text/css">${resp.css}</style>`;

    if (document.getElementById("formbuilderstyle")) {
      console.warn("ja existe");
    } else {
      document.head.insertAdjacentHTML("beforeend", styleFormBuilder);
    }

    holdManyFiles({ files: resp.js, src: true, type: "script" });
    holdManyFiles({
      files: resp.css,
      href: true,
      type: "link",
      rel: "stylesheet",
    });
    holdManyFiles({ files: resp.img, src: true, type: "img" });

    let div = document.createElement("div");
    div.innerHTML = resp?.html;

    div.style.margin = "5px";
    let btn = div.querySelector('button[type="submit"]');

    if (!btn) {
      btn = div.querySelector('button[onclick="formSubmit()"]');
    }

    if (!btn) {
      btn = div.querySelector('button[onclick="formSumit()"]');
    }

    //console.log("SUBMIT BUTTON", btn);

    let inputs = div.querySelectorAll("input");
    let inputSelect = div.querySelectorAll("select");
    let inputTextArea = div.querySelectorAll("textarea");
    disable(inputs);
    disable(inputSelect);
    disable(inputTextArea);

    let formNewLine = div.querySelectorAll(".formNewLine");
    for (const key in formNewLine) {
      if (Object.hasOwnProperty.call(formNewLine, key)) {
        const element = formNewLine[key];
        element.style.display = "block";
      }
    }

    setFormSubmit(btn);
    if (btn) btn.style.display = "none";
    let html = div.innerHTML;
    console.error("error============================11");
    console.error(html);
    setIframeUrl(html);
    hideSpinner();
    // setOpenForm(4);
  };

  const handleAdd = () => {
    setData();
    setCurrentForm({});
    setCancel("Cancelar");
    setOpenForm(1);
  };

  const handleShow = async (form) => {
    handleRemoveFieldHtml(form);
    setBack("Voltar");
    setData(form);
    setCurrentForm(form);
    setOpenForm(2);
  };

  const handleEdit = (form) => {
    form.endpoint = "edit";
    setData(form);
    setCurrentForm(form);
    setCancel("Cancelar");
    setOpenForm(3);
  };

  const handleDelete = (form) => {
    //console.log("form", form);
    setData(form);
    setCurrentForm(form);
    // setCancel("Cancelar")
    setShowConfirm(true);
  };

  const handleClone = (form) => {
    //console.log("=================form");
    //console.log(form);
    form.endpoint = "clone";
    setData(form);
    setCurrentForm(form);
    setCancel("Cancelar");
    setOpenForm(3);
  };

  const handleCancel = () => {
    setOpenForm(0);
    setCreateAnotherForm(false);
    const customEvent = new CustomEvent("removeBootstrap", { detail: "" });
    document.dispatchEvent(customEvent);
    //console.log("handleCancel");
  };

  const getIframeData = (obj) => {};

  const deleteData = async (answer) => {
    if (answer) {
      const form = currentForm?.name;
      //console.log("DELETING", form?.nome);
      let result = await userApiService.getDeleteForm(form?.nome);

      if (result?.success) {
        loadTable();
        setOpenForm(0);
        showMsg.success("Dados removidos com sucesso!");
      } else {
        showMsg.error(
          result?.exception?.msg || "Houve erro ao remover os dados"
        );
      }
    }
    setShowConfirm(false);
  };

  const handleCreateAnotherFormChange = async (evt) => {
    //console.log("handleCreateAnotherFormChange", evt.target.checked);
    setCreateAnotherForm(evt.target.checked);
    // await sleep(1500);
    // if(evt?.target?.checked) {
    //     var iframe = document.getElementById("ifr-form-builder");
    //     iframe.src = iframe?.src
    //     //console.log("SRC", _contentWindow)
    // }
  };
  const loadTable = async (list = null) => {
    if (!list) {
      list = (
        (await userApiService.getSortedFormBuilderForms()) ?? []
      ).reverse();
    }

    setTableData(list);
    let rows = [];
    if (list) {
      Object.values(list).map((item) => {
        rows.push({
          num: item?.name?.codigo,
          nome: item?.name?.nome?.toString()?.split(".")[0],
          model: item?.name.nome,
          status: item.status || "Activo",
          modelo: item.modelo,
          action: (
            <Actions
              page="form_builder"
              value={item}
              handleView={handleShow}
              handleClone={handleClone}
              handlefom={handleDelete}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ),
        });
      });

      setList({ columns: columns, rows: rows });
      //console.log("FormBuilder222", list);
    }
  };

  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  hideSpinner();

  const handleButtons = (saveButton = false) => {
    return (
      <>
        <Box className={`voltar-button-container w-100 fixed-bottom`}>
          <Button onClick={handleCancel} className={`cancel-btn`}>
            Voltar
          </Button>
          {saveButton ? (
            <Button onClick={saveForm} className={`save-btn`}>
              Salvar
            </Button>
          ) : null}
        </Box>
      </>
    );
  };

  const handleTitle = (params) => {
    const {
      title = "Formulários",
      subtitle = "Cadastrados",
      mapButton = false,
    } = params ? params : {};
    return (
      <Box className={`forms-title-container position-relative`}>
        <h6 className={`forms-title`}>{title}</h6>
        <label className={`forms-sub-title`}>{subtitle}</label>
      </Box>
    );
  };

  const showConfirmBox = () => {
    var nome = "formulario?";
    return showConfirm ? (
      <ConfirmBox
        msg={StringMessage.message + ` ${nome}`}
        handleResult={deleteData}
      />
    ) : null;
  };

  const iframeHasLoaded = (e) => {
    _contentWindow = e.target.contentWindow;
    setContentWindow(_contentWindow);
    //console.log("_contentWindow", _contentWindow);
    // var formSaveButton =  iframe.getElementById("formSaveButton");
    // if(formSaveButton) {
    //     //console.log("formSaveButton", formSaveButton)
    // }
    setHasIframeContent(true);
    sendMessage("hi-from-portal-backoffice");
  };

  const handleFormMenus = () => {
    return (
      <Box
        className={`listagem position-relative .container-fluid w-100 p-3 m-0`}
      >
        <img
          className={`menu-grafismo-vertical d-none`}
          src={fundo_base_vertical}
        />
        <img className={`menu-s-vertical d-none`} src={s_image} />
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>MODELO DE FORMULÁRIOS</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page="form_builder"
              handleAdd={handleAdd}
              dataTip={"Adicionar Formulario"}
              dataFor={"adicionar"}
              addText={"Adicionar"}
            />
          </div>
        </Box>
        <>
          <Box className={`form-list flex-fill`}>
            <DATATABLE className={`form-list flex-fill d-12`} data={list} />
          </Box>
        </>
      </Box>
    );
  };

  const editForm = () => {
    let formName = currentForm?.name?.nome;
    let endPoint = formName ? `/${currentForm?.endpoint}/${formName}` : "";
    return (
      <Row
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 mt-0 ml-0 mr-0 mb-5`}
      >
        <Box
          className={`listagem-headerr d-flex flex-md-row flex-sm-column p-25 pl-0 pr-0`}
        >
          <Box className={`listagem-header flex-grow-5`}>
            {!formName ? <strong>CRIAR</strong> : <strong>EDITAR</strong>}{" "}
            &nbsp;{formName}
          </Box>
          <Box
            className={`d-flex flex-fill flex-row-reverse p-0 containerCreateAnotherOne`}
          >
            <Button
              onClick={handleCancel}
              className={`cancel-btn right-0 ml-20`}
            >
              {cancel}
            </Button>
            <label className={` createAnotherOne`}>
              <input
                type="checkbox"
                ref={checkboxRef}
                onChange={(evt) => handleCreateAnotherFormChange(evt)}
                checked={createAnotherForm}
              />{" "}
              Criar outro
            </label>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row h-100`}>
          <iframe
            id="ifrFormBuilder"
            src={`${process.env.REACT_APP_FORM_BUILDER_FRONTEND_HOST}${endPoint}`}
            title="formbuild"
            width="100%"
            onLoad={iframeHasLoaded}
          />
        </Box>
      </Row>
    );
  };

  const previewForm = () => {
    let nomePreviewFormulario = currentForm?.name?.nome;
    return (
      <Row
        className={`listagem position-relative .container-fluid w-100 p-3 m-0`}
      >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>PRÉ-VISUALIZAÇÃO DO</strong>&nbsp;FORMULÁRIO
          </Box>
          <Box className={`p-0`}>
            <div className="allbtns">
              <Button
                onClick={handleCancel}
                className={`cancel-btn right-1 m-1`}
              >
                {back}
              </Button>
            </div>
          </Box>
        </Box>
        <Box
          className={`listagem-tabela d-flex flex-column pt-0 pl-5 pr-5`}
          style={{ paddingTop: "0px" }}
        >
          <Row>
            <Col
              sm={12}
              className={`d-flex flex-column justify-content-start large-font input-container-2 pt-4 pb-100`}
            >
              <div id="stringpreview" className={"pt-2"}>
                <strong>
                  <h4>{nomePreviewFormulario.toUpperCase()}</h4>
                </strong>
              </div>
              <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
            </Col>
          </Row>
        </Box>
      </Row>
    );
  };

  const saveForm = () => {
    let valid = formSubmit ? formSubmit.click() : false;
    if (!valid) return false;
    let formUrl = document.getElementById("formUrl").innerHTML;

    let response = addFormData({
      form: formUrl,
      content: {
        _data: { ...window.formData.content },
      },
      nestedModels: window.formData.nestedModels,
    });

    //console.log("response", response);
    if (response) {
      resetCurrentPolygon();
      showMsg.success(StringMessage.messageSave);
      setIframeUrl(null);
    }
  };

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return previewForm();
      case 3:
        return editForm();
      case 4:
        return handleClone();
      default:
        break;
    }
  };

  return start();
}
