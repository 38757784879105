
import { Box } from '@mui/system';
import React, {
    useEffect,
  } from 'react';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import FormControl from '@mui/material/FormControl';
import ReactTooltip from 'react-tooltip';

export const ChangePictureActionButton = (props) => {

    useEffect(function () {
        ReactTooltip.rebuild();
      }, [])
    const { 
        dataUri,
        openPopOver,
        handleOpenTakeFotoModal,
        handleOpenGallery,
        onChangeSelectedImage,
        anchorEl,
        popOverId, 
        handleOpenPopOver,
        handleClosePopOver,
        handleSendToServer,
        handleRemoveDataUri
    } = props

    return (<Box className={`flex-fill w-100 p-0`}  >
        {
            dataUri 
            ? <div  className={`d-flex flex-fill flex-row w-100 p-0`} data-tip='Remover a Foto' data-for='remover'>
                <Button onClick={handleRemoveDataUri} 
                    className={`cancel-btn w-100 m-1`} >
                    Remover 
                </Button>
                <ReactTooltip id='remover' />
                {
                    handleSendToServer &&
                    <Button onClick={handleSendToServer} 
                        className={`right-btn w-100 m-1`} data-tip='Salvar Foto' data-for='salvar'>
                        Salvar 
                    </Button>
                    
                }
                 <ReactTooltip id='salvar' />
                </div>
            : <> <Button  aria-describedby={popOverId} 
                variant="contained" 
                onClick={handleOpenPopOver} 
                className={`right-btni w-100 m-1`} 
                data-tip='Alterar Foto' data-for='alterar'>
                Carregar foto 
            </Button>
            <ReactTooltip id='alterar' />
            <Popover
                id={popOverId}
                open={openPopOver}
                anchorEl={anchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                >
                <MenuList
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={true}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleOpenTakeFotoModal}>Tirar foto</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleOpenGallery}>
                        <label className="" 
                            htmlFor="file-input2"
                            style={{}}
                        >
                        Ver na galeria
                        </label>
                    </MenuItem>
                </MenuList>
            </Popover>

            <FormControl>
                <Input
                    id="file-input2"
                    className="upload-image"
                    type="file"
                    // value={pro}
                    name="foto"
                    onChange={onChangeSelectedImage}
                    style={{ visibility: "hidden" }}
                    accept="image/*"
                />
            </FormControl>
            </>
        }
    </Box>
    );
};