import React, {
  useEffect,
  useState,
} from 'react';

import { makeStyles } from '@mui/styles'
  ;
import style from '../themes/styles/geral';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import ReactTooltip from 'react-tooltip';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handlePageChange } from '../utils/handlePageChange';
import { permissionsMapped } from "../utils/permissionsMapped";
import { databaseService } from '../services/database.service';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useStyle = makeStyles(style);


export default function Actions(props) {
  const [loggedUser, setLoggedUser] = useState(null);
  const classes = useStyle();
  const {
    handleEdit,
    handleEditRForm,
    handleDelete,
    handleClone,
    handleBlock,
    handleView,
    handleDisabeEnable,
    disabled,
    status,
    estado,
    page,
    user,
    value
  } = props;

  useEffect(function () {
    ReactTooltip.rebuild();
    async function getUserData () {
      const loggedUser = databaseService.isLogged()
      setLoggedUser(loggedUser);
    }

    getUserData();
  }, [])

  const calcActionWidth = () => {
    let initial = 30;
    let btnQtdes = handleEdit ? 1 : 0;
    if (handleDelete) btnQtdes++;
    if (handleBlock) btnQtdes++;
    if (handleView) btnQtdes++;
    if (handleDisabeEnable) btnQtdes++;

    let add = (btnQtdes) * (initial + 10);
    return `${initial + add}px`;
  }
  const showHiding = (action) => {
    var pagePerm = permissionsMapped[action];

    // const hasPermission = loggedUser?.allPermissionsMapped?.find((item) => {
    //   return pagePerm === item;
    // });

    var hasPermission = null;
  if (loggedUser?.codigo == 1){

    hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
      return pagePerm === item;
    });

  }else{

    hasPermission = loggedUser?.permissions?.filter((item) => {
      return pagePerm === item.nome;
    });
  }

    return hasPermission  ? true : false;

  };

  const handleFormMenus = () => {
    return (
      <Box className={`forms-title-container d-flex flex-row`} >
        <ToastContainer />
        {showHiding(`${page}_view`) ?
        handleView ? <Button onClick={function () { handlePageChange(handleView, page, "view", value) }} className={`${classes.successBtn} `} >
          <VisibilityIcon color='success' data-tip='Visualizar' data-for='visualizar' />
          <ReactTooltip id='visualizar' />
        </Button>
          :null
        :null
        }

        {showHiding(`${page}_edit`) ?
          handleEdit
              ? <Button onClick={function() {handlePageChange(handleEdit, page, "edit", value) }} className={`${classes.infoBtn} `}>
                <EditIcon color='primary' data-tip='Editar' data-for='editar' />
                <ReactTooltip id='editar' />
              </Button>
              : null
            : null
          }

        {estado === 0
          ? <Button  onClick={ function(){ handleEditRForm(value)} } className={`${classes.infoBtn} `} >
            <EditIcon color='primary' data-tip='Editar' data-for='Editar' />
            {/* <ReactTooltip id='editar' /> */}
          </Button>

          : null}

        {estado === 1 || estado === 2
          ? <Button className={`${classes.infoBtn} `} disabled={true} >
            <EditIcon data-tip='Iniciar In' data-for='Iniciar' />
            {/* <ReactTooltip id='editar' /> */}
          </Button>
          : null}

        {showHiding(`${page}_clone`) ?
        handleClone ? <Button onClick={function () { handlePageChange(handleClone, page, "clone", value) }} className={`remove-btn `} >
          <FileCopyIcon color='primary' data-tip='Clonar Formulario' data-for='clonar' />
          <ReactTooltip id='clonar' />
        </Button>
          :null
        :null
        }
        {showHiding(`${page}_delete`) ?
        handleDelete ? <Button onClick={function () { handlePageChange(handleDelete, page, "delete", value) }} className={`remove-btn `} >
          <DeleteIcon color='error' data-tip='Apagar' data-for='delete' />
          <ReactTooltip id='delete' />
        </Button>
          :null
        :null
        }
      </Box>)
  }

  return (
    handleFormMenus()
  );
}
