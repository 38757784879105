import React, {
  useState,
  useEffect,
} from 'react';

import api from '../../services/api'
import createErrorMessage from "../../utils/createErrorMessage"
import Myframe from '../Myframe';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { Col, Row } from 'react-bootstrap';
import Actions from '../../components/Actions';
import DATATABLE from "../../components/DataTable/dataTable"
import SelectLabels from '../../components/SelectLabels';
import validator from '../../services/validator.service'
import ConfirmBox from '../../components/ConfirmBox'
import StringMessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton';

const validation = new validator();
const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Bairros',
    field: 'nome',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Sigla',
    field: 'sigla',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Comuna',
    field: 'comuna',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 200
  }
];

export default function Bairros(props) {
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Formulário');
  const [openForm, setOpenForm] = useState();
  const [codigo, setCodigo] = useState();
  const [nome, setNome] = useState();
  const [sigla, setSigla] = useState();
  const [comuna, setComuna] = useState();
  const [listaProvs, setListaProvs] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [provincia, setProvincia] = useState();
  const [listaMunicipios, setListaMunicipios] = useState();
  const [listaComuna, setListaComuna] = useState();
  const [listaComunaTemp, setListaComunaTemp] = useState([]);
  const [listaMunicipiosTemp, setListaMunicipiosTemp] = useState([]);
  const [municipio, setMunicipio] = useState();

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    let list = await userApiService.getComunas() ?? [];
    let listMuni = await userApiService.getMunicipios() ?? [];
    let listPro = await userApiService.getProvinces() ?? [];

    permissionHandle(list, 'Comunas')
    permissionHandle(listMuni, 'Municipios')
    permissionHandle(listPro, 'Provincia')

    if (Array.isArray(list)) {
      list = list.map((item) => {
        return { codigo: item.codigo, nome: item.nome, municipio: item.municipio.codigo }
      })
    } else {
      list = []
    }

    setListaComuna(list);

    if (Array.isArray(listMuni)) {
      listMuni = listMuni.map((item) => {
        return { codigo: item.codigo, nome: item.nome, provincia: item.provincia.codigo }
      })
    } else {
      listMuni = []
    }

    setListaMunicipios(listMuni);

    if (Array.isArray(listPro)) {
      listPro = listPro.map((item) => {
        return { codigo: item.codigo, nome: item.nome }
      })
    } else {
      listPro = []
    }

    setListaProvs(listPro);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])


  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const handleProviaUpdate = (event) => {
    setMunicipalitiesByProvince(event.codigo)
  }
  const handleMunicipUpdate = (event) => {
    setComunaByMunicepaloites(event.codigo)
  }

  const setMunicipalitiesByProvince = (prov = null) => {
    if (listaMunicipios) {
      const aux = listaMunicipios.filter((obj) => {
        if (obj?.provincia === prov) return obj?.nome;
      })
      setListaMunicipiosTemp(aux)
    }
  }

  const setComunaByMunicepaloites = (prov = null) => {
    if (listaComuna) {
      const aux = listaComuna.filter((obj) => {
        if (obj?.municipio === prov) return obj?.nome;
      })
      setListaComunaTemp(aux)
    }
  }

  const setData = (bairro = null) => {
    setCodigo(bairro?.codigo);
    setNome(bairro?.nome);
    setSigla(bairro?.sigla);
    setComuna(bairro?.comuna);
    setOpenForm(true);
  }

  const handleEdit = (prov) => {
    setData(prov);
    setOpenForm(true);
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }

  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  }

  const handleDelete = (obj) => {
    setCodigo(obj?.codigo)
    setShowConfirm(true)
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();

  const showConfirmBox = () => {
    var nome = 'Bairro?'
    return (
      showConfirm ? <ConfirmBox msg={StringMessage.message + ` ${nome}`} handleResult={deleteData} /> : null
    );
  }

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteAny('bairros-aldeias', codigo);
      if (result?.success) {
        loadTable();
        setOpenForm(false);
        showMsg.success('Dados removidos com sucesso!')
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }


  const saveForm = async () => {
    setOpenSpinner(true);
    const prov = {
      codigo: codigo,
      nome: nome,
      sigla: sigla,
      comuna: comuna?.codigo
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveBairro(prov);
    if (result?.success) {
      loadTable();
      setOpenForm(false);
      showMsg.success(StringMessage.messageSave)
    } else {
      let error = result?.exception?.msg
      showMsg.error(createErrorMessage(result))
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const updateForm = async () => {
    setOpenSpinner(true);
    const prov = {
      codigo: codigo,
      nome: nome,
      sigla: sigla,
      comuna: comuna?.codigo
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateBairro(prov);
    let message = StringMessage.messageEditar
    if (result?.success) {
      loadTable();
      setOpenForm(false);
      showMsg.success(message)
    } else {
      let error = result?.exception?.msg
      showMsg.error(error)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }
  const loadTable = async () => {
    let list = (await userApiService.getBairros()) ?? [];

    permissionHandle(list, 'Bairros')

    if (Array.isArray(list)) {
      list.reverse();
    } else {
      list = []
    }

    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: l.codigo,
          nome: l.nome,
          sigla: l.sigla,
          comuna: l.comuna.nome,
          action: (<Actions page='bairros' value={l} handleEdit={handleEdit} handleDelete={handleDelete} />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }

  const handleTitle = (params) => {
    const { title = 'Bairros', subtitle = 'Cadastradas', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const start = () => {
    return openForm ? showForm() : handleFormMenus();
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>BAIRROS</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='bairros'
              handleAdd={handleAdd}
              dataTip={'Adicionar Bairro'}
              dataFor={'adicionar'}
              addText={'Adicionar'} />
          </div>
        </Box>

        {iframeUrl ?
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
            {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
          :
          <>
            <Box className={`listagem-tabela flex-fill d-12`}  >
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        }
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >

        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>CADASTRAR</strong>&nbsp;NOVO BAIRRO
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(false)} className={`cancel-btn m-1`} >
                Cancelar
              </Button>
              <Button onClick={saveForm} className={`right-btn m-1`} style={{ display: saveButtonVisible }}>
                Salvar
              </Button>
              <Button onClick={updateForm} className={`right-btn m-1`} style={{ display: editButtonVisible }}>
                Actualizar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column h-100 p-5`}  >
          <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
            <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
              <label className={`input-label-23`} >Província:</label>
              <SelectLabels
                initialValue={provincia}
                setValue={handleProviaUpdate}
                lista={listaProvs}
                className={`select-labels`}
              />
            </Col>
            <Col md={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
              <label className={`input-label-23`} >Município:</label>
              <SelectLabels
                initialValue={municipio}
                setValue={handleMunicipUpdate}
                lista={listaMunicipiosTemp}
                className={`select-labels`}
              />
            </Col>
          </Col>
          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Comuna:</label>
                <SelectLabels
                  initialValue={comuna}
                  setValue={setComuna}
                  lista={listaComunaTemp}
                  className={`select-labels ml-0`}
                />
              </Col>
            </Col>
          </Col>
          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Nome:</label>
                <Input
                  type='select'
                  label="Nome"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  //onKeyPress={keyPressed}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2`} >
                <label className={`input-label-23`} >Sigla:</label>
                <Input
                  type='text'
                  label="nome"
                  disableUnderline={true}
                  value={sigla}
                  onChange={(e) => setSigla(e.target.value)}
                  //onKeyPress={keyPressed}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
          </Col>
        </Box>
      </Row >)
  }

  return (
    start()
  );

};
