import React from 'react';
import DualListBox from "react-dual-listbox"
import ReactTooltip from 'react-tooltip';

const DualBoxList = (props) => {
    const { options, selected, onChange } = props
  
    return (
       <>
        <DualListBox
        canFilter
        options={options}
        selected={selected}
        //showHeaderLabels={true}
        onChange={onChange}
        icons={{
            moveLeft:<span className="fas fa-chevron-left"/>,
            moveAllLeft: [
                <span key={10}   className="fas fa-chevron-left" />,
                <span key={11} className="fas fa-chevron-left" />,
            ],
            moveRight: <span className="fas fa-chevron-right" />,
            moveAllRight: [
                <span key={20} className="fas fa-chevron-right" />,
                <span key={21} className="fas fa-chevron-right" />,
            ],
        }
       
    }
    lang ={{
      moveLeft:'Mover a Esquerda',
      moveAllLeft:'Mover tudo a Esquerda',
      moveRight:'Mover a Direita',
      moveAllRight:'Mover tudo a Direita'
    }}
        
    />
     <ReactTooltip id='esquerda' />
       </>
    )
    
}

export default DualBoxList