import HomeIcon from '@mui/icons-material/Home';
import MinhaContaIcon from '@mui/icons-material/ManageAccounts';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import FormsIcon from '@mui/icons-material/Article';
import SyncIcon from '@mui/icons-material/Sync';
import QuizIcon from '@mui/icons-material/Quiz';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import logout from '../assets/images/icones/LOGOUT.png';
import desenhar from '../assets/images/icones/desenhar.png';
import gps from '../assets/images/icones/gps.png';
import salvar from '../assets/images/icones/salvar.png';
import mapa from '../assets/images/icones/mapa.png';

const icones = {
  home: <HomeIcon/>,
  minha_conta: <MinhaContaIcon/>,
  dados_de_acesso: <SecurityIcon/>,
  cadastros: <AddModeratorIcon/>,
  formularios: <FormsIcon/>,
  sincronizacao: <SyncIcon/>,
  inqueritos: <QuizIcon/>,
  entidades: <ApartmentIcon/>,
  dados_administrativos: <FileCopyIcon/>,
  logout: logout,
  desenhar: desenhar,
  gps: gps,
  salvar: salvar,
  mapa: mapa,
};

export default icones;
