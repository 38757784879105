import React, {
  useState,
  useEffect,
} from 'react';

import api from '../../services/api'
import createErrorMessage from "../../utils/createErrorMessage"
import Myframe from '../Myframe';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Table } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { Col, Row } from 'react-bootstrap';
import DATATABLE from "../../components/DataTable/dataTable"
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Actions from '../../components/Actions';
import validator from '../../services/validator.service'
import SelectLabels from "../../components/SelectLabels";
import ConfirmBox from '../../components/ConfirmBox'
import { capFirstLetter } from '../../utils/string';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import StringMessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton';
import { handlePageChange } from '../../utils/handlePageChange';

const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Produtores',
    field: 'nome',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Tipo de Produção',
    field: 'tipo_produtor',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 200
  }
];

const validation = new validator();

export default function Produtores(props) {
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Formulário');
  const [openForm, setOpenForm] = useState(0);
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [tipo_produtor, setTipoDeProdutor] = useState()
  const [observacao, setObservacao] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [back, setBack] = useState("")
  const [cancel, setCancel] = useState("")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [listaTipoProducao, setListaTipoProducao] = useState([
    { codigo: null, nome: "Selecionar Tipo de Produção" },
  ]);

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    let tipoProdutor = (await userApiService.getProducersType()) ?? [];

    permissionHandle(tipoProdutor, 'Tipo Produtor')


    if (Array.isArray(tipoProdutor)) {
      tipoProdutor = tipoProdutor.map((item) => {
        return {
          codigo: item?.codigo,
          nome: item?.nome,
          tipoProdutor: item?.tipoProdutor?.codigo
        };
      });
    } else {
      tipoProdutor = []
    }


    setListaTipoProducao([listaTipoProducao[0], ...tipoProdutor]);
    loadTable();
    // let list = await userApiService.getEpocas() ?? [];
    // list = list.map((item) => {
    //   return { codigo: item.codigo, nome: item.nome  }
    // })

    // setList(list);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])

  const permissionHandle = (response, permission) => {
    if (response.status === 403){
      showMsg.error(response.message + ' ' + permission)
    }
  }
  
  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();

  const setData = (produtor = null) => {
    setCodigo(produtor?.codigo);
    setNome(produtor?.nome);
    setTipoDeProdutor(produtor?.tipo_produtor)
    setObservacao(produtor?.observacao);
    setOpenForm(3);
  }

  const handleSetTipoProdutor = (obj) => {
    setTipoDeProdutor(obj);
    setListaTipoProducao(
      listaTipoProducao.filter(
        (model) => model.codigo === null || model.municipio === obj.codigo
      )
    );
    setListaTipoProducao([
      { codigo: null, nome: "Selecionar Tipo de Produção" },
    ]);
  };


  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setCancel("Cancelar")
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  }

  const handleShow = (produtor) => {
    setData(produtor)
    setBack("Voltar")
    setCancel('Cancelar')
    setSaveButtonVisible('none')
    setOpenForm(2);
  }

  const handleEdit = (produtor) => {
    setData(produtor)
    setOpenForm(3);
    setCancel("Cancelar")
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }

  const handleDelete = (obj) => {
    setCodigo(obj?.codigo)
    setShowConfirm(true)
  }

  const showConfirmBox = () => {
    var nome = 'Epoca?'
    return (
      showConfirm ? <ConfirmBox msg={StringMessage.message2 + ` ${nome}`} handleResult={deleteData} /> : null
    );
  }

  const deleteData = async (answer) => {
    setOpenSpinner(true);

    if (answer) {
      let result = await userApiService.deleteProdutor(codigo);
      if (result) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessage.messageRemove)
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
  }

  const saveForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      tipo_produtor: tipo_produtor?.codigo,
      observacao: observacao,
    }

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveProdutor(rowData);
    if (result?.success) {
      setData()
      loadTable();
      setOpenForm(0);
      showMsg.success(StringMessage.messageSave)
    } else {
      showMsg.error(createErrorMessage(result))
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const editHandleView = (msg) => {
    //console.log('page ', msg);
    setOpenForm(3);
  }

  const updateForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      tipo_produtor: tipo_produtor?.codigo,
      observacao: observacao,
    }

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateProdutor(rowData);
    let message = StringMessage.messageEditar
    if (result?.success) {
      setData()
      loadTable();
      setOpenForm(0);
      showMsg.success(message)
    } else {
      showMsg.error(result?.exception?.msg)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }
  const loadTable = async () => {
    let list = ((await userApiService.getProducers()) ?? []).reverse();
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: count++,
          nome: l?.nome,
          tipo_produtor: l?.tipo_produtor,
          action: (<Actions page='produtores' value={l} handleView={handleShow} handleEdit={handleEdit} handleDelete={handleDelete} />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }

  const handleTitle = (params) => {
    const { title = 'Produtores', subtitle = 'Cadastrados', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      default:
        break;
    }
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>Produtores</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='seasons'
              handleAdd={handleAdd}
              dataTip={'Adicionar Epoca'}
              dataFor={'adicionar'}
              addText={'Adicionar'} />
          </div>
        </Box>

        {iframeUrl ?
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
            {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
          :
          <>
            <Box className={`listagem-tabela flex-fill d-12`}  >
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        }
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                {back}
              </Button>
              <Button onClick={() => handlePageChange(editHandleView, "produtores", "edit", 'edit')} className={`right-btn m-1`} >
                Editar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row  p-5 pb-3`}  >
          <Col sm={4} className={`d-flex flex-column d-6 `}  >
            <FormControl component="fieldset" className="mb-2">
              <FormLabel component="legend"><strong>Nome</strong></FormLabel>
              <FormLabel>{capFirstLetter(nome)}</FormLabel>
            </FormControl>
          </Col>
          <Col sm={4} className={`d-flex flex-column d-6 p-3`}  >
            <FormControl component="fieldset" className="mb-2">
              <FormLabel component="legend"><strong>Observação</strong></FormLabel>
              <FormLabel>{observacao}</FormLabel>
            </FormControl>
          </Col>
        </Box>
      </Row>)
  }


  const editForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>&nbsp;Produtor
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                {cancel}
              </Button>
              <Button onClick={saveForm} className={`right-btn m-1`} style={{ display: saveButtonVisible }}>
                Salvar
              </Button>
              <Button onClick={updateForm} className={`right-btn m-1`} style={{ display: editButtonVisible }}>
                Actualizar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column h-100 p-1`}  >
          <Col sm={12} className={`d-flex flex-column d-6 p-3`} >
            <Col sm={12} className={`d-flex flex-row d-6 p-3`} >
              <Col sm={4} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Nome:</label>
                <Input
                  type='text'
                  label="nome"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col sm={4} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Tipo de Produção:</label>
                <SelectLabels
                  initialValue={tipo_produtor?.codigo}
                  setValue={setTipoDeProdutor}
                  lista={listaTipoProducao}
                  className={`select-labels`}
                />
              </Col>
            </Col>
            <Col sm={12} className={`d-flex flex-row d-6 p-3`} >
              <Col sm={12} className={`d-flex flex-column`} >
                <label className={`large-font`} >Observação:</label>
                <textarea value={observacao} className={`user-img`} onChange={(e) => { setObservacao(e.target.value) }} rows='6' />
              </Col>
            </Col>
          </Col>
        </Box>
      </Row >)
  }

  return (
    start()
  );

};
