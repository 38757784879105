import React, { Component } from 'react';
import './App.css';
import { AuthProvider } from './contexts/auth';
import Layout from './layouts';
// import { ThemeProvider } from '@mui/styles';
// import { makeStyles } from '@mui/styles'
import { createTheme, adaptV4Theme, ThemeProvider } from '@mui/material/styles';
import style from "./themes/styles/geral";

// const useStyle = makeStyles(style);

const theme = createTheme({
  components: {
    MuiFormLabel: {
      root: {
        width: "100%",
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // padding: 0,
          color: '#FFFFFF',
          position: 'relative',
          width: 'auto',
          fontWeight: 'bold',
          // backgroundImage: `linear-gradient(180deg, ${colors.darkGreen}, ${colors.darkGreen}, ${colors.darkGreen}, ${colors.lightGreen})`,
        },
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;