import React, {
  useState,
  useEffect,
} from 'react';

import api from '../../services/api'
import createErrorMessage from "../../utils/createErrorMessage"
import Myframe from '../Myframe';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Table } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { Col, Row } from 'react-bootstrap';
import DATATABLE from "../../components/DataTable/dataTable"
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Actions from '../../components/Actions';
import validator from '../../services/validator.service'
import ConfirmBox from '../../components/ConfirmBox'
import { capFirstLetter } from '../../utils/string';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import StringMessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton';
import ReactTooltip from 'react-tooltip';
import { databaseService } from '../../services/database.service';
import { permissionsMapped } from "../../utils/permissionsMapped";
import { handlePageChange } from '../../utils/handlePageChange';

const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Actividades Principal',
    field: 'nome',
    sort: 'asc',
    width: 300
  },

  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 200
  }
];

const validation = new validator();

export default function ActividadePrincipal(props) {
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Formulário');
  const [openForm, setOpenForm] = useState(0);
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [produtivityCapacity, setProductionCapacity] = useState('')
  const [observacao, setObservacao] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [back, setBack] = useState("")
  const [cancel, setCancel] = useState("")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [loggedUser, setLoggedUser] = useState(null);

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    // let list = await userApiService.getEpocas() ?? [];
    // list = list.map((item) => {
    //   return { codigo: item.codigo, nome: item.nome  }
    // })

    // setList(list);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])

  useEffect(function () {
    ReactTooltip.rebuild();
      async function getUserData () {
        const loggedUser = databaseService.isLogged()
        setLoggedUser(loggedUser);
      }

      getUserData();
  }, [])

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();

  const setData = (produtor = null) => {
    setCodigo(produtor?.codigo);
    setNome(produtor?.nome);
    setProductionCapacity(produtor?.productionsCapacity)
    setObservacao(produtor?.observacao);
    setOpenForm(3);
  }
  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setCancel("Cancelar")
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  }

  const handleShow = (produtor) => {
    setData(produtor)
    setBack("Voltar")
    setCancel('Cancelar')
    setSaveButtonVisible('none')
    setOpenForm(2);
  }

  const handleEdit = (produtor) => {
    setData(produtor)
    setOpenForm(3);
    setCancel("Cancelar")
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }

  const handleDelete = (obj) => {
    setCodigo(obj?.codigo)
    setShowConfirm(true)
  }

  const showConfirmBox = () => {
      return (
        showConfirm ? <ConfirmBox msg={StringMessage.message2 + ` ${nome}`} handleResult={deleteData} /> : null
      );
    }

  const deleteData = async (answer) => {
    setOpenSpinner(true);

    if (answer) {
      let result = await userApiService.deleteActividadesPrincipal(codigo);
      if (result) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessage.messageRemove)
      } else {
        showMsg.error(result?.exception?.msg )
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
  }

  const saveForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      observacao: observacao,
    }

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveAtividadePrincipal(rowData);
    if (result?.success) {
      setData()
      loadTable();
      setOpenForm(0);
      showMsg.success(StringMessage.messageSave)
    } else {
      showMsg.error(createErrorMessage(result))
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const updateForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      observacao: observacao,
    }

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateAtividadePrincipal(rowData);
    let message = StringMessage.messageEditar
    if (result?.success) {
      setData()
      loadTable();
      setOpenForm(0);
      showMsg.success(message)
    } else {
      showMsg.error(result?.exception?.msg)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const showHiding = (action) => {
    var pagePerm = permissionsMapped[action];

    // const hasPermission = loggedUser?.allPermissionsMapped?.find((item) => {
    //   return pagePerm === item;
    // });

    var hasPermission = null;
    if (loggedUser?.codigo == 1){

      hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
        return pagePerm === item;
      });

    }else{

      hasPermission = loggedUser?.permissions?.filter((item) => {
        return pagePerm === item.nome;
      });
    }

    return hasPermission?.length > 0 ? true : false;
  };

  const editHandleView = (msg) => {
      setOpenForm(3);
  }
  const loadTable = async () => {
    let list = await userApiService.getAtividadesPrincipais() ?? [];
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: count++,
          nome: l?.nome,
          action: (<Actions page ='actividades principal' value={l} handleView={handleShow} handleEdit={handleEdit} handleDelete={handleDelete} />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }

  const handleTitle = (params) => {
    const { title = 'Produtores', subtitle = 'Cadastrados', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      default:
        break;
    }
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
        <Box className={`listagem-header`}>
          <strong>ACTIVIDADES PRINCIPAIS</strong>&nbsp;CADASTRADAS
          </Box>
          <div className="allbtns">
          <AddButton
        page='seasons'
        handleAdd={handleAdd}
        dataTip={'Adicionar'}
        dataFor={'adicionar'}
        addText={'Adicionar'}/>
          </div>
        </Box>

        {iframeUrl ?
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
            {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
          :
          <>
            <Box className={`listagem-tabela flex-fill d-12`}  >
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        }
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
        <Box className={`listagem-header `}>
          <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
          <div className="allbtns">
          <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
              {back}
            </Button>

            <Button onClick={() => handlePageChange(editHandleView, "actividades principal", "edit", 'edit') } className={`right-btn m-1`} >
              Editar
            </Button>

            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row  p-5 pb-3`}  >
          <Col sm={4} className={`d-flex flex-column d-6 `}  >
            <FormControl component="fieldset" className="mb-2">
              <FormLabel component="legend"><strong>Nome</strong></FormLabel>
              <FormLabel>{capFirstLetter(nome)}</FormLabel>
            </FormControl>
          </Col>
          <Col sm={4} className={`d-flex flex-column d-6 p-3`}  >
            <FormControl component="fieldset" className="mb-2">
              <FormLabel component="legend"><strong>Observação</strong></FormLabel>
              <FormLabel>{observacao}</FormLabel>
            </FormControl>
          </Col>
        </Box>
      </Row>)
  }


  const editForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
        <Box className={`listagem-header `}>
          <strong>{`${ openForm === 1 ? "CADASTRAR" : "EDITAR" }`}</strong>&nbsp;Actividades Principal
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
          <div className="allbtns">
          <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
              {cancel}
            </Button>
            <Button onClick={saveForm} className={`right-btn m-1`} style={{display:saveButtonVisible}}>
              Salvar
            </Button>
            <Button onClick={updateForm} className={`right-btn m-1`} style={{display:editButtonVisible}}>
            Actualizar
            </Button>
           </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column h-100 p-1`}  >
          <Col sm={12} className={`d-flex flex-column d-6 p-3`} >
            <Col sm={12} className={`d-flex flex-row d-6 p-3`} >
              <Col sm={4} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Nome:</label>
                <Input
                  type='text'
                  label="nome"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
            <Col sm={12} className={`d-flex flex-row d-6 p-3`} >
              <Col sm={12} className={`d-flex flex-column`} >
                <label className={`large-font`} >Observação:</label>
                <textarea value={observacao} className={`user-img`} onChange={(e) => { setObservacao(e.target.value) }} rows='6' />
              </Col>
            </Col>
          </Col>
        </Box>
      </Row >)
  }

  return (
    start()
  );

};
