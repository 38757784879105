import React, {
    useEffect,
} from 'react';
import { Col, Card, Row, Container } from 'react-bootstrap';
import Button from "@material-ui/core/Button";
import { FaUserAltSlash, FaUsers, FaClipboardList, FaUserTimes } from 'react-icons/fa';

const CardOne = (props) => {
    var CardIcone = props.icone;
    return <Col md="3" sm="12" style={{ padding: '5px' }} className={"dash-card-item"}>
        <Card style={{ background: props.background, fontSize: '18', padding: '15px' }}>
            <Card.Body>
                <>
                    <Col sm='1'><CardIcone size={30} /> </Col>
                    <Col> <Card.Title style={{ fontWeight: 'bold', fontSize: '40px' }}>
                        {props.value}
                    </Card.Title>
                        <Card.Text>{props.title}</Card.Text>
                    </Col>
                </>
            </Card.Body>
        </Card>
    </Col>
}

export default function cards(props) {
    const {
        openInquiries,
        invalidate,
        validate,
        pending
    } = props;

    return (
        <div className={`d-md-flex align-items-row dash-cards`}>
            <CardOne icone={FaClipboardList} title={"Inquéritos Abertos"} value={openInquiries} background='#008FFB' />
            <CardOne icone={FaUserAltSlash} title={"Dados Admistrativos Pendentes"} value={pending} background='#ff9100' />
            <CardOne icone={FaUserTimes} title={"Dados Admistrativos Invalidados"} value={invalidate} background='#ff1100' />
            <CardOne icone={FaUsers} title={"Dados Admistrativos Validados"} value={validate} background='#07a82c' />
        </div>
    );
}