import React, { useState, useEffect } from "react";

import api from "../../services/api";
import Myframe from "../Myframe";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Col, Row } from "react-bootstrap";
import DATATABLE from "../../components/DataTable/dataTable"
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Actions from "../../components/Actions";
import validator from "../../services/validator.service";
import ConfirmBox from "../../components/ConfirmBox";
import { capFirstLetter, getFormName } from "../../utils/string";
import { formatDate }  from "../../utils/formatDate";
import { holdManyFiles, setAlreadyFilledData } from "../../utils/html";
import StringMessages from "../../utils/string-messages"

const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: "Entidades Remetidas",
    field: "entidade_remetente",
    sort: "asc",
    width: 300,
  },
  {
    label: "Data de Envio",
    field: "data_de_envio",
    sort: "asc",
    width: 300,
  },{
    label: "Formulário",
    field: "formulario",
    sort: "asc",
    width: 300,
  },
  {
    label: "Estado",
    field: "estado",
    sort: "asc",
    width: 300,
  },
  {
    label: "Acções",
    field: "action",
    sort: "asc",
    width: 200,
  },
];

const validation = new validator();

export default function ValidarFormularios(props) {
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState("Formulário");
  const [openForm, setOpenForm] = useState(0);
  const [codigo, setCodigo] = useState("");
  const [estado, setEstado] = useState("");
  const [novoEstado, setNovoEstado] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmBoxHtml, setConfirmBoxHtml] = useState("Deseja remover esta dado administrativo?");
  const [isWizard, setIsWizard] = useState();
  const [wizardSize, setWizardSize] = useState(1);
  const [formPage, setFormPage] = useState(1);
  const [wizardNext, setWizardNext] = useState(null);
  const [wizardPrev, setWizardPrev] = useState(null);
  const [formSubmit, setFormSubmit] = useState();
  const [formItemToFill, setFormItemToFill] = useState(null);
  const [formularioToFill, setFormularioToFill] = useState("");
  const [formName, setFormName] = useState(null);
  const [dataDeEnvio, setDataDeEnvio] = useState(null);
  const [dataDeValidacao, setDataDeValidacao] = useState(null);
  const [entidadeSuperior, setEntidadeSuperior] = useState(null);
  const [entidadeRemetente, setEntidadeRemetente] = useState("");
  const [documentId, setDocumentId] = useState(null);
  const [formulario, setFormulario] = useState(null);
  const [dadoAdministrativo, setDadoAdministrativo] = useState(null);
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [user, setUser] = useState();
  const [currentData, setCurrentData] = useState();

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    let _user = await userApiService.getUserData();
    setUser(_user);
    loadTable();
    // setEstado();
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, []);


  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  hideSpinner();

  const setData = async (dadoAdministrativo = null, disableAttributes = false) => {
    try {
      var formulario = dadoAdministrativo?.formularios[0];
      let formName = getFormName(formulario?.modelo);
      //console.log("codigo__", dadoAdministrativo)
      // //console.log("formName", formName);
      var data_de_validacao = dadoAdministrativo?.data_de_validacao;
      if(data_de_validacao) {
        data_de_validacao = formatDate(data_de_validacao)
      }
      setCodigo(dadoAdministrativo?.codigo);
      setEntidadeRemetente(dadoAdministrativo?.entidade?.nome);
      setEntidadeSuperior(dadoAdministrativo?.entidade_superior?.nome);
      setFormulario(formulario?.nome);
      // setModeloFormulario(formulario?.modelo);
      setDataDeEnvio(formatDate(dadoAdministrativo?.data_de_envio));
      setDataDeValidacao(data_de_validacao || "N/A");
      setEstado(dadoAdministrativo?.estado);
      setDadoAdministrativo(dadoAdministrativo);
      setFormName(formName);

      var dataFilled = null
      if(dadoAdministrativo) {
        const elasticdata = await userApiService.getAdminData({
          codigo: dadoAdministrativo?.codigo,
          form: formName
        })

        dataFilled = elasticdata?.data?.result?.hits?.hits[0]?._source
        //console.log("elasticData", elasticdata);
        //console.log("2.elasticData", dataFilled);
        setDocumentId(elasticdata._id);
        // dataFilled = JSON.parse('{"DadosPessoais":{"nome":"Mia Person One", "valorDaMoeda":"2000", "profissao":"Mecânico","idade":"23","genero":"Opção1"}, "aaa":{"aa1":"4567"}}')
        var formToFill = handleSetFormularioToFill(formulario, dataFilled, disableAttributes);
        formToFill?.onclick();
      }
    } catch (error) {
      //console.log("error", error)
    }
  };

  const handleAdd = () => {
    setData();
    setOpenForm(1);
  };

  const handleShow = (adminData) => {
    setOpenForm(2);
    setData(adminData, true);
  };

  const handleDelete = (obj) => {
    setShowConfirm(true);
  };

  const handleValidateInvalidate = (action) => {
    var message = {
      1: <ConfirmBox
        msg={StringMessages.validar}
        handleResult={(answer) => update(answer, action)}
      />,
      2: <ConfirmBox
        msg={StringMessages.invalidar}
        handleResult={(answer) => update(answer, action)}
      />
    }

    setNovoEstado(action);
    setShowConfirm(true);
    setConfirmBoxHtml(message[action])
  };

  const handleSetFormularioToFill = (obj, dataFilled = null, disableAttributes = false) => {
    setFormularioToFill(obj);
    const form = obj;
    var item = createFormItem(form, dataFilled, disableAttributes);
    handleEdit(form);
    if(dataFilled) setCurrentData(dataFilled)
    console.error('1.currentData',currentData);
    console.error('1.1.currentData',dataFilled);

    // var item = fillFields(form, dataFilled, disableAttributes);
    setFormItemToFill(item)
    return item;
  };

  const handleEdit = async (form) => {
    setOpenSpinner(true);
    let formName = getFormName(form?.modelo)?.toUpperCase();
    let resp = await userApiService.getFormBuilderFormByName(form?.modelo);

    holdManyFiles({ files: resp.js, src: true, type: 'script' });
    holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet'});

    //console.log("resp", resp);

    let div = document.createElement('div');
    div.innerHTML = resp?.html?.replace("formSumit", "formSubmit");
    div.style.margin = '5px';

    let btn = div.querySelector('button[type="submit"]');
    if (!btn) {
      btn = div.querySelector('button[onclick="formSubmit()"]');
    }

    if (!btn) {
      btn = div.querySelector('button[onclick="formSumit()"]');
    }

    setFormSubmit(btn);
    if (btn) btn.style.display = 'none';

    let html = div.innerHTML

      setIframeUrl(html)
      setIframeTitle(formName)

    // setTimeout(() => {
    //   setOpenSpinner(false);
    // }, 1000);

  }

  useEffect(function () {
    //console.log(12)
    // fillFields();
    // createFormItem();
  }, [currentData?._Metadata]);

  const fillFields = () => {

    let interval = setInterval(function () {
        try {
          if (!currentData) return false;
          console.error('1.currentData',currentData);
          let data = currentData;
          console.error('2.currentData',data);

          // Object.keys(data).map(key => {
          //   let inputs = document.querySelectorAll(`input.databaseField[data-model="${key}"]:not(.notDataFieldConsider), textarea.databaseField[data-model="${key}"]:not(.notDataFieldConsider), select.databaseField[data-model="${key}"]:not(.notDataFieldConsider)`);
          //   Array.from(inputs).map(input => {
          //     try {
          //       if (input) {
          //         let value = data[key][input.name];
          //         if (input.dataset.multiple) {
          //           value = data[key][input.name].values[0];
          //           data[key][input.name].values = data[key][input.name].values.slice(1, data[key][input.name].values.length);
          //         }
          //         if (input.type === 'checkbox') {
          //           if (Array.isArray(value) && value.includes(input.value))
          //             input.checked = true;
          //         } else if (input.type === 'radio' && input.dataset.value === value) {
          //           input.checked = true;
          //         } else if (input.type === 'file') {
          //           // //console.log('file')
          //           // //console.log(value)
          //         } else if(input.dataset.determinant) {
          //           input.value = value.length;
          //         } else {
          //           input.value = value;
          //         }
          //       }
          //     } catch (error) {
          //       console.error(error);
          //     }
          //     return true;
          //   });
          //   return true;
          // })
        } catch (error) {
        // setLoading(false);
      }

      clearInterval(interval);
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
    }, 2000)


  }

  const createFormItem = (form, dataFilled = null, disableAttributes = false) => {
    try {
      //console.log('FORM', form)
    // let formName = form?.modelo?.toString().toUpperCase().split('.')[0];
    let formName = getFormName(form?.modelo)?.toUpperCase();

    const formItem = {
      name: formName,
      onclick: async () => {
        let resp = await userApiService.getFormBuilderFormByName(form?.modelo);
        //console.log("resp", resp);

        holdManyFiles({ files: resp.js, src: true, type: 'script' });
        holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet' });
        holdManyFiles({ files: resp.img, src: true, type: 'img' });
        let div = document.createElement('div');
        div.innerHTML = resp?.html?.replace("formSumit", "formSubmit");
        div.style.margin = '5px';
        let btn = div.querySelector('button[type="submit"]');
        if (!btn) {
          btn = div.querySelector('button[onclick="formSubmit()"]');
        }

        if (!btn) {
          btn = div.querySelector('button[onclick="formSumit()"]');
        }
        // //console.log("SUBMIT BUTTON", btn);
        let formNewLine = div.querySelectorAll('.formNewLine');
        for (const key in formNewLine) {
          if (Object.hasOwnProperty.call(formNewLine, key)) {
            const element = formNewLine[key];
            element.style.display = "block"
          }
        }

        let wizard = div.querySelectorAll('.formWizardPage')?.length;

        if (wizard > 0) {
          setIsWizard(wizard);
          let btnNext = div.querySelector('#btn-form-next');
          let btnPrev = div.querySelector('#btn-form-prev');

          setWizardNext(btnNext);
          setWizardPrev(btnPrev);
          setWizardSize(wizard);
          // //console.log('oha aí o wizard', btnNext, btnPrev);
          // //console.log('oha aí o wizard counter', wizard);
          if (btnPrev) btnPrev.style.display = 'none';
          if (btnNext) btnNext.style.display = 'none';
        }



        // TAKE THIS INTO A FUNCTION AT A LATER STAGE
        setAlreadyFilledData(div, dataFilled);


        setFormSubmit(btn);
        if (btn) btn.style.display = 'none';

        let html = div.innerHTML

        setIframeUrl(html)
        setIframeTitle(formName)
        setTimeout(() => {
          setOpenSpinner(false);
        }, 1000);

        let fill = false
        setTimeout(() => {
          let fillDataFunctions = document.querySelector('#formUrl');
          if (fillDataFunctions?.dataset){
            let filldataFun =  eval(fillDataFunctions.dataset.filldata)
            if (filldataFun) fill = filldataFun(dataFilled, false)

            setTimeout(() => {
              //console.log('fill ', fill);
               setOpenSpinner(false);
            }, 1000);

            if (disableAttributes) {
              let inputs = div.querySelectorAll('input');
              let inputSelect = div.querySelectorAll('select')
              let textareaSelect = div.querySelectorAll('textarea')

              // disable(inputs)
              // disable(inputSelect)
              // disable(textareaSelect)

              if(disableAttributes)  {
                            let inputs = div.querySelectorAll('input');
                            let inputSelect = div.querySelectorAll('select')
                            let textareaSelect = div.querySelectorAll('textarea')

                            for (const key in inputs) {
                              if (Object.hasOwnProperty.call(inputs, key)) {
                                const element = inputs[key];
                                element.setAttribute("disabled", true)
                              }
                            }
                            for (const key in inputSelect) {
                              if (Object.hasOwnProperty.call(inputSelect, key)) {
                                const element = inputSelect[key];
                                element.setAttribute("disabled", true)
                              }
                            }
                            for (const key in textareaSelect) {
                              if (Object.hasOwnProperty.call(textareaSelect, key)) {
                                const element = textareaSelect[key];
                                element.setAttribute("disabled", true)
                              }
                            }
                          }
            }
          }

        }, 4000);
      }
    }

    return formItem;
    } catch (error) {
      //console.log('error form', error);
    }
  };

  // const createFormItem = (form, dataFilled = null, disableAttributes = false) => {
  //   try {
  //     let formName = getFormName(form?.modelo)?.toUpperCase();

  //   const formItem = {
  //     name: formName,
  //     onclick: async () => {
  //       let resp = await userApiService.getFormBuilderFormByName(form?.modelo);
  //       //console.log("resp", resp);

  //       holdManyFiles({ files: resp.js, src: true, type: 'script' });
  //       holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet'});
  //       holdManyFiles({ files: resp.img, src: true, type: 'img' });
  //       let div = document.createElement('div');
  //       div.innerHTML = resp?.html?.replace("formSumit", "formSubmit");
  //       div.style.margin = '5px';
  //       let btn = div.querySelector('button[type="submit"]');
  //       if (!btn) {
  //         btn = div.querySelector('button[onclick="formSubmit()"]');
  //       }

  //       if (!btn) {
  //         btn = div.querySelector('button[onclick="formSumit()"]');
  //       }
  //       // //console.log("SUBMIT BUTTON", btn);
  //       let formNewLine = div.querySelectorAll('.formNewLine');
  //       for (const key in formNewLine) {
  //         if (Object.hasOwnProperty.call(formNewLine, key)) {
  //           const element = formNewLine[key];
  //           element.style.display = "block"
  //         }
  //       }

  //       let wizard = div.querySelectorAll('.formWizardPage')?.length;

  //       if (wizard > 0) {
  //         setIsWizard(wizard);
  //         let btnNext = div.querySelector('#btn-form-next');
  //         let btnPrev = div.querySelector('#btn-form-prev');

  //         setWizardNext(btnNext);
  //         setWizardPrev(btnPrev);
  //         setWizardSize(wizard);
  //         // //console.log('oha aí o wizard', btnNext, btnPrev);
  //         // //console.log('oha aí o wizard counter', wizard);
  //         if (btnPrev) btnPrev.style.display = 'none';
  //         if (btnNext) btnNext.style.display = 'none';
  //       }




  //       let fillDataFunction = div.querySelector('input.input-fill-data');
  //       console.error('vai trazer alguma coisa')
  //       console.error(fillDataFunction)

  //       // TAKE THIS INTO A FUNCTION AT A LATER STAGE
  //       // setAlreadyFilledData(div, dataFilled);

  //       setFormSubmit(btn);
  //       if (btn) btn.style.display = 'none';

  //       let html = div.innerHTML

  //       setIframeUrl(html)
  //       setIframeTitle(formName)

  //       let fill = false
  //       setTimeout(() => {
  //         let fillDataFunctions = document.querySelector('#formUrl');
  //         if (fillDataFunctions?.dataset){
  //           let filldataFun =  eval(fillDataFunctions.dataset.filldata)
  //           if (filldataFun) fill = filldataFun(dataFilled, false)

  //           setTimeout(() => {
  //             //console.log('fill ', fill);
  //              setOpenSpinner(false);
  //           }, 1000);

  //           if (disableAttributes) {
  //             let inputs = div.querySelectorAll('input');
  //             let inputSelect = div.querySelectorAll('select')
  //             let textareaSelect = div.querySelectorAll('textarea')

  //             // disable(inputs)
  //             // disable(inputSelect)
  //             // disable(textareaSelect)

  //           }
  //         }

  //       }, 4000);
  //       // setTimeout(() => {
  //       //   let fillDataFunctions = document.querySelector('#formUrl');
  //       //   if (fillDataFunctions?.dataset){
  //       //     let filldataFun =  eval(fillDataFunctions.dataset.filldata)
  //       //     if (filldataFun) fill = filldataFun(dataFilled, false)

  //       //     setTimeout(() => {
  //       //       //console.log('fill ', fill);
  //       //        setOpenSpinner(false);
  //       //     }, 1000);

  //       //     if(disableAttributes)  {
  //       //       let inputs = div.querySelectorAll('input');
  //       //       let inputSelect = div.querySelectorAll('select')
  //       //       let textareaSelect = div.querySelectorAll('textarea')

  //       //       for (const key in inputs) {
  //       //         if (Object.hasOwnProperty.call(inputs, key)) {
  //       //           const element = inputs[key];
  //       //           element.setAttribute("disabled", true)
  //       //         }
  //       //       }
  //       //       for (const key in inputSelect) {
  //       //         if (Object.hasOwnProperty.call(inputSelect, key)) {
  //       //           const element = inputSelect[key];
  //       //           element.setAttribute("disabled", true)
  //       //         }
  //       //       }
  //       //       for (const key in textareaSelect) {
  //       //         if (Object.hasOwnProperty.call(textareaSelect, key)) {
  //       //           const element = textareaSelect[key];
  //       //           element.setAttribute("disabled", true)
  //       //         }
  //       //       }
  //       //     }
  //       //   }

  //       // }, 4000);

  //     }
  //   }

  //   return formItem;
  //   } catch (error) {
  //     //console.log('error: ', error)
  //   }

  // };

  const showConfirmBox = () => {
    return showConfirm ? (
      confirmBoxHtml
    ) : null;
  };

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteAny("admin_data", codigo);
      if (result?.success) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessages.messageRemove);
      } else {
        showMsg.error(
          result?.exception?.msg || StringMessages.messageRemoveError
        );
      }
    }

    setShowConfirm(false);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const update = async (answer, nEstado) => {
    if(answer) {
      setOpenSpinner(true);
      const rowData = {
        codigo: codigo,
        estado: nEstado,
      };

      //console.log("update", codigo)
      //console.log("update", nEstado)

      let timer = setTimeout(() => {
        hideAlertShow();
        clearTimeout(timer);
      }, 5 * 1000);

      // if (
      //   !validation.handleValidation([
      //     { value: codigo, type: "text", label: "Código" },
      //   ])
      // ) {
      //   setTimeout(() => {
      //     setOpenSpinner(false);
      //   }, 1000);
      //   return false;
      // }

      let result = await userApiService.updateAdminData(rowData);
      if (result?.success) {
        setData();
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessages.messageEditar);
      } else {
        showMsg.error(result?.exception.msg || StringMessages.messageErrorSave2);
      }

      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
    }

    setShowConfirm(false);
  };

  const stateCompare = (estado,data)=>{
    if(estado ==0)
    {
      return data
    }
    return null
  }
  const allowNullDate = (data) => {
    return data === formatDate(new Date('1970-01-01T00:00:00.000Z')) ? '' : data
 }
 const loadTable = async () => {
  try{
    let status = 0
    let _user = await userApiService.getUserData();
    let userEntity = _user?.entidade?.codigo
    let list = (await userApiService.getAdminDataByStatusAndEntidadeSuperior(status, userEntity) ?? []);
    if(Array.isArray(list)){
      list.reverse();
    }else{
      list = []
    }
    let rows = [];
    let count = 1;
    Object.values(list).map((l) => {
      const estadoFormatado = StringMessages.formattedStSatus[l?.estado] || "Pendente"

      rows.push({
        num:l?.codigo,
        entidade_remetente: l?.entidade.nome,
        data_de_envio: formatDate(l?.data_de_envio),
        entidade_superior:l?.entidade_superior?.nome,
        formulario:l?.formularios[0]?.nome,
        estado: estadoFormatado,
        action: (
          <Actions
            page='validate_form'
            value={l}
            handleView={stateCompare(l?.estado, handleShow)}
            disabled={l?.estado}
          />
        ),
      });
    });
      setList({ columns: columns, rows: rows });
  }catch (error){
    //console.log("validar form",error)
  }

  }

  const handleTitle = (params) => {
    const {
      title = "",
      subtitle = "",
      mapButton = false,
    } = params ? params : {};
    return (
      <Box className={`forms-title-container position-relative`}>
        <h6 className={`forms-title`}>{title}</h6>
        <label className={`forms-sub-title`}>{subtitle}</label>
      </Box>
    );
  };

  const handleFormMenus = () => {
    return (
      <Box
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
      >
        <Box className={`listagem-tabela flex-fill d-12`}>
        <Box className={` flex-fill d-flex flex-row-reverse p-0`}></Box>
        <h3>Validar Formulários </h3>
          <DATATABLE
            className={`form-list flex-fill d-12`}
            data={list}
          />
        </Box>
      </Box>
    );
  };

  const showForm = () => {
    return (
      <Row
        className={`listagem position-relative .container-fluid w-100 p-3 m-0`}
      >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
        <Box className={`listagem-header`}>
          <strong>VALIDAR DADOS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
          <div className="allbtns">
          <Button
              onClick={() => setOpenForm(0)}
              className={`cancel-btn m-1`}
            >
              Voltar
            </Button>
            <Button
              onClick={() => handleValidateInvalidate(2)}
              disabled={estado}
              className={`right-btn m-1`}
            >
              Invalidar
            </Button>
            <Button
              onClick={() =>handleValidateInvalidate(1)}
              disabled={estado}
              className={`right-btn m-1`}
            >
              Validar
            </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column  p-5 pb-3`}>
          <Row className={`d-flex flex-row pb-3`}>
            <Col sm={12} className={`d-flex flex-column `}  >
              <Row className={`d-flex flex-row p-1`}  >
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Entidade Remetente</strong>
                    </FormLabel>
                    <FormLabel>{capFirstLetter(entidadeRemetente || "")}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Entidade Superior</strong>
                    </FormLabel>
                    <FormLabel>{capFirstLetter(entidadeSuperior || "")}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >

                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Data de envio</strong>
                    </FormLabel>
                    <FormLabel>{dataDeEnvio}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Data de validação</strong>
                    </FormLabel>
                    <FormLabel>{dataDeValidacao}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Formulário</strong>
                    </FormLabel>
                    <FormLabel>{formulario}</FormLabel>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={`d-flex flex-row pb-3`}>
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Row>
        </Box>
      </Row>
    );
  };

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        break;
      case 2:
        return showForm();
      case 3:
        break;
      default:
        break;
    }
  };

  return start();
}
