import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLabels(props) {
  const { initialValue, lista, className, setValue, selected } = props;
  const [currentValue, setCurrentValue] = React.useState(initialValue ?
    lista.find((a) => a.codigo === initialValue.codigo) : ""
  );
  
  React.useEffect(() => {
    if (selected) {
      setCurrentValue(selected)
    }
  }, [])

  const handleChange = (event) => {
    const { value } = event.target
    setValue(value);
    setCurrentValue(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, marginTop: '4px', marginLeft: 0,  width: '100%', minWidth: 120, minHeight: '35px' }} className={className} >
        <Select
          defaultValue=""
          value={currentValue}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          style={{ maxHeight: '35px' }}
        >
          {lista ? Array.from(lista).map((item, key) => {
            return (<MenuItem key={key} value={item}>{item.nome || item.titulo}</MenuItem>)
          }) : null}
        </Select>
      </FormControl>
    </div>
  );
}