import React, {
    useEffect,
} from 'react';
import { MDBDataTable } from "mdbreact";
import { DataGrid } from '@mui/x-data-grid';
import { Row } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
export default function DataTable(props) {
    const {
        data
    } = props;
  
    var columns = data?.columns?.map((item) => {
        item.headerName = item.label;
        return item;
    }) ?? []

    var rows = data.rows ?? [];
    useEffect(async function () {
        ReactTooltip.rebuild();
      }, [])

    return (
        <Row className='p-4'  style={{ width: '100%' }}>
            <MDBDataTable
                striped
                bordered
                small
                data={data ?? []}
                // columns={columns}
                // rows={rows}
                noBottomColumns={true}
               sortable={false}
               order='desc'
                pageSize={5}
                searchLabel={"Procurar"}
                entriesLabel={"Mostrar entradas"}
                noRecordsFoundLabel={"Não foram encontrados registros correspondentes"}
                paginationLabel={["Anterior", "Próximo"]}
                infoLabel={["Mostrar","até","de","entradas"]}
                paginationComponentOptions={{
                    noRowsPerPage: true,
                    rowsPerPageText: 'Linhas por página:',
                    rangeSeparatorText: 'de'
                    
                }}
            />
            <ReactTooltip data-html="true" />
        </Row>
    );
}