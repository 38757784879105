import axios from 'axios';
import { formBuilderApiService } from './form_builder_api'
import { databaseService } from './database.service';
import { elasticsearchApiService } from './elastic.service';
import { ruRU } from '@mui/x-data-grid';
import { permissions } from '../assets/lang/strings';

const BASE_URL = process.env.REACT_APP_PORTAL_API;
const BASE_URL_OLD = process.env.REACT_APP_PORTAL_OLD;

axios.defaults.baseURL = BASE_URL;

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json'
  }
});

httpClient.interceptors.response.use(
  response => response,
  (error) => {
    console.error(error)
    if (!error.response) {
      //console.log("Sem conexão! Por favor verifique a conexão á API")
      databaseService.logout()
    }

    const statusCode = error?.response?.status
    //console.log("INTERCEPTED - STATUS CODE", statusCode)
    if (statusCode === 401) {
      //console.log("LOGOUT")
      //console.log(error?.response)
      databaseService.logout()

      var cEvent = new CustomEvent("snieap-token-issue", {})
      document.dispatchEvent(cEvent)
    }

    if (statusCode === 403) {
      console.error("Permissão em falta. Você não tem todas autorizações para realizar esta ação.")
      // alert("Não tens permissão pra ver esta página")
      let err = { status: 403, message: "Permissão em falta. Você não tem todas autorizações para realizar esta ação." }

      return Promise.reject(err);
    }
    // store.dispatch(authHideSpinner({ showDialogSpinner: false }))
    return Promise.reject({ error: error, msg: error?.response?.data?.exception?.msg });
  });

const httpClient2 = axios.create({
  baseURL: BASE_URL_OLD,
  timeout: 10000,
});

const getToken = () => {
  // const loggedUser = databaseService.isLogged()
  const userLogged = databaseService.isLogged()

  if (userLogged?.accessToken) {
    // //console.log('Permissions here:', userLogged)
    localStorage.setItem("access_token", userLogged?.accessToken)
    localStorage.setItem("allPermissionsMapped", userLogged.allPermissionsMapped)
  }

  const token = localStorage.getItem("access_token")
  // //console.log("IS LOGGED - LOGGED ")
  // //console.log("IS LOGGED - HAS TOKEN", token ? "RECEIVED TOKEN" : "NO TOKEN")
  return token
}

const get = async ({ endpoint }) => {
  try {
    const response = await httpClient.get(endpoint, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
      },
      validateStatus: function (status) {
        return status < 400; // Resolve only if the status code is less than 400
      },
    });

    if (response.status < 400) {
      return response.data?.data;
    } else {
      // throw new Error(`Failed to fetch data from ${endpoint}. Status code: ${response.status}.`);
    }
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}: ${error}`, error);
    if (error?.status === 403) {
      return error
    } else {
      return []
    }
    // throw new Error(`Failed to fetch data from ${endpoint}.`);
  }
};


const post = async ({ endpoint, data }) => {
  let result = await httpClient
    .post(
      endpoint,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      },
      {
        validateStatus: function (status) {
          return status < 400; // Resolve only if the status code is less than 500
        },
      },
    )
    .catch(function (error) {
      console.error(error);
      return []
    });

  // if (result?.data) {
  //   //console.log("RESULT", result?.data?.data)
  // }
  return result?.data;
}

const put = async ({ endpoint, data }) => {
  let result = await httpClient
    .put(
      endpoint,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      },
      {
        validateStatus: function (status) {
          return status < 400; // Resolve only if the status code is less than 500
        },
      },
    )
    .catch(function (error) {
      console.error(error);
      return []
    });

  // if (result?.data) {
  //   //console.log("RESULT", result?.data?.data)
  // }
  return result?.data
}

const handleDelete = async ({ endpoint }) => {
  let result = await httpClient
    .delete(
      endpoint,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      },
      {
        validateStatus: function (status) {
          return status < 400; // Resolve only if the status code is less than 500
        },
      },
    )
    .catch(function (error) {
      console.error(error);
      return []
    });

  // if (result?.data) {
  // //console.log("RESULT", result?.data)
  // }
  return result?.data;
}

// const sendResponse = async (result) => {
//   if (result.data) {
//     return result.data.data;
//   }

//   return false;
// }

export default class api {
  getProfilePic = (path) => {
    const serverPath = BASE_URL.replace("/api", "");
    return `${path}`;
  };

  getAuthUserByEmail = (email) => {
    return get({ endpoint: `/auth/user-status/${email}` })
  };

  deleteAny = async (endpoint, codigo) => {
    endpoint = `/${endpoint}/${codigo}`;
    return handleDelete({ endpoint });
  }

  disableEnableAny = async (endpoint, action, codigo) => {
    endpoint = `/${endpoint}/${action}/${codigo}`;
    return put({ endpoint: endpoint });
  }

  getProfiles = async () => {
    return get({ endpoint: "/roles" })
  };

  saveProfile = (data) => {
    return post({ endpoint: 'roles', data: data });
  };

  updateProfile = (data) => {
    return put({ endpoint: `roles/${data.codigo}`, data: data });
  }



  getProvinces = async () => {
    return get({ endpoint: '/provinces' });
  };


  saveProvince = (data) => {
    return post({ endpoint: 'provinces', data: data });
  };
  updateProvince = (data) => {
    return put({ endpoint: `provinces/${data.codigo}`, data: data });
  }

  getMunicipios = async () => {
    return get({ endpoint: '/municipalities' });
  };

  saveMunicipio = (data) => {
    return post({ endpoint: 'municipalities', data: data });
  };
  updateMunicipio = (data) => {
    return put({ endpoint: `municipalities/${data.codigo}`, data: data });
  }
  getComunas = async () => {
    return get({ endpoint: '/comunes' });
  };
  getNameRegisto = async () => {
    return get({ endpoint: '/configure' });
  };
  getNameDirector = async () => {
    return get({ endpoint: '/configureName' });
  };

  // nameRegisto = async (data) => {
  //   return post({ endpoint: 'configs', data: data });
  // };

  saveNameRegisto = (data) => {
    return post({ endpoint: '/configure', data: data });
  };
  saveNameDirector = (data) => {
    return post({ endpoint: '/configureName', data: data });
  };

  // nameRegisto = (data) => {
  //   return put({ endpoint: `/configs/${data.codigo}`, data: data });
  // };

  saveComuna = (data) => {
    return post({ endpoint: '/comunes', data: data });
  };
  saveProdutor = (data) => {
    return post({ endpoint: '/producers', data: data });
  };
  updateProdutor = (data) => {
    return put({ endpoint: `producers/${data.codigo}`, data: data });
  }
  getProducers = async () => {
    return get({ endpoint: '/producers' });
  };
  saveTipoProdutor = (data) => {
    return post({ endpoint: '/producers-type', data: data });
  };
  updateTipoProdutor = (data) => {
    return put({ endpoint: `producers-type/${data.codigo}`, data: data });
  }
  getAtividadesPrincipais = async () => {
    return get({ endpoint: '/main-activity' });
  };
  saveAtividadePrincipal = (data) => {
    return post({ endpoint: '/main-activity', data: data });
  };
  updateAtividadePrincipal = (data) => {
    return put({ endpoint: `main-activity/${data.codigo}`, data: data });
  }
  getIfraEmpresa = async () => {
    return get({ endpoint: '/company-infrastructure' });
  };
  saveIfraEmpresa = (data) => {
    return post({ endpoint: '/company-infrastructure', data: data });
  };
  updateIfraEmpresa = (data) => {
    return put({ endpoint: `company-infrastructure/${data.codigo}`, data: data });
  }
  getProducersType = async () => {
    return get({ endpoint: '/producers-type' });
  };
  updateComuna = (data) => {
    return put({ endpoint: `comunes/${data.codigo}`, data: data });
  }

  getExplorationTypes = async () => {
    return get({ endpoint: '/exploration-types' });
  };

  saveExplorationType = (data) => {
    return post({ endpoint: 'exploration-types', data: data });
  };

  updateExplorationType = (data) => {
    return put({ endpoint: `exploration-types/${data.codigo}`, data: data });
  }

  getSocialObjects = async () => {
    return get({ endpoint: '/social-objects' });
  };

  saveSocialObjects = (data) => {
    return post({ endpoint: 'social-objects', data: data });
  };

  updateSocialObjects = (data) => {
    return put({ endpoint: `social-objects/${data.codigo}`, data: data });
  }

  getBairros = async () => {
    return get({ endpoint: '/bairros-aldeias' });
  };

  saveBairro = (data) => {
    return post({ endpoint: 'bairros-aldeias', data: data });
  };
  updateBairro = (data) => {
    return put({ endpoint: `bairros-aldeias/${data.codigo}`, data: data });
  }
  getDepartamentos = async () => {
    return get({ endpoint: '/departments' });
  };

  saveDepartamento = (data) => {
    return post({ endpoint: 'departments', data: data });
  };
  updateDepartamento = (data) => {
    return put({ endpoint: `departments/${data.codigo}`, data: data });
  }
  getEntidades = async () => {
    return get({ endpoint: '/entities' });
  };

  saveEntidade = async (data) => {
    return post({ endpoint: 'entities', data: data });
  };

  updateEntidade = async (data) => {
    return put({ endpoint: `entities/${data.codigo}`, data: data });
  }

  getForms = async () => {
    return get({ endpoint: '/forms' });
  };

  saveForm = async (data) => {
    return post({ endpoint: 'forms', data: data });
  };

  updateForm = (data) => {
    return put({ endpoint: `forms/${data.codigo}`, data: data });
  }

  getEpocas = async () => {
    return get({ endpoint: '/seasons' });
  };

  saveEpoca = async (data) => {
    return post({ endpoint: 'seasons', data: data });
  };

  updateEpoca = (data) => {
    return put({ endpoint: `seasons/${data.codigo}`, data: data });
  }

  getUserData = async () => {
    let result = databaseService.isLogged();
    return result;
  };

  getUsers = async () => {
    return get({ endpoint: '/users' });
  };

  saveUser = async (data) => {
    return post({ endpoint: 'users', data: data });
  };

  updateUser = (userId, data) => {
    try {
      return put({ endpoint: `users/${userId}`, data: data });
    } catch (error) {
      //console.log("error", error);
    }
  };

  updateUser2 = (data) => {
    try {
      var toSend = data?.get('codigo');
      return put({ endpoint: `users/${toSend}`, data: data });
    } catch (error) {
      //console.log("error", error);
    }

  };
  startInquiry = async (data) => {
    return put({ endpoint: `inquiries/start-inquiry/${data.codigo}`, data: data })
  }

  activateUser = async (data) => {
    return put({ endpoint: `users/disable-user/${data.codigo}`, data: data })
  }

  disActivateUser = async (data) => {
    return put({ endpoint: `users/enable-user/${data.codigo}`, data: data })
  }

  endInquiry = async (data) => {
    return put({ endpoint: `inquiries/end-inquiry/${data.codigo}`, data: data })
  }

  changePassword = async (data) => {
    return put({ endpoint: `users/reset-password/${data.codigo}`, data: data })
  }

  getInqueritos = async () => {
    return get({ endpoint: '/inquiries' });
  };

  saveInquerito = async (data) => {
    return post({ endpoint: '/inquiries/backoffice', data: data });
  };
  updateInquerito = async (data) => {
    return put({ endpoint: `inquiries/${data.codigo}`, data: data });
  }

  getTipoCulturas = async () => {
    return get({ endpoint: '/culture-types' });
  };

  saveTipoCultura = async (data) => {
    return post({ endpoint: '/culture-types', data: data });
  };
  updateTipoCultura = async (data) => {
    return put({ endpoint: `culture-types/${data.codigo}`, data: data });
  }

  getEfectivoPecuario = async () => {
    return get({ endpoint: '/effective-livestock' });
  };

  saveEfectivoPecuario = async (data) => {
    return post({ endpoint: '/effective-livestock', data: data });
  };
  updateEfectivoPecuario = async (data) => {
    return put({ endpoint: `effective-livestock/${data.codigo}`, data: data });
  }




  getAdminDatas = async () => {
    return get({ endpoint: '/admistrative-data' });
  };

  getAdminDataByStatus = async (estado, entidade) => {
    return get({ endpoint: `/admistrative-data/status/${estado}/entidade/${entidade}` });
  };

  getAdminDataByMultipleStatus = async (estados) => {
    return get({ endpoint: `/admistrative-data/multiple-status/${estados}` });
  };

  getAdminDataByStatusAndEntidadeSuperior = async (estado, entidade) => {
    return get({ endpoint: `/admistrative-data/status/${estado}/entidade-superior/${entidade}` });
  };

  getAdminDataByMultipleStatusAndEntidadeSuperior = async (estado, entidade) => {
    return get({ endpoint: `/admistrative-data/multiple-status/${estado}/entidade-superior/${entidade}` });
  };

  saveAdminData = async (data) => {
    return post({ endpoint: 'admistrative-data', data: data });
  };

  updateAdminData = async (data) => {
    return put({ endpoint: `admistrative-data/${data.codigo}`, data: data });
  }

  getElasticsearchAdminDataByFormName = async (data) => {
    return post({ endpoint: `admistrative-data/get-elastic-data`, data: data });
  };

  getElasticsearchInquiryData = async (data) => {
    return post({ endpoint: `inquiries/get-elastic-data`, data: data });
  };

  getIndicatorsData = async (entidade) => {
    return get({ endpoint: `/indicators/${entidade}` });
  };

  getGraphsData = async () => {
    return get({ endpoint: '/indicators/admin-data/timeline' });
  };
  getGraphsDataSuperior = async (entidade) => {
    return get({ endpoint: `/indicators/admin-data/timeline/entidade-superior/${entidade}` });
  };

  getGraphsPieDataSuperior = async (entidade) => {
    return get({ endpoint: `/indicators/admin-data/entidade-superior/${entidade}` });
  };

  updateElasticsearchDataByFormName = async (data) => {
    return post({ endpoint: `admistrative-data/update-elastic-data`, data: data });
  };

  sendProfileProfilePic = async ({ user_id, image }) => {
    let formData = new FormData()
    formData.append('user_id', user_id)
    formData.append('profile_pic', image)
    return post({ endpoint: 'users/update-picture', data: formData });
  }

  getAuditTrails = async () => {
    return get({ endpoint: '/audit-trails' });
  };

  getAll = () => {
    return httpClient.get('/');
  };

  get = (id) => {
    return httpClient.get(`/${id}`);
  };

  auth = async (email, password) => {
    let userData = await httpClient
      .post(
        '/auth',
        { email, password },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        },
        {
          validateStatus: function (status) {
            return status < 400;
          },
        },
      )
      .catch(function (error) {
        //console.log(error);
        return error
      });

    let result = {};
    if (userData?.error) {
      result.success = false
      result.msg = userData.msg
    } else {
      if (!userData?.data?.data) {
        result.success = false
        result.msg = "Erro encontrado ao efectuar o login."
        return
      }

      result.success = true
      result.data = userData?.data?.data
    }

    return result;
  };

  create = (data) => {
    return post({ endpoint: `/`, data: data });
  };

  update = (id, data) => {
    return put({ endpoint: `/${id}`, data: data });

  };

  remove = (id) => {
    return handleDelete({ endpoind: `/${id}` });
  };

  removeAll = () => {
    return handleDelete({ endpoind: '/' });
  };

  findByTitle = (title) => {
    return get({ endpoint: `/?title=${title}` });
  };

  getPermissions = async () => {
    return get({ endpoint: `/permissions` });
  };

  savePermission = (data) => {
    //console.log(data)
    if (data.codigo) return put({ endpoint: `/permissions/${data.codigo}`, data: data });
    return post({ endpoint: 'permissions', data: data });
  };

  deleteProdutor = (id) => {
    return handleDelete({ endpoint: `/producers/${id}` });
  };

  deleteInquiries = (id) => {
    return handleDelete({ endpoint: `/inquiries/${id}` });
  };
  deleteProvince = (id) => {
    return handleDelete({ endpoint: `provinces/${id}` });
  };
  deleteForms = (id) => {
    return handleDelete({ endpoint: `forms/${id}` });
  };
  deleteProfile = (id) => {
    return handleDelete({ endpoint: `roles/${id}` });
  };
  deleteMunicipio = (id) => {
    return handleDelete({ endpoint: `municipalities/${id}` });
  };

  deleteComuna = (id) => {
    return handleDelete({ endpoint: `provinces/${id}` });
  };
  deleteEpoca = (id) => {
    return handleDelete({ endpoint: `seasons/${id}` });
  };
  deleteExplorationType = (id) => {
    return handleDelete({ endpoint: `exploration-types/${id}` });
  };
  deleteSocialObjects = (id) => {
    return handleDelete({ endpoint: `social-objects/${id}` });
  };
  deleteDepartments = (id) => {
    return handleDelete({ endpoint: `departments/${id}` });
  };
  deleteTipoProdutor = (id) => {
    return handleDelete({ endpoint: `producers-type/${id}` });
  };
  deleteTipoCultura = (id) => {
    return handleDelete({ endpoint: `culture-types/${id}` });
  };
  deleteActividadesPrincipal = (id) => {
    return handleDelete({ endpoint: `main-activity/${id}` });
  };
  deleteEntities = (id) => {
    return handleDelete({ endpoint: `entities/${id}` });
  };
  deleteInfraEmpresa = (id) => {
    return handleDelete({ endpoint: `company-infrastructure/${id}` });
  };

  deleteEfecticoPecuario = (id) => {
    return handleDelete({ endpoint: `effective-livestock/${id}` });
  };

  changeDefaultPwd = (data) => {
    return put({ endpoint: `/users/change-default-password/${data.codigo}`, data: data });
  };

  getAdminData = async ({ codigo, form }) => {
    //console.log("getAdminData", { codigo, form });

    return await formBuilderApiService.getAdminData({ codigo, form });
  };
  getDeleteForm = async (formName) => {
    //console.log("getDeleteForm", formName);

    return await formBuilderApiService.getDeleteForm(formName);
  };

  synchronize = async (formName, formData) => {
    return await formBuilderApiService.synchronize(formName, formData);
  };

  getFormBuilderForms = async () => {
    return await formBuilderApiService.getAll();
  };


  getSortedFormBuilderForms = async () => {
    return await formBuilderApiService.getAllSorted();
  };

  getFormBuilderFormByName = async (name) => {
    return await formBuilderApiService.getForm(name);
  };

  getFullAdress = async (name) => {
    return await formBuilderApiService.getFullAdress(name);
  };

  getDataByNif = async (docNumber, authToken = null) => {
    const apiUrl = `https://api.gov.ao/nif/v1/consultarNIF?tipoDocumento=NIF&numeroDocumento=${docNumber}`;
    const headers = new Headers({
      'Authorization': authToken,
      'Content-Type': 'application/json',
    });

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    let response = await fetch(apiUrl, requestOptions);
    let data = response.ok ? response.json() : {};
    return data;
  };

  getDataByBi = async (docNumber, authToken = null) => {
    const apiUrl = `https://api.gov.ao/bi/v1/getBI?bi=${docNumber}`;
    const headers = new Headers({
      'Authorization': authToken,
      'Content-Type': 'application/json',
    });

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    let response = await fetch(apiUrl, requestOptions);
    let data = response.ok ? response.json() : {};
    return data;
  };

  getDataByBiOrNif = async (docNumber) => {

    const authToken = 'Basic ' + btoa('minagrif:Nz#$20!23Mg');

    let dataByBi = await this.getDataByBi(docNumber, authToken);
    let dataByNif = await this.getDataByNif(docNumber, authToken);

    dataByBi = dataByBi.data;
    dataByNif = dataByNif.data;

    if (dataByNif || dataByBi)
      return {
        nome: dataByNif?.nome_contribuinte || (dataByBi?.first_name ? `${dataByBi?.first_name} ${dataByBi?.last_name}` : ''),
        email: dataByNif?.email,
        municipio: dataByBi?.birth_municipality_name || dataByNif?.municipio_morada,
        provincia: dataByNif?.provincia_morada || dataByBi?.birth_province_name,
        comuna: dataByBi?.residence_commune_name || dataByNif?.comuna_morada,
        aldeiaBairro: dataByBi?.residence_neighbor || dataByNif?.bairro_morada,
        contacto: dataByNif?.numero_contacto,
        companyCreatedDate: dataByNif?.data_constituicao,
      }

    return null;
  };
}

export const userApiService = {
  api
};
