import React, {
    useState,
    useEffect,
  } from 'react';
  import MenuSharp from '@material-ui/icons/MenuSharp';
  import styl from '../../assets/css/style.css'
  import { logout, addFormData, getFormData, isMapOpen } from '../../services/database.service'
  import { formBuilderApiService } from '../../services/form_builder_api'
  import { userApiService } from '../../services/api'
  import { getFormList } from '../../services/database.service'
  import AuditTrails from '../../pages/AuditTrails';
  import { makeStyles } from '@mui/styles';
  import style from '../../themes/styles/geral';
  import icones from '../../themes/icones';
  import Box from "@material-ui/core/Box";
  import s_image from '../../assets/images/s30.png';
  import Button from '@mui/material/Button';
  import Menu from '@mui/material/Menu';
  import MenuItem from '@mui/material/MenuItem';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import PersonIcon from '@mui/icons-material/Person';
  import BuildIcon from '@mui/icons-material/Build';
  import LogoutIcon from '@mui/icons-material/Logout';
  import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
  import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
  import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
  import SummarizeIcon from '@mui/icons-material/Summarize';
  import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
  import NomearRegisto from '../../pages/NomeRegisto/NomearRegisto';
  import {
    getPolygon
  }
  from '../../services/database.service'

  import api from "../../services/api";
  import { permissionsMapped } from "../../utils/permissionsMapped";
  import { removeFormBuilderCss } from "../../utils/events";
  import { ToastContainer, toast } from "react-toastify";

  const useStyle = makeStyles(style, styl);

  export default function SubbarMenu(props) {
    const classes = useStyle();
    const [formSubmit, setFormSubmit] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(Boolean(anchorEl));
    const [ menuItems, setMenuItems ] = useState([{isOpen: false}, {isOpen: false}]);
    const { handleOpen, changeScreen, showMsg, setLogged, designMode, setDesignMode, user } = props;
    let internetConection = window.navigator?.onLine || false;
    const userApiService = new api();

    useEffect(function () {
    }, [])

    const notify = () =>
    toast.error("Nao Tens Permissão Para Acessar esta Pagina!");


  const handlePageChange = async (fn, action) => {
    var loggedUser = await userApiService.getUserData();
    var pagePerm = permissionsMapped[action];
    loggedUser.allPermissionsMapped = localStorage.getItem('allPermissionsMapped').split(',')

    // var hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
    //   return pagePerm === item;
    // });


    var hasPermission = null;
    if (loggedUser?.codigo == 1){

      hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
        return pagePerm === item;
      });

    }else{

      hasPermission = loggedUser?.permissions?.filter((item) => {
        return pagePerm === item.nome;
      });
    }

    const permissionLength = hasPermission?.length;
    if (!permissionLength > 0) {
      notify();
      return;
    }

    removeFormBuilderCss()
    fn(action);
  };


    const handleOpenMenu = (event, index) => {
      const anchor = event.currentTarget ?? event.target;
      var _menuItems = menuItems;
      _menuItems.forEach((el) => {
        el.isOpen = false;
      });
      _menuItems[index].isOpen = true;
      setMenuItems(_menuItems);
      setAnchorEl(anchor);
    };

    const handleClose = () => {
      var _menuItems = menuItems;
      _menuItems.forEach((el) => {
        el.isOpen = false;
      });
      setMenuItems(_menuItems);
      setAnchorEl(null);
    };

    const handleViewAuditoria = () => {
      changeScreen('audit_trail');
      handleClose();
    }
    const handleShowModal = () => {
        changeScreen('renomear_registo');
        handleClose();
      }

    const handleShowModalNomeDirector = () => {
        changeScreen('renomear_director');
        handleClose();
      }
    return (
      <>
        <Box className={`${classes.userContainer} h-100 d-flex flex-row-reverse d-none d-md-block m-0 p-0 position-absolute end-0`} >
          <Box className={`position-absolute ww-auto d-flex end-0 h-100 p-0 pr-2 `}>
            <div>
              <Button onClick={(el) => handleOpenMenu(el, 1)} className={`menu-btn h-100 mr-2`}
                style={{ marginTop: '-15px', paddingRight: '390px' }}
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
              >
              </Button>
              <Menu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={menuItems[1].isOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handlePageChange(handleShowModal, "entidades_create")} disableRipple >
                    <DriveFileRenameOutlineIcon />Editar Nº de Registro
                </MenuItem>
                <MenuItem onClick={() => handlePageChange(handleShowModalNomeDirector, "entidades_create")} disableRipple >
                    <DriveFileRenameOutlineIcon />Editar nome Director
                </MenuItem>
                <MenuItem onClick={() => handlePageChange(handleViewAuditoria, "audit_trail")} disableRipple>
                  <SummarizeIcon /> Auditoria
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Box>
        <Button onClick={handleOpen} className={`${classes.menuButtonRight} h-100 position-absolute end-0 p-0 d-md-none`} >
          <MenuSharp />
        </Button>
      </>
    );
  };
