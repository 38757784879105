import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import { Box } from "@mui/system";
import Button from "@material-ui/core/Button";
import { capFirstLetter } from "../../utils/string";
import FormControl from "@mui/material/FormControl";
import ReactTooltip from 'react-tooltip';
import FormLabel from "@mui/material/FormLabel";
import api from "../../services/api";
import Input from "@material-ui/core/Input";
import minagrip_s from "../../assets/images/minagrip-s.png";
import validator from "../../services/validator.service";
import { saveLoggedUser } from "../../services/database.service";
import { BootstrapDialog } from "../../components/bootstrapDialog";
import "../../assets/css/react-html5-camera-photo.css";
import { ChangePictureActionButton } from "../../components/changePictureActionButton";

const userApiService = new api();
const validation = new validator();

export default function MinhaConta(props) {
  const [user, setUser] = useState(true);
  const { setLogged, setOpenSpinner } = props;
  // const [logged, setLogged] = useState(true);
  const [contacto, setContacto] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirpassword, setConfirPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { hideAlertShow, showMsg, changeScreen,} = props;

  const [dataUri, setDataUri] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [showCameraItem, setShowCameraItem] = useState(false);
  const [profilePic, setProfilePic] = useState("");

  useEffect(() => {
    validation.setShowMsg(showMsg.error);
    ReactTooltip.rebuild();
    async function fetchData() {
      let _user = await userApiService.getUserData();
      setUser(_user);
      setContacto(_user?.contacto ?? "");

      configureProfilePic(_user);
    }

    fetchData();
  }, []);

  const configureProfilePic = (paramUser = null) => {
    let _user = user;

    if (paramUser) {
      _user = paramUser;
    }

    //console.log("user -configureProfilePic", _user);

    const exisPropPic = _user?.profile_pic
      ? userApiService.getProfilePic(_user?.profile_pic)
      : minagrip_s;
    setProfilePic(exisPropPic);
  };

  const setData = (minhaConta = null) => {
    if (!minhaConta) return;
   
    setUser(minhaConta);
    setContacto(minhaConta?.contacto ?? "");
 
    saveLoggedUser(minhaConta);
  };

  const setDataResetPassword = (minhaConta = null) => {
    setUser(minhaConta);
    setNewPassword(minhaConta?.password ?? "");
    setConfirPassword(minhaConta?.password ?? "");
  };

  const showResetPassword = async () => {
    setShowPassword(true);
  };
  const hideForm = async () => {
    setShowPassword(false);
  };

  const resetPassword = async () => {
    const resetRow = {
      codigo: user.codigo,
      actualPassword: password,
      newPassword: newpassword,
      confirpassword: confirpassword,
      hashedPassword: user.password,
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: password, label: "Senha Actual", type: "required" },
        { value: newpassword, label: "Nova Senha", type: "required" },
        {
          value: newpassword,
          value2: confirpassword,
          label: "Confirmar nova Senha",
          type: "confirm_password",
        },
      ])
    ) {
      return false;
    }
    let result2 = await userApiService.changePassword(resetRow);
    if (result2?.success) {
      setDataResetPassword(result2?.data);
      changeScreen("minha_conta");
      showMsg.success("Dados salvos com sucesso!");
    } else {
      showMsg.error(
        result2?.exception.msg || "Houve erro ao salvar a entidade"
      );
    }
  };

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
    configureProfilePic();
  };

  const handleOpenTakeFotoModal = () => {
    setOpenPictureModal(true);
    setShowCameraItem(true);
    setAnchorEl(null);
    setDataUri(null);
    configureProfilePic();
  };

  const handleOpenGallery = () => {
    setAnchorEl(null);
    setDataUri(null);
    configureProfilePic();
  };

  const handleClosePictureModal = () => {
    setOpenPictureModal(false);
    setShowCameraItem(false);
  };

  const handleClosePictureModalReset = () => {
    setDataUri(null);
    handleClosePictureModal();
    configureProfilePic();
  };

  const handleTakePhoto = (dataUri) => {
    try {
      createPreview(dataUri);
      setDataUri(dataUri);
    } catch (error) {
      //console.log("Error > createImage");
      //console.log(error);
    }
  };

  const handleTakePhotoAnimationDone = (dataUri) => {
    try {
      createPreview(dataUri);
      setDataUri(dataUri);
    } catch (error) {
      //console.log("Error > createImage");
      //console.log(error);
    }
  };

  const handleCameraError = (error) => {
    //console.log("handleCameraError", error);
  };

  const onChangeSelectedImage = async (e) => {
    try {
      const imageBlob = await createImage(e.target.files[0]);
      createPreview(imageBlob);
      setDataUri(imageBlob);
    } catch (error) {
      //console.log("Error > createImage");
      //console.log(error);
    }
  };

  const createImage = (imageBlob) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    } catch (error) {
      //console.log("Error > createImage");
      //console.log(error);
    }
  };

  const createPreview = (imageBlob) => {
    try {
      setProfilePic(imageBlob);
    } catch (error) {
      //console.log("Error > createImage");
      //console.log(error);
    }
  };

  const handleRemoveDataUri = () => {
    setDataUri(null);
    configureProfilePic();
  };

  const saveForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      contacto: contacto,
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: contacto, label: "Contacto", type: "required" },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateUser(user?.codigo, rowData);
    if (result?.success) {
      setData(result?.data?.user);
      showMsg.success("Dados salvos com sucesso!");
    } else {
      showMsg.error(result?.exception.msg || "Houve erro ao salvar a entidade");
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const handleRepeatPicture = () => {
    setDataUri(null);
    configureProfilePic();
  };

  const handleSendToServer = async () => {
    setOpenSpinner(true);

    let result = await userApiService.sendProfileProfilePic({
      user_id: user.codigo,
      image: dataUri,
    });

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
      setOpenSpinner(false);
    }, 5 * 1000);

    if (result?.success) {
      
      setShowCameraItem(false);
      setOpenPictureModal(false);
      setDataUri(null);
      setAnchorEl(null);
      setData(result?.data?.user);
      showMsg.success("Imagem de perfil actualizada com sucesso!");
    } else {
      showMsg.error(
        result?.exception.msg || "Houve erro ao actualizar a imagem de perfil"
      );
    }
  };

  const openPopOver = Boolean(anchorEl);
  const popOverId = openPopOver ? "simple-popover" : undefined;
  const isFullscreen = true;

  var dialogProps = {
    isFullscreen,
    dataUri,
    openPictureModal,
    handleRepeatPicture,
    handleClosePictureModal,
    handleClosePictureModalReset,
    handleTakePhotoAnimationDone,
    handleCameraError,
    handleSendToServer,
    showCameraItem,
  };

  const actionButtonProps = {
    dataUri,
    openPopOver,
    handleOpenTakeFotoModal,
    handleOpenGallery,
    onChangeSelectedImage,
    anchorEl,
    popOverId,
    handleOpenPopOver,
    handleClosePopOver,
    handleSendToServer,
    handleRemoveDataUri,
  };

  return (
    <Row
      className={`listagem position-relative .container-fluid w-100 p-3 m-0`}
    >
      <Box className={`listagem-headerr d-flex flex-row p-0`}>
      <Box className={`listagem-header`}>
        <strong>MINHA</strong>&nbsp;CONTA
        </Box>
        <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
        <div className="allbtns">
          <Button onClick={saveForm} className={`right-btn m-1`} data-tip='Salvar Informação' data-for='save'>
            Salvar
          </Button>
          <ReactTooltip id='save' />
          </div>
        </Box>

      </Box>
      <Box
        className={`minha-conta-container d-flex flex-row h-100 p-3 pb-3`}
      >
        <Col sm={3} className={`d-flex d-6 mr-4`}>
          <div
            className={`user-profile-img-cont d-flex flex-column flex-fill`}
          >
            <div>
              <img
                className={`user-profile-img mr-2 p-0`}
                src={profilePic}
              />
            </div>
            <ChangePictureActionButton {...actionButtonProps} />
          </div>
        </Col>
        <Col sm={9} className={`d-flex flex-column d-6 `}>
          <Col sm={12} className={`d-flex flex-row d-6 `}>
            <Col sm={4} className={`d-flex flex-column d-6 `}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Nome</strong>
                </FormLabel>
                <Input
                  type="text"
                  label="Nome"
                  disableUnderline={true}
                  disabled={true}
                  value={user?.nome}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Perfil</strong>
                </FormLabel>
                <Input
                  type="text"
                  label="Titulo"
                  disableUnderline={true}
                  disabled={true}
                  value={user?.role?.nome}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </FormControl>
              <FormControl component="fieldset" className="mb-2 required">
                <label className={`input-label-23`}>Contacto:</label>
                <Input
                  type="text"
                  label="contacto"
                  disableUnderline={true}
                  value={contacto}
                  onChange={(e) => setContacto(e.target.value)}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </FormControl>
            </Col>
            <Col sm={4} className={`d-flex flex-column d-6 `}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Email</strong>
                </FormLabel>
                <Input
                  type="email"
                  disableUnderline={true}
                  disabled={true}
                  value={user?.email}
                  className={`input-2 large-font`}
                />
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Departamento</strong>
                </FormLabel>
                <Input
                  type="text"
                  label="Departamento"
                  disableUnderline={true}
                  disabled={true}
                  value={user?.departamento?.nome}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Entidade</strong>
                </FormLabel>
                <Input
                  type="text"
                  label="Departamento"
                  disableUnderline={true}
                  disabled={true}
                  value={user?.entidade?.nome}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </FormControl>
            </Col>
          </Col>
          <Col sm={12} className={`emc-btn d-flex flex-column d-6 `}>
            &nbsp;
            <Col sm={4} className={`d-flex flex-column d-6 `}>
              {showPassword === false ? (
                
                <Button
                  onClick={showResetPassword}
                  className={`right-btni m-2 ml-1 bg-warning`}
                  data-tip='Redefinir Senha' data-for='redifinir'
                >
                  Redefinir Senha
                </Button>
                
                
              ) : (
                
                <Button
                  onClick={hideForm}
                  className={`right-btni m-2`}
                >
                  Fechar
                </Button>
              )}
              <ReactTooltip id='redifinir'/>
            </Col>
          </Col>
        </Col>{" "}
      </Box>
      {showPassword && (
        <Box
          className={`listagem-tabela h-100 p-5 pt-2`}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Col sm={3} className={`d-flex d-6 mr-4`}></Col>
          <Col sm={9} className={`d-flex flex-column d-6 `}>
            <Col sm={12} className={`d-flex flex-row d-6 `}>
              <FormControl component="fieldset" className="mb-2 required">
                <FormLabel component="legend">
                  <label>Senha Actual</label>
                </FormLabel>
                <Input
                  type="password"
                  disableUnderline={true}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`input-2 large-font`}
                />
              </FormControl>

              <FormControl>
                <FormLabel component="legend" className="mb-2 required">
                  <label> Nova Senha</label>
                </FormLabel>
                <Input
                  type="password"
                  disableUnderline={true}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={`input-2 large-font`}
                />
              </FormControl>

              <FormControl>
                <FormLabel component="legend" className="mb-2 required">
                  <label>Confirmar nova Senha</label>
                </FormLabel>
                <Input
                  type="password"
                  disableUnderline={true}
                  onChange={(e) => setConfirPassword(e.target.value)}
                  className={`input-2 large-font`}
                />
              </FormControl>
            </Col>
            <FormControl style={{ width: "250px", marginLeft:'-6px',  }}>
              <Button
                onClick={resetPassword}
                className={`right-btn m-2`}
                data-tip='Redefinir a Senha' data-for='redifinirr'
              >
                Redefinir Senha
              </Button>
              <ReactTooltip id='redifinirr'/>
            </FormControl>
          </Col>
        </Box>
      )}

      <BootstrapDialog {...dialogProps} />

      {/* <form onSubmit={handleSubmitProfilePic}>
                <input type='file' name='file' onChange={handleFileChange}></input>
                <button type='submit'>Submit</button>
            </form> */}
    </Row>
  );
}
