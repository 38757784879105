import React, {
  useState,
  useEffect,
} from 'react';

import api from '../../services/api'
import createErrorMessage from "../../utils/createErrorMessage"
import Myframe from '../Myframe';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { Col, Row } from 'react-bootstrap';
import DATATABLE from "../../components/DataTable/dataTable"
import SelectLabels from '../../components/SelectLabels';
import Actions from '../../components/Actions';
import validator from '../../services/validator.service'
import ConfirmBox from '../../components/ConfirmBox'
import StringMessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton';
const validation = new validator();
const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Comunas',
    field: 'nome',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Sigla',
    field: 'sigla',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Município',
    field: 'municipio',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 200
  }
];

export default function Comunas(props) {
  const [formMenu, setFormMenu] = useState(false);
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Formulário');
  const [openForm, setOpenForm] = useState();
  const [formSubmit, setFormSubmit] = useState();
  const [nome, setNome] = useState();
  const [sigla, setSigla] = useState();
  const [municipio, setMunicipio] = useState();
  const [codigo, setCodigo] = useState();
  const [foto, setFoto] = useState();
  const [provincia, setProvincia] = useState();
  const [listaProvs, setListaProvs] = useState();
  const [listaMunicipios, setListaMunicipios] = useState();
  const [listaMunicipiosTemp, setListaMunicipiosTemp] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();

    let list = await userApiService.getMunicipios() ?? [];
    let listPro = await userApiService.getProvinces() ?? [];

    permissionHandle(list, 'Municipios')
    permissionHandle(listPro, 'Provincias')


    if (Array.isArray(list)) {
      list = list.map((item) => {
        return { codigo: item.codigo, nome: item.nome, provincia: item.provincia.codigo }
      })
    } else {
      list = []
    }

    setListaMunicipios(list);

    if (Array.isArray(listPro)) {
      listPro = listPro.map((item) => {
        return { codigo: item.codigo, nome: item.nome }
      })
    } else {
      listPro = []
    }


    setListaProvs(listPro);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const handleProviaUpdate = (event) => {
    setMunicipalitiesByProvince(event.codigo)
  }

  const setMunicipalitiesByProvince = (prov = null) => {
    if (listaMunicipios) {
      const aux = listaMunicipios.filter((obj) => {
        if (obj?.provincia === prov) return obj?.nome;
      })
      setListaMunicipiosTemp(aux)
    }
  }


  const setData = (comuna = null) => {
    setCodigo(comuna?.codigo);
    setNome(comuna?.nome);
    setSigla(comuna?.sigla);
    setMunicipio(comuna?.municipio);
    setOpenForm(true);
  }

  const handleEdit = (prov) => {
    setData(prov);
    setOpenForm(true);
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();
  const showConfirmBox = () => {
    var nome = 'Comuna?'
    return (
      showConfirm ? <ConfirmBox msg={StringMessage.message2 + ` ${nome}`} handleResult={deleteData} /> : null
    );
  }

  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  }
  const handleDelete = (obj) => {
    setCodigo(obj?.codigo)
    setShowConfirm(true)
  }

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteAny('comunes', codigo);
      if (result?.success) {
        loadTable();
        setOpenForm(false);
        showMsg.success(StringMessage.messageRemove)
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const saveForm = async () => {
    setOpenSpinner(true);
    const prov = { codigo: codigo, nome: nome, sigla: sigla, municipio: municipio?.codigo };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
        { value: prov.municipio, label: 'Município', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveComuna(prov);
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(false);
      showMsg.success(StringMessage.messageSave)
    } else {
      showMsg.error(createErrorMessage(result))
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }
  const updateForm = async () => {
    setOpenSpinner(true);
    const prov = { codigo: codigo, nome: nome, sigla: sigla, municipio: municipio?.codigo };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' },
        { value: prov.municipio, label: 'Município', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateComuna(prov);
    let message = StringMessage.messageEditar
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(false);
      showMsg.success(message)
    } else {
      let error = result?.exception?.msg
      showMsg.error(error)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const loadTable = async () => {
    // let list = ((await userApiService.getComunas()) ?? []).reverse();
    let list = (await userApiService.getComunas()) ?? [];

    permissionHandle(list, 'Comunas')

    if (Array.isArray(list)) {
      list.reverse();
    } else {
      list = []
    }
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: count++,
          nome: l.nome,
          sigla: l.sigla,
          municipio: l.municipio.nome,
          action: (<Actions page='comunas' value={l} handleEdit={handleEdit} handleDelete={handleDelete} />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }

  const handleTitle = (params) => {
    const { title = 'Comunas', subtitle = 'Cadastradas', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const start = () => {
    return openForm ? showForm() : handleFormMenus();
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>COMUNAS</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='comunas'
              handleAdd={handleAdd}
              dataTip={'Adicionar Comuna'}
              dataFor={'adicionar'}
              addText={'Adicionar'} />
          </div>
        </Box>

        {iframeUrl ?
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
            {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
          :
          <>
            <Box className={`listagem-tabela flex-fill d-12`}  >
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        }
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >

        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>CADASTRAR</strong>&nbsp;NOVA COMUNA
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(false)} className={`cancel-btn m-1`} >
                Cancelar
              </Button>
              <Button onClick={saveForm} className={`right-btn m-1`} style={{ display: saveButtonVisible }}>
                Salvar
              </Button>
              <Button onClick={updateForm} className={`right-btn m-1`} style={{ display: editButtonVisible }}>
                Actualizar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column h-100 p-5`}  >
          <Col sm={12} className={`d-flex flex-row d-6 w-100 `}  >
            <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
              <label className={`input-label-23`} >Província:</label>
              <SelectLabels
                initialValue={provincia}
                setValue={handleProviaUpdate}
                lista={listaProvs}
                className={`select-labels`}
              />
            </Col>
            <Col md={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
              <label className={`input-label-23`} >Município:</label>
              <SelectLabels
                initialValue={municipio}
                setValue={setMunicipio}
                lista={listaMunicipiosTemp}
                className={`select-labels`}
              />
            </Col>
            {/* <Col md={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
            </Col> */}
          </Col>
          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col md={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Nome:</label>
                <Input
                  type='select'
                  label="Nome"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  //onKeyPress={keyPressed}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col md={6} className={`d-flex flex-column  justify-content-start large-font input-container-2`} >
                <label className={`input-label-23`} >Sigla:</label>
                <Input
                  type='text'
                  label="nome"
                  disableUnderline={true}
                  value={sigla}
                  onChange={(e) => setSigla(e.target.value)}
                  //onKeyPress={keyPressed}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
          </Col>
        </Box>
      </Row >)
  }

  return (
    start()
  );

};
