const storage = window ? window.localStorage : undefined;

export class database {

  init() {
    return storage;
  }

  set(key, value) {
    storage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    try {
      return JSON.parse(storage.getItem(key));
    } catch(error) {
      return null
    }
  }

  delete(key) {
    return storage.removeItem(key);
  }

  reset() {
    return storage.clear();
  }

  getAll() {
    let result = {};
    for (let i in storage) {
      if (this.get(i)) result[i] = this.get(i);
    }
    return result;
  }

  createCollection(name = null) {
    if (!name) return false;
    let col = this.get(name)
    if (col) return col;

    this.set(name, {});
    return this.get(name);
  }

  getCollectionAll(name) {
    if (!name) return false;
    let col = this.get(name)
    return col;
  }

  getCollectionKey(name, key) {
    if (!name || !key) return false;
    let col = this.get(name)
    return col[key];
  }

  addCollectionObject(name, key, value) {
    if (!name || !value) return false;
    let col = this.createCollection(name)

    if (!key) {
      key = Number(Object.values(col).length || 0) + 1;
    }

    if (key >= 1) {
      for (let index in col) {
        if (col[index] == value) { delete col[index]; }
      }
    }

    col[key] = value;
    this.set(name, col);
    return true;
  }

  removeCollectionObject(name, key) {
    if (!name || !key) return false;
    let target = this.get(name)
    if (target[key]) delete target[key];
    this.set(name, target);
    return true;
  }
};