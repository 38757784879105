import React, {
    useState,
    useEffect,
} from 'react';
import createErrorMessage from "../../utils/createErrorMessage"
import api from '../../services/api'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Actions from '../../components/Actions';
import { MDBDataTable } from 'mdbreact';
import validator from '../../services/validator.service'
import ConfirmBox from '../../components/ConfirmBox'
import { Col, Row } from 'react-bootstrap';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { capFirstLetter } from '../../utils/string';
import StringMessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton'
import { handlePageChange } from '../../utils/handlePageChange';

const validation = new validator();
const userApiService = new api();
const columns = [
    {
        label: '#',
        field: 'num',
        sort: 'asc',
        width: 10
    },
    {
        label: 'Permissões',
        field: 'descricao',
        sort: 'asc',
        width: 40
    },
    {
        label: 'Nome',
        field: 'nome',
        sort: 'asc',
        width: 40
    },
    {
        label: 'Acções',
        field: 'action',
        sort: 'asc',
        width: 50
    }
];

export default function Permissoes(props) {
    const [list, setList] = useState([]);
    const [openForm, setOpenForm] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false);
    const [codigo, setCodigo] = useState('');
    const [nome, setNome] = useState('');
    const [observacao, setObservacao] = useState('');
    const { hideAlertShow, openMap, showMsg } = props;

    useEffect(function () {
        validation.setShowMsg(showMsg.error);
        loadTable();
    }, [])


    const hideSpinner = () => {
        let spinningContainer = document.querySelector('#spinningContainer');
        let spinningCurtain = document.querySelector('#spinningCurtain');

        if (!spinningContainer || !spinningCurtain) return false;

        spinningContainer.remove()
        spinningCurtain.remove()
    }

    hideSpinner();

    const saveForm = async () => {
        const profile = {
            codigo: codigo,
            nome: nome,
            observacao: observacao,
        };

        let timer = setTimeout(() => {
            hideAlertShow();
            clearTimeout(timer);
        }, 5 * 1000);

        if (!validation.handleValidation(
            [
                { value: nome, label: 'Nome', type: 'required' },
            ])) {
            return false;
        }

        let result = await userApiService.savePermission(profile);
        if (result?.success) {
            setData();
            loadTable();
            setOpenForm(0);
            showMsg.success(StringMessage.messageSave)
        } else {
            showMsg.error(createErrorMessage(result))
        }
    }

    const setData = (permissao) => {
        setCodigo(permissao?.codigo)
        setNome(permissao?.nome);
        setObservacao(permissao?.observacao);
    }

    const handleAdd = () => {
        setData();
        setOpenForm(1);
    }

    const handleShow = (permissao) => {
        setData(permissao)
        setOpenForm(2);
    }

    const handleEdit = (permissao) => {
        setData(permissao);
        setOpenForm(3)
    }

    const handleDelete = (permissao) => {
        //console.log('0pronto')
        setCodigo(permissao?.codigo)
        setShowConfirm(true)
    }

    const showConfirmBox = () => {
        return (
            showConfirm ? <ConfirmBox msg={StringMessage.message} handleResult={deleteData} /> : null
        );
    }

    const editHandleView = (msg) => {
      //console.log('page ', msg);
      setOpenForm(3);
  }


    const deleteData = async (answer) => {
        if (answer) {
            let result = await userApiService.deleteAny('permissions', codigo);
            if (result?.success) {
                loadTable();
                setOpenForm(0);
                showMsg.success(StringMessage.messageRemove)
            } else {
                showMsg.error(StringMessage.messageRemoveError)
            }
        }
        setShowConfirm(false)
    }

    const loadTable = async () => {
        let list = ((await userApiService.getPermissions())?? []).reverse();
        let rows = [];
        let count = 1;
        if (list) {
            Object.values(list).map(l => {
                rows.push({
                    num: count++,
                    descricao: l?.observacao,
                    nome: l?.nome,
                    action: (<Actions value={l} page='permissoes' handleView={handleShow} handleEdit={handleEdit} handleDelete={handleDelete} />)
                });
            });
        }
        setList({ columns: columns, rows: rows })
    }

    const start = () => {
        switch (openForm) {
            case 0:
                return handleFormMenus();
            case 1:
                return editForm();
            case 2:
                return showForm();
            case 3:
                return editForm();
            default:
                break;
        }
    }


    const handleObservacao = (observacao) => {
        setObservacao(observacao);
    }

    const handleFormMenus = () => {
        return (
            <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
                {showConfirmBox()}
                <Box className={`listagem-headerr d-flex flex-row p-25`}>
                <Box className={`listagem-header `}>
                    <strong>PERMISSÕES</strong>&nbsp;CADASTRADAS
                    </Box>
                    <div className="allbtns">
                    <AddButton
                    page='permissoes'
                    handleAdd={handleAdd}
                    dataTip={'Adicionar permissoes'}
                    dataFor={'adicionar'}
                    addText={'Adicionar'}/>
                    </div>
                </Box>
                <Box className={`listagem-tabela flex-fill d-12`}  >
                    <MDBDataTable
                        className={`form-list flex-fill d-12`}
                        striped
                        bordered
                        small
                        data={list}
                    />
                </Box>
            </Box >)
    }

    const showForm = () => {
        return (
            <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`} >
                <Box className={`listagem-headerr d-flex flex-row p-25`}>
                <Box className={`listagem-header `}>
                    <strong>INFORMAÇÕES GERAIS</strong>
                    </Box>
                    <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
                    <div className="allbtns">
                    <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                            Voltar
                        </Button>
                        <Button onClick={() => handlePageChange(editHandleView, "permissoes", "edit", 'edit')} className={`right-btn m-1`} >
                            Editar
                        </Button>
                        </div>
                    </Box>
                </Box>
                <Box className={`listagem-tabela d-flex flex-row p-5 pb-3`}  >
                    <Col sm={4} className={`d-flex flex-column d-6 `}  >
                        <FormControl component="fieldset" className="mb-2">
                            <FormLabel component="legend"><strong>Nome</strong></FormLabel>
                            <FormLabel>{capFirstLetter(nome)}</FormLabel>
                        </FormControl>
                    </Col>
                    <Col sm={4} className={`d-flex flex-column d-6 `}  >
                        <FormControl component="fieldset" className="mb-2">
                            <FormLabel component="legend"><strong>Observação</strong></FormLabel>
                            <FormLabel>{observacao}</FormLabel>
                        </FormControl>
                    </Col>
                </Box>
            </Row>
        )
    }

    const editForm = () => {
        return (
            <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
                <Box className={`listagem-headerr d-flex flex-row p-25`}>
                <Box className={`listagem-header`}>
                    <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>&nbsp;PERMISSÃO
                </Box>
                    <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
                    <div className="allbtns">
                    <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                            Cancelar
                        </Button>
                        <Button onClick={saveForm} className={`right-btn m-1`} >
                            Salvar
                        </Button>
                        </div>
                    </Box>
                </Box>
                <Box className={`listagem-tabela d-flex flex-column h-100 p-1`}  >
                    <Col sm={12} className={`d-flex flex-column d-6 p-3`} >
                        <Col sm={12} className={`d-flex flex-column d-6  p-3`}  >
                            <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                                <label className={`input-label-23`} >Nome:</label>
                                <Input
                                    type='select'
                                    label="descricao"
                                    disableUnderline={true}
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    autoCapitalize={'none'}
                                    className={`input-2 large-font`}
                                />
                            </Col>
                        </Col>
                        <Col sm={12} className={`d-flex flex-row d-6 p-3`} >
                            <Col sm={12} className={`d-flex flex-column`} >
                                <label className={`large-font`} >Observação:</label>
                                <textarea value={observacao} className={`user-img`} onChange={(e) => { setObservacao(e.target.value) }} rows='6' />
                            </Col>
                        </Col>
                    </Col>
                </Box >
            </Row >)
    }

    return (
        start()
    );

};
