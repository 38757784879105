import React, { useState, useEffect } from "react";
import api from "../../services/api";
import createErrorMessage from "../../utils/createErrorMessage"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DATATABLE from "../../components/DataTable/dataTable"
import Box from "@material-ui/core/Box";
import StringMessage from "../../utils/string-messages";
const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Nomes Registrados',
    field: 'nome',
    sort: 'asc',
    width: 300
  },
];
function NomearDirector(props) {
  const [show, setShow] = useState(true);
  const [nomeDirector, setNomeDirector] = useState("");
  const [list, setList] = useState([]);
  const handleClose = () => setShow(false);
  const { showMsg, setOpenSpinner } = props;
  
  useEffect(async () => {
    loadTable()
    let nomeDirectorCong = (await userApiService.getNameDirector()) ?? [];
    setNomeDirector(nomeDirectorCong[0]?.nome)

    nomeDirectorCong = nomeDirectorCong.map((item) => {
      return {codigo:item.codigo, nomeDirector: item.nome };
    });
  }, []);

  const loadTable = async () => {
    let list = await userApiService.getNameDirector() || [];
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: count++,
          nome: l.nameDirector,
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }
  const handleSave = async () => {
    setShow(false)
    setOpenSpinner(true);
    const rowData = {
        nameDirector: nomeDirector,
    };

  

    let result = await userApiService.saveNameDirector(rowData);
    let formMessage = StringMessage.messageSave;
    //console.log('quem esta ai result',result)
    if (result?.success) {
      showMsg.success(formMessage);
    } else {
     
      showMsg.error(createErrorMessage(result));
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);

  };
 
  return (
    
    <>
      {/* <Button  className="bgColorMod" onClick={handleShow}>
        <DriveFileRenameOutlineIcon /> Renomaer Nº Registo{nomeRegisto}
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Registar novo Director</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome:</Form.Label>
              <Form.Control
                type="text"
                label="nomeDirector"
                disableUnderline={true}
                value={nomeDirector}
                placeholder="digite o nome do novo director"
                onChange={(e) => setNomeDirector(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
          <Box className={`listagem-tabela flex-fill d-12`}  >
          <DATATABLE
            className={`form-list flex-fill d-12`}
            data={list}
          />
        </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button  className="btnCancelColor" onClick={handleClose}>
            CANCELAR
          </Button>
         
          <Button className="btnSavelColor"  onClick={handleSave}>
            SALVAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default NomearDirector