import Button from "@material-ui/core/Button";
import ReactTooltip from 'react-tooltip';
import { handlePageChange } from '../../utils/handlePageChange';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, {
  useEffect,
} from 'react';




export default function AddButton(props) {

  const {
    handleAdd,
    dataTip,
    dataFor,
    addText,
    value,
    page

  } = props

 
  const add = () => {
    return (
      <div className="allbtns">
        <Button onClick={function () { handlePageChange(handleAdd, page, "create", value) }} className={`right-btn m-1`} >
          {addText}
        </Button>
        <ToastContainer/>
      </div>
    )
  }
  return (
    add()
  )
}