import React, {
  useEffect,
  useState,
} from 'react';
import { makeStyles } from "@mui/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuSharp from '@material-ui/icons/MenuSharp';
import GeocadMap from '../../components/map'
import style from '../../themes/styles/geral';
import logoMd from '../../assets/images/SNIEA.png';
import grafismo from '../../assets/images/banner-top.png';
import perfil_foto from '../../assets/images/perfil_photo.png';
import Formularios from '../Formularios'
//import Zonas from '../Zonas'
import { Col, Row } from 'react-bootstrap';
import icones from '../../themes/icones';
import { logout, resetCurrentPolygon, setPolygon } from '../../services/database.service'
import ConfirmBox from "../../components/ConfirmBox";
import {
  setMapOpen,
  getTempPolygonCoords
}
  from '../../services/database.service'

const telas = ['forms', 'menu'];

const useStyle = makeStyles(style);

export default function MapViewer(props) {
  const classes = useStyle();
  const screenFormList = 'formlist';
  const [modalOpen, setModalOpen] = useState(getTempPolygonCoords());
  const [open, setOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState(null);
  const { closeMap } = props;

  useEffect(() => {
    setMapOpen()
  },[])

  const handleClose = () => {
    setOpen(false);
  }

  const map = () => {
    setOpen(false);
  }

  const save = () => {
    setPolygon('sdfsdgsdg');
    closeMap();
    setMapOpen(false)
  }

  const cancel = () => {
    resetCurrentPolygon();
    closeMap();
    setMapOpen(false)
  }

  return (
    <Row className={`${classes.mapViewer} position-absolute w-100 vh-100 p-0 m-0 d-flex flex-column `} >
      <Row className={`${classes.mapViewerHead} d-flex position-relative w-100 p-0 m-0`} >
        <Box className={`${classes.userContainer} h-100 d-flex flex-row-reverse m-0 p-0 position-absolute end-0`} >
          <Box className={`position-absolute w-auto d-flex flex-row-reverse end-0 h-100 p-0`}>
            <Button onClick={() => save()} className={`menu-btn h-100 p-0 `} mode="contained">
              <img
                onClick={() => save()}
                className={`${classes.menuLogo234} h-100 w-25 p-0`}
                src={icones.salvar}
              />
              <label className={`menu-btn d-none d-md-block`}>Salvar</label>
            </Button>
            <Button onClick={() => closeMap()} className={`menu-btn h-100 p-0 `} mode="contained">
              <img
                onClick={() => closeMap()}
                className={`${classes.menuLogo234} h-100 w-25 p-0`}
                src={icones.gps}
              />
              <label className={`menu-btn d-none d-md-block`}>GPS Track</label>
            </Button>
            <Button onClick={() => closeMap()} className={`menu-btn h-100 p-0 `} mode="contained">
              <img
                onClick={() => closeMap()}
                className={`${classes.menuLogo234} h-100 w-25 p-0`}
                src={icones.desenhar}
              />
              <label className={`menu-btn d-none d-md-block`}>Desenhar</label>
            </Button>
            <Button onClick={() => cancel()} className={`menu-btn start-0 h-100 p-0 `} mode="contained">
              <img
                //onClick={() => cancel()}
                className={`${classes.menuLogo234} h-100 w-25 p-0`}
                src={icones.logout}
              />
              <label className={`menu-btn d-none d-md-block`}>Cancelar</label>
            </Button>
          </Box>
        </Box>
      </Row>

      <Row className={`${classes.mapViewerBody} w-100 p-0 m-0 `} >
        <div className={`${classes.mapViewerMap} m-0 p-0`} >
          <GeocadMap
            style={{ zIndex: 1 }}
            id={'map'}
            center={[-8.9192245, 13.1855814]}
            zoom={10}
            polygon={[[51.509, -0.08], [51.503, -0.06], [51.51, -0.047]]}
            shapefile={[]} />
        </div>
      </Row>
    </Row>
  );
};