import api from './api';
import { login } from './mock';
import { databaseService } from './database.service';
const passwordHash = require('password-hash');
const useMock = false;
const userApiService = new api();

export const signIn = async (email, password) => {
  let internetConection = window.navigator?.onLine || false;
  //mock up
  if (useMock) {
    let response = login(email, password);
    if (response) {
      var hashedPassword = passwordHash.generate(password);
      let logged = {
        id: response?.data?.id,
        email: email,
        password: hashedPassword
      }

      let userData = {
        data: logged,
        errors: null,
        forms: await userApiService.getForms(),
        mock: useMock
      }
      databaseService.saveLogged(userData);
    }
    return response;
    //offline login
    //all code here
  } else {
    if (internetConection) {
      let response = await userApiService.auth(email, password);
      if (response.success) {
        databaseService.saveLogged(response.data);
      }

      return response
    }

    return false;
  }
}

export const isLogged = async () => {
  return await databaseService.isLogged();
}

const authService = { signIn, isLogged }

export default authService;