import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ConfirmBox(props) {
  // const [open, setOpen] = React.useState(true);
  const { show = true, title = 'Confirmação', msg = 'To subscribe to this website, please enter your email address here. We will send updates occasionally.', close, handleResult } = props;

  const handleNao = () => {
    if (typeof handleResult == 'function') {
      handleResult(false);
    }
    // setOpen(false);
  };

  const handleSim = () => {
    if (typeof handleResult == 'function') {
      handleResult(true);
    }
    // setOpen(false);
  };

  return (
    <div>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={show}
        onClose={handleNao}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={`cancel-btn`} autoFocus onClick={handleNao}>
            Não
          </Button>
          <Button className={`info-btn`} onClick={handleSim}>Sim</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}