import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";


export default function ComboBox(props) {
  const { list, initialValue, setValue } = props;
  const [currentValue, setCurrentValue] = useState(
    initialValue ? list.find((a) => a.id === initialValue.id) : ""
  );

  const handleChange = (event, newValue) => {
    if (newValue) {
      setValue(newValue);
      setCurrentValue(newValue);
    }
  };

  return (        
      <Autocomplete
        disablePortal
        value={currentValue}
        id="combo-box-demo"
        options={list}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="" />}
      />
  );
}
