import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles'
;
import style from '../../themes/styles/geral';

const useStyle = makeStyles(style);
export default function Toast(props) {
  const classes = useStyle();
  const [showAlert, setShowAlert] = useState(props?.show || false);
  const { type = '', msg = 'Ocorreu uma operação', onClose } = props || {};

  const close = () => {
    onClose(false);
  }

  return (
    <Stack className='fixed-top' sx={{ width: '100%', position: 'absolute', top: 0, zIndex: 900 }} spacing={2}>
      <Alert variant="filled" severity={type || "success"} onClose={close} >{msg}</Alert>
    </Stack>
  );
};
