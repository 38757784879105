import api from "../services/api";
import { toast } from "react-toastify";
import { permissionsMapped } from "../utils/permissionsMapped";
import "react-toastify/dist/ReactToastify.css";
const userApiService = new api();

const notify = () => toast.error("Nao Tens Permissão Para Acessar esta Pagina!");

const handlePageChange = async (fn, page, action, value) => {
  var loggedUser = await userApiService.getUserData();
  action = `${page}_${action}`;
  var pagePerm = permissionsMapped[action];

  var hasPermission = null;
  if (loggedUser?.codigo == 1){
    
    hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
      return pagePerm === item;
    });

  }else{

    hasPermission = loggedUser?.permissions?.filter((item) => {
      return pagePerm === item.nome;
    });
  }

  if (!hasPermission?.length > 0) {
    notify();
    return;
  }

  const customEvent = new CustomEvent("removeBootstrap", { detail: "" });
  document.dispatchEvent(customEvent);

    fn(value);

  // //console.log("handlePageChange", action);
};

export { handlePageChange };
