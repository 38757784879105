import React, {
  useEffect,
  useState,
} from 'react';

import { makeStyles } from '@mui/styles'
  ;
import style from '../../themes/styles/geral';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ReactTooltip from 'react-tooltip';
import EditIcon from '@material-ui/icons/Edit';
import LockOpen from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddEnabled from '@mui/icons-material/PersonAdd';
import PersonAddDisabled from '@mui/icons-material/PersonAddDisabled';
import { handlePageChange } from '../../utils/handlePageChange';
import { ToastContainer, toast } from 'react-toastify';
import { permissionsMapped } from "../../utils/permissionsMapped";
import { databaseService } from '../../services/database.service';

import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useStyle = makeStyles(style);

export default function Actions(props) {
  const [loggedUser, setLoggedUser] = useState(null);
  const classes = useStyle();
  const {

    handleEdit,
    handleDelete,
    handleBlock,
    handleView,
    userBlockHandle,
    handleDisabeEnable,
    handleEnableUser,
    isEnabled,
    disabled,
    usertatus,
    value,
    page
  } = props;

  useEffect(function () {
    ReactTooltip.rebuild();
      async function getUserData () {
        const loggedUser = databaseService.isLogged()
        setLoggedUser(loggedUser);
      }

      getUserData();
  }, [])

  const showHiding = (action) => {
    var pagePerm = permissionsMapped[action];

    // const hasPermission = loggedUser?.allPermissionsMapped?.find((item) => {
    //   return pagePerm === item;
    // });

    var hasPermission = null;
    if (loggedUser?.codigo == 1){

      hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
        return pagePerm === item;
      });

    }else{

      hasPermission = loggedUser?.permissions?.filter((item) => {
        return pagePerm === item.nome;
      });
    }

    return hasPermission  ? true : false;
  };

  const calcActionWidth = () => {
    let initial = 30;
    let btnQtdes = handleEdit ? 1 : 0;
    if (handleDelete) btnQtdes++;
    if (handleBlock) btnQtdes++;
    if (handleView) btnQtdes++;
    if (handleDisabeEnable) btnQtdes++;

    let add = (btnQtdes) * (initial + 10);
    return `${initial + add}px`;
  }


  const handleFormMenus = () => {
    return (
      <Box className={`forms-title-container d-flex flex-row`} >
        <ToastContainer />
        {showHiding(`${page}_view`) ?
        handleView ?<Button onClick={function () { handlePageChange(handleView, page, "view",value) }} className={`${classes.successBtn} `}>
          <VisibilityIcon color='success' data-tip='Visualizar' data-for='visualizar' />
          <ReactTooltip id='visualizar' />
        </Button>
         :null
        :null
        }

        {showHiding(`${page}_edit`) ?
        userBlockHandle
          ? <Button onClick={function () { handlePageChange(userBlockHandle, page, "edit", value) }} className={`${classes.infoBtn} `} >
            <EditIcon color='primary' data-tip='Editar' data-for='editar' />
            <ReactTooltip id='editar' />
          </Button>
           :null
          :null
        }
        {handleBlock ? <Button onClick={function () { handleEdit(value) }} className={`${classes.successBtn} `}  >
          <LockOpen color='primary' />
        </Button> : null}
        {showHiding(`${page}_disable`) ?
        usertatus === 1 ?
        <Button onClick={function () { handlePageChange(handleDisabeEnable, page, "disable", value) }} className={`${isEnabled ? classes.successBtn : classes.removeBtn} position-relative `} >
          <PersonAddDisabled color='error' data-tip='Desativar Usuario' data-for='desativar' />
          <ReactTooltip id='desativar' />
        </Button>
         : null
        : null
      }

        {showHiding(`${page}_enable`) ?
        usertatus === 2 ||usertatus === 0  ?
         <Button onClick={function () { handlePageChange(handleEnableUser, page, "enable",value)  }} className={`${disabled ? classes.successBtn : classes.removeBtn} position-relative `}>
          <PersonAddEnabled color='success' data-tip='Ativar Usuario' data-for='ativar' />
          <ReactTooltip id='ativar' />
        </Button>
          : null
        : null
        }
        <ReactTooltip data-html="true" />
      </Box>)
  }

  return (
    handleFormMenus()
  );

};
