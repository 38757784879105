const capFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
}

const getFormName = (modelo) => {
    return modelo?.toString()
        // .toLowerCase()
        .trim()
        .replace(" ", "")
        .split('.')[0];
}

module.exports = {
    capFirstLetter,
    getFormName
};
