import react from "react"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingBar = ({ handleCloseSpinner, openSpinner}) => {
    return<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
        open={openSpinner}
        onClick={handleCloseSpinner}
    >
        <div className={"d-flex flex-column align-items-center justify-content-center justify-items-center"}                                           >
            <CircularProgress color="inherit" value={25} />
            Carregando...
        </div>
    </Backdrop>
}

export default LoadingBar