import React, { useState, useEffect } from "react";
import api from "../../services/api";
import createErrorMessage from "../../utils/createErrorMessage"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import StringMessage from "../../utils/string-messages";
const userApiService = new api();

function NomearRegisto(props) {
  const [show, setShow] = useState(true);
  const [nomeRegisto, setNomeRegisto] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { hideAlertShow, showMsg, setOpenSpinner } = props;
  
  useEffect(async () => {
    console.error("nao ha nome de registo");
    //console.log(90000);
    let nomeRegistoCong = (await userApiService.getNameRegisto()) ?? [];

    //console.log('nomeRegistoCong',nomeRegistoCong)
    nomeRegistoCong = nomeRegistoCong.map((item) => {
      return {codigo:item.codigo, nameRegisto: item.nome };
    });
  }, []);


  const handleSave = async () => {
    setShow(false)
    setOpenSpinner(true);
    const rowData = {
      nameRegisto: nomeRegisto,
    };

    let result = await userApiService.saveNameRegisto(rowData);
    let formMessage = StringMessage.messageSave;
    if (result?.success) {
      showMsg.success(formMessage);
    } else {
     
      showMsg.error(createErrorMessage(result));
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);

  };
 

  return (
    <>
      {/* <Button  className="bgColorMod" onClick={handleShow}>
        <DriveFileRenameOutlineIcon /> Renomaer Nº Registo{nomeRegisto}
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nome do Registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome do número de registro</Form.Label>
              <Form.Control
                type="text"
                label="nomeRegistro"
                disableUnderline={true}
                value={nomeRegisto}
                placeholder="Renomear o Número de Registro"
                onChange={(e) => setNomeRegisto(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button  className="btnCancelColor" onClick={handleClose}>
            CANCELAR
          </Button>
          <Button className="btnSavelColor"  onClick={handleSave}>
            SALVAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default NomearRegisto