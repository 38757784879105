import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ELASTICSEARCH_HOST;

axios.defaults.baseURL = BASE_URL;

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

const getAllByFormName = async (formName) => {
  //console.log("BASE_URL", BASE_URL);
  try {
    let response = await httpClient.get(`/${formName}/_search`);
    if (response.data) {
      //console.log(response.data);

      return
      // return result;
    }
  } catch (error) {

  }
  return [];
};

export const elasticsearchApiService = {
    getAllByFormName,
    // create,
    // update,
    // remove,
    // removeAll,
    // findByFormName,
};
  