import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { InputLabel } from "@mui/material";

export default function SelectLabels(props) {
  const { initialValue, lista, className, setValue, selected } = props;
  const [currentValue, setCurrentValue] = useState(
    initialValue ? lista?.find((a) => a.codigo === initialValue.codigo) : ""
  );

  React.useEffect(() => {
    setCurrentValue(initialValue ? lista?.find((a) => a.codigo === initialValue.codigo) : "");
  }, [initialValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    setValue(value);
    setCurrentValue(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <FormControl
      sx={{
        //   m: 1,
        //   marginTop: "4px",
        //   marginLeft: 0,
        minWidth: 120,
        //   minHeight: "35px",
      }}
      className={"select-box"}
    >
      <InputLabel id="test-select-label"></InputLabel>
      <Select
        defaultValue=""
        value={currentValue}
        onChange={handleChange}
        displayEmpty
        // MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        style={{ maxHeight: "35px" }}
        variant="outlined"
        label={" "}
      >
        {lista
          ? Array.from(lista).map((item, key) => {
            return (
              <MenuItem key={key} value={item}>
                {item.nome || item.titulo}
              </MenuItem>
            );
          })
          : null}
      </Select>
    </FormControl>
  );
}
