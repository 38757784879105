import * as L from 'leaflet'
import 'leaflet-draw'
import { getStorageInfo } from 'leaflet.offline'

import {
  setTempPolygon,
  getTempPolygonCoords,
  setDrawingPolygon,
  getDrawingPolygon,
  isMarkMissionMode,
  setMission,
  getMission
}
  from '../../services/database.service'

const shapeFileProperties = {}

var LastLat,
  LastLng,
  featureGroup,
  marker,
  circle,
  walkingPoint = []

const province = {
  'Benguela': '#E31A1C',
  'Bengo': '#800026',
  'Bié': '#82E0AA',
  'Cabinda': '#FD8D3C',
  'Cuando Cubango': '#BB8fE',
  'Cuanza Sul': '#F5B041',
  'Cuanza Norte': '#F5B051',
  'Cunene': ' #148F77',
  'Huí­la': ' #784212 ',
  'HuÃ­la': ' #784212 ',
  'Huambo': '#FC4E2A',
  'Luanda': '#BD0026',
  'Lunda Norte': '#F4D03F ',
  'Lunda Sul': '#2874A6',
  'Malanje': ' #7E5109',
  'Moxico': ' #283747',
  'Namibe': '#E6B0AA',
  'Uí­ge': '#FED976',
  'UÃ­ge': '#FED976',
  'Zaire': '#FEB24C'
}

var map = {};
var zoomSteps = [10, 13, 16, 18, 20];
var currentZoomValue = zoomSteps[0];

var polygon_options = {
  showArea: true,
  shapeOptions: {
    stroke: true,
    color: '#6e83f0',
    weight: 4,
    opacity: 0.5,
    fill: true,
    fillColor: null, //same as color by default
    fillOpacity: 0.2,
    clickable: true
  }
}

var polygonDrawer;

function getColor(feature) {
  if (province[feature.properties.NAME_1] || province[feature.properties.NAME_1] === '') {
    return province[feature.properties.NAME_1]
  }
  return '#FFEDA0'
}

function style(feature, styleColor, tam) {
  return {
    fillColor: getColor(feature),
    weight: tam !== undefined ? tam : 2,
    opacity: 1,
    color: styleColor !== undefined ? styleColor : 'white',
    dashArray: '3',
    fillOpacity: 0.7
  }
}

function mouseoverLayer(feature, layer) {
  layer.on('mouseover', function (e) {
    layer.setStyle({
      fillOpacity: 0.4
    })
  })
  layer.on('mouseout', function (e) {
    layer.setStyle(province[feature.properties.NAME_1])
  })
}

export const getFeatureGroup = (marker) => {
  let featureGroup = L.featureGroup([marker]).addTo(map)
  moveToPosition(featureGroup)

  return featureGroup
}

export const setMarker = (params) => {
  let { map, latlng, radius, text = 'Aqui' } = params;
  L.marker(latlng).addTo(map)
    .bindPopup(`${text}`).openPopup()
}

export const setCircle = (params) => {
  let { latlng, radius, map = map } = params;
  L.circle(latlng, radius).addTo(map)
}

export function onLocationFound(e) {
  let radius = e.accuracy

  LastLat = e.latlng.lat
  LastLng = e.latlng.lng

  if (marker) {
    map.removeLayer(marker)
  }
  if (circle) {
    map.removeLayer(circle)
  }

  //setMarker({map, latlng: e.latlng, radius });
  //circle = L.circle(e.latlng, radius).addTo(map)

  featureGroup = getFeatureGroup(marker)

  document.getElementById('stop').addEventListener('click', (e) => {
    map.stopLocate()
    e.preventDefault()

    if (marker) {
      map.removeLayer(marker)
    }
    if (circle) {
      map.removeLayer(circle)
    }
    let polygon = L.polygon(walkingPoint).addTo(map)
    localStorage.setItem('geoJSON', JSON.stringify(polygon.toGeoJSON()))
  })
}

var mapServer = 'https://api.mapbox.com/styles/v1';
window.mapServer = mapServer;

export function loadMap(params) {

  const { mapMode = 'mapbox/satellite-streets-v11', id = window.geocadProps.id } = params;

  //map = L.map(id, {
  map = L.map(id, {
    center: window.geocadProps.center,
    zoom: window.geocadProps.zoom,
    drawControl: true
  })

  let mapUrl = `${mapServer}/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`;
  L.tileLayer.offline(mapUrl, {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: window.geocadProps.maxZoom,
    zoom: window.geocadProps.zoom,
    minZoom: window.geocadProps.minZoom,
    id: mapMode,
    tileSize: 512,
    zoomOffset: -1,
    accessToken: 'pk.eyJ1Ijoid2FsdGVyYW5nb2xhciIsImEiOiJja3JlcjR1bW4wMG02Mm9xbnJoZnA5dDczIn0.EiaiP9Z8IfNfl96y-MryGw'
  }).addTo(map, { style: style })

  //setMarker({map, latlng: window.geocadProps.center, radius: 2708 });

  const polygon = L.polygon(
    window.geocadProps.polygon
  ).addTo(map)

  /*
  map.on('click', function (e) {
    let lat = e.latlng.lat
    let lng = e.latlng.lng

    setDrawingPolygon([map._lastCenter?.lat, map._lastCenter?.lng])

    //document.getElementById('mapCoordDIV').innerHTML = `Coordenadas ${lat.toFixed(5)} : ${lng.toFixed(5)}`
  })*/

  map.on('zoom', (e) => {

    try {
      let newZoom = e.target._zoom;
      if (currentZoomValue < newZoom) {
        handleZoomIn();
      } else {
        handleZoomOut();
      }
      currentZoomValue = newZoom;
      return false;
    } catch (error) {
    }
  })

  map.on('locationfound', (e) => {

    try {
      let { lat, lng } = e.latlng

      if (lat !== LastLat && lng !== LastLng) {
        onLocationFound(e)
      } else {
        moveToPosition(featureGroup)
      }
    } catch (error) {

    }
  })

  map.on(L.Draw.Event.CREATED, function (e) {
    try {
      let points = e.layer?._latlngs[0] || [];
      let newArray = [];
      for (let p of points) {
        let _point_to_save = [p.lat, p.lng];
        newArray.push(_point_to_save);
      }

      let area = 0;
      for (let island of e.layer.getLatLngs()) {
        // If the polygon is non-contiguous, access the island
        if (island.length < 2) {
          island = island[0]
        }
        // Sum the area within each "island"
        area += L.GeometryUtil.geodesicArea(island);
      }
      if (isMarkMissionMode()) {
        setMission({ coords: newArray, area: area })
        showPolygon({ color: 'orange' })
      } else {
        setTempPolygon({ coords: newArray, area: area });
        showPolygon()
      }
    } catch (error) {

    }
  });

  showPolygon();

  map.locate({
    setView: true,
    maxZoom: window.geocadProps.maxZoom,
    watch: true,
    enableHighAccuracy: true
  })

  setZoomEvents();

  return map
}

var movQtde = 0;

export function showPolygon(params = {}) {
  try {
    let { color = 'red' } = params;
    var coords = getTempPolygonCoords();
    if (coords?.length > 0) {
      for (let x of coords) {
        let polygon2 = L.polygon(x, { color: color }).addTo(map);
        map.fitBounds(polygon2?.getBounds());
      }
    }

    var __coords = getDrawingPolygon();
    if (__coords?.length > 0) {
      let __polygon = L.polygon(__coords, { color: 'blue' }).addTo(map);
      map.fitBounds(__polygon?.getBounds());
    }

    var m__coords = getMission()?.coords;
    if (m__coords?.length > 0) {
      let m__polygon = L.polygon(m__coords, { color: 'orange' }).addTo(map);
      map.fitBounds(m__polygon.getBounds());
      //map.locate({setView: true, maxZoom: window.geocadProps.maxZoom});

      let center = m__polygon.getBounds().getCenter();
      //map.panTo(center);
      map.flyTo(center, 14);
      setMarker({ map, latlng: center, radius: 1048, text: 'Local da missão' });
    }
  } catch (error) {
    console.log('error', error)
  }
}

var gpsTracking = null;
export function handleGPSTracking(fx) {
  try {
    let dados = fx();
    gpsTracking = setInterval(function () {
      const { latitude, longitude } = dados;
      setDrawingPolygon([latitude, longitude]);
      showPolygon();
    }, 2000)
  } catch (error) {
    console.log('error', error)
  }
}

export function stopGPSTracking() {
  try {
    clearInterval(gpsTracking);
  } catch (error) {
    console.log('error', error)
  }
}

var zoomInCommand, zoomOutCommand;

export function setZoomEvents() {
  try {
    let timer = setInterval(function () {

      if (document.getElementsByClassName('leaflet-control-zoom-in')) {

        zoomInCommand = document.getElementsByClassName('leaflet-control-zoom-in')[0];
        zoomOutCommand = document.getElementsByClassName('leaflet-control-zoom-out')[0];

        if (zoomInCommand) {
          zoomInCommand.addEventListener('click', () => {
            document.getElementsByClassName('leaflet-control-zoom-in')[0].classList.remove('leaflet-disabled')
            document.getElementsByClassName('leaflet-control-zoom-out')[0].classList.remove('leaflet-disabled')
            handleZoomIn();
          })
          zoomInCommand.classList.remove('leaflet-disabled')
        }

        if (zoomOutCommand) {
          zoomOutCommand.addEventListener('click', () => {
            document.getElementsByClassName('leaflet-control-zoom-in')[0].classList.remove('leaflet-disabled')
            document.getElementsByClassName('leaflet-control-zoom-out')[0].classList.remove('leaflet-disabled')
            handleZoomOut();
          })
          zoomOutCommand.classList.remove('leaflet-disabled')
        }

        clearInterval(timer);
      }
    }, 3000)
  } catch (error) {
    console.log('error', error)
  }
}

export function handleZoomIn() {
  try {
    let zoom = nextZoomStep();
    if (zoom === map._layersMaxZoom) return;
    map._layersMaxZoom = zoom;
    map._layersMinZoom = zoom;
    map.setView(Object.values(map._lastCenter), zoom);
  } catch (error) {
    console.log('error', error)
  }
}

export function handleZoomOut() {
  try {
    let zoom = previousZoomStep();
    if (zoom === map._layersMinZoom) return;
    map._layersMaxZoom = zoom;
    map._layersMinZoom = zoom;
    map.setView(Object.values(map._lastCenter), zoom);
  } catch (error) {
    console.log('error', error)
  }
}

export function nextZoomStep() {
  try {
    for (let i = 0; i < zoomSteps.length; i++) {
      if (currentZoomValue < zoomSteps[i]) {
        return zoomSteps[i];
      }
    }
  } catch (error) {
    console.log('error', error)
  }
  return currentZoomValue;
}

export function previousZoomStep() {
  try {
    for (let i = zoomSteps.length - 1; i >= 0; i--) {
      if (currentZoomValue > zoomSteps[i]) {
        return zoomSteps[i];
      }
    }
  } catch (error) {
    console.log('error', error)
  }
  return currentZoomValue;
}

let count = 0
let layerGlobal = {}

function loadShapefile(shapefile, styleColor, tam, mapMode) {
  map.eachLayer(function (layer) {
    map.removeLayer(layer)
  })
  loadMap(mapMode)
  document.getElementById('load').style.display = 'block'
  const shpfile = new L.Shapefile(`assets/shapefiles/${shapefile}.zip`, {
    onEachFeature: function (feature, layer) {
      if (feature.properties && layer) {
        layer.setStyle(style(feature, styleColor, tam))
        layer.bindPopup(Object.keys(feature.properties).map(function (k) {
          shapeFileProperties[`${++count}`] = feature.properties
          return k + ": " + feature.properties[k];
        }).join("<br />"), {
          maxHeight: 200
        });
      }
    }
  });
  shpfile.addTo(map);
  shpfile.once("data:loaded", function () {
    document.getElementById('load').style.display = 'none'
  })
  return shpfile
}

//loadMap('mapbox/satellite-streets-v11')
/*
document.getElementById('country').addEventListener('click',  (e) => {
  loadShapefile('shp_angola', undefined, undefined, 'mapbox/satellite-streets-v11')
})

document.getElementById('roads').addEventListener('click', (e) => {
  loadShapefile('angola_roads', '#F1C40F', 4, 'mapbox/streets-v11')
})

document.getElementById('electricity').addEventListener('click', () => {
  loadShapefile('electricity-transmission-and-distribution', ' #5b2c6f ', 4, 'mapbox/streets-v11')
})*/

function spinner(status) {
  return document.getElementById('load').style.display = status
}

export const moveToPosition = (featureGroup) => {
  let bounds = featureGroup.getBounds()
  map.fitBounds(bounds, { maxZoom: window.geocadProps.maxZoom })

  if (bounds) {
    // Initialize the FeatureGroup to store editable layers
    polygonDrawer = new L.Draw.Polygon(map, polygon_options);
    polygonDrawer.enable()
    let layerClick = document.querySelector('.leaflet-draw-tooltip-single')
    // document.getElementById('testTarget')
    mouseClick(layerClick)

    // walkingPoint.push([polygonDrawer._currentLatLng.lat, polygonDrawer._currentLatLng.lng])
    // polygonDrawer.drawstart()
  }
}

export let mouseClick = (elem) => {
  // let event = new MouseEvent('click', {
  //   bubbles: true,
  //   cancelable: true,
  //   clientX: 20,
  //   view: elem.ownerDocument.defaultView
  // })
  // event.preventDefault()

  polygonDrawer = new L.Draw.Polygon(map, polygon_options)
  polygonDrawer.enable()

  let setTime = setTimeout(() => {

    let btn = document.createElement('buttom')
    btn.onclick = () => {
      //alert('clicou-me!')
    }
    btn.click()
    clearTimeout(setTime)
  }, 3000)
  // let camceled = !elem.dispatchEvent(event)
}