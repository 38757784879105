import React, { useState, useEffect } from "react";
import createErrorMessage from "../../utils/createErrorMessage"
import api from "../../services/api";
import { formBuilderApiService } from "../../services/form_builder_api";
import Tabela from "../../components/Tabela";
import Myframe from "../Myframe";
import { Col, Row } from "react-bootstrap";
import { MDBBox } from "mdbreact";
import DATATABLE from "../../components/DataTable/dataTable";
import disable from "../../utils/disableInputs";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Actions from "../../components/Actions";
import fundo_base_vertical from "../../assets/images/fundo_base-vertical.png";
import s_image from "../../assets/images/s30.png";
import Input from "@material-ui/core/Input";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { capFirstLetter } from "../../utils/string";
import validator from "../../services/validator.service";
import ConfirmBox from "../../components/ConfirmBox";
import StringMessage from "../../utils/string-messages";
import AutocompleteSelect from "../../components/AucompleteSelect/AucompleteSelect";
import { holdManyFiles } from "../../utils/html";
import AddButton from "../../components/AddButton";
import { removeFormBuilderCss } from "../../utils/events";
import { handlePageChange } from '../../utils/handlePageChange';

const userApiService = new api();
const validation = new validator();

const columns = [
  {
    label: "#",
    field: "num",
    sort: "asc",
    width: 10,
  },
  {
    label: "Formulários",
    field: "nome",
    sort: "asc",
    width: 300,
  },
  {
    label: "Modelo",
    field: "modelo",
    sort: "asc",
    width: 300,
  },
  {
    label: "Estado",
    field: "status",
    sort: "asc",
    width: 200,
  },
  {
    label: "Acções",
    field: "action",
    sort: "asc",
    width: 400,
    type: "object"
  },
];


export default function Formularios(props) {
  const [formPreview, setFormPreview] = useState(false);
  const [currentForm, setCurrentForm] = useState([]);
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(StringMessage.noPreview);
  const [tableData, setTableData] = useState({});
  const [iframeTitle, setIframeTitle] = useState("Formulário");
  const [open, setOpen] = useState(true);
  const [openForm, setOpenForm] = useState(0);
  const [formsList, setFormsList] = useState();
  const [codigo, setCodigo] = useState("");
  const [nome, setNome] = useState("");
  const [observacao, setObservacao] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [modeloFormulario, setModeloFormulario] = useState("");
  const [listaModelosFormularios, setListaModellistaModelosFormularios] = useState([]);
  const [formSubmit, setFormSubmit] = useState();
  const [isWizard, setIsWizard] = useState();
  const [wizardSize, setWizardSize] = useState(1);
  const [formPage, setFormPage] = useState(1);
  const [wizardNext, setWizardNext] = useState(null);
  const [wizardPrev, setWizardPrev] = useState(null);
  const [formItemToFill, setFormItemToFill] = useState(null);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  let internetConection = window.navigator?.onLine || false;
  const [back, setBack] = useState("")
  const [cancel, setCancel] = useState("")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    let modeloFormulariosList = await userApiService.getFormBuilderForms();

    permissionHandle(modeloFormulariosList, 'Formularios')

    if (Array.isArray(modeloFormulariosList)) {
      modeloFormulariosList = modeloFormulariosList.map((item) => {
        return { id: item.name, label: item.name };
      });
    } else {
      modeloFormulariosList = []
    }

    setListaModellistaModelosFormularios(modeloFormulariosList);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, []);

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const handleShowHtmlForm = async (obj) => {
    //console.log("handleShowHtmlForm", obj);
    const form = obj;
    setModeloFormulario(obj);
    let resp = await userApiService.getFormBuilderFormByName(form?.id);
    holdManyFiles({ files: resp.js, src: true, type: "script" });
    holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet' });
    holdManyFiles({ files: resp.img, src: true, type: "img" });
    let div = document.createElement("div");
    div.innerHTML = resp?.html;
    div.style.margin = "5px";
    let btn = div.querySelector('button[type="submit"]');

    if (!btn) {
      btn = div.querySelector('button[onclick="formSubmit()"]');
    }

    if (!btn) {
      btn = div.querySelector('button[onclick="formSumit()"]');
    }

    // //console.log("SUBMIT BUTTON", btn);

    let inputs = div.querySelectorAll("input");
    let inputSelect = div.querySelectorAll("select");
    let inputTextArea = div.querySelectorAll("textarea");
    disable(inputs);
    disable(inputSelect);
    disable(inputTextArea);

    let formNewLine = div.querySelectorAll(".formNewLine");
    for (const key in formNewLine) {
      if (Object.hasOwnProperty.call(formNewLine, key)) {
        const element = formNewLine[key];
        element.style.display = "block";
      }
    }

    let wizard = div.querySelectorAll(".formWizardPage")?.length;

    if (wizard > 0) {
      setIsWizard(wizard);
      let btnNext = div.querySelector("#btn-form-next");
      let btnPrev = div.querySelector("#btn-form-prev");

      setWizardNext(btnNext);
      setWizardPrev(btnPrev);
      setWizardSize(wizard);

      // //console.log("oha aí o wizard", btnNext, btnPrev);
      // //console.log("oha aí o wizard counter", wizard);

      if (btnPrev) btnPrev.style.display = "none";
      if (btnNext) btnNext.style.display = "none";
    }

    setFormSubmit(btn);
    if (btn) btn.style.display = "none";
    let html = div.innerHTML;
    setIframeUrl(html);
    // setOpenForm(4);
    hideSpinner();
  };

  const handleShow = async (form) => {
    setData(form);
    setCurrentForm(form);
    setOpenForm(2);
    setBack("Voltar")
  };

  const setData = (form = null) => {
    var modeloFormulario = { id: form?.modelo || "", label: form?.nome || "" };
    handleShowHtmlForm(modeloFormulario);
    setCodigo(form?.codigo);
    setNome(form?.nome);
    setModeloFormulario(modeloFormulario);
    setObservacao(form?.observacao);
  };

  const handleAdd = () => {
    setData();
    setEditButtonVisible("none");
    setSaveButtonVisible("flex");
    setCancel("Cancelar")
    setOpenForm(1);
  };

  const handleEdit = (form) => {
    setData(form);
    setSaveButtonVisible("none");
    setEditButtonVisible("flex");
    setCancel("Cancelar")
    setOpenForm(3);
  };
  const handleDelete = (obj) => {
    setCodigo(obj?.codigo);
    setShowConfirm(true);
  };

  const handleCancel = () => {
    setOpenForm(0);
    setIframeUrl(StringMessage.noPreview);
    const customEvent = new CustomEvent("removeBootstrap", { detail: "" })
    document.dispatchEvent(customEvent)
  };

  const showConfirmBox = () => {
    var nome = "formulario?";
    return showConfirm ? (
      <ConfirmBox
        msg={StringMessage.message + ` ${nome}`}
        handleResult={deleteData}
      />
    ) : null;
  };

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteForms(codigo);
      if (result) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessage.messageRemove);
      } else {
        showMsg.error(
          result?.exception?.msg
        );
      }
    }
    setShowConfirm(false);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
  };

  const loadTable = async () => {
    const forms = ((await userApiService.getForms()) ?? []).reverse();
    const list = forms.filter((form) => form.tipo === "formulario" || !form.tipo)
    setFormsList(list);
    setTableData(list);
    let rows = [];
    let count = 1;
    if (list) {
      Object.values(list).map((item) => {
        rows.push({
          id: item?.codigo,
          num: item?.codigo,
          // nome: item.name.toString().split('.')[0],
          nome: item?.nome?.toString().split(".")[0],
          status: item.status || "Activo",
          modelo: item?.modelo.slice(0, -5),
          observacao: item?.observacao,
          action: (
            <Actions
              page='formlist'
              value={item}
              handleView={handleShow}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ),
        });
      });

      setList({ columns: columns, rows: rows });
      setOpenSpinner(false)
    }
  };

  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  hideSpinner();

  const saveForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      modelo: modeloFormulario.id,
      observacao: observacao,
      tipo: "formulario",
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        {
          value: modeloFormulario.id,
          label: "Modelo de Formulário",
          type: "required",
        },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveForm(rowData);
    let formMessage = StringMessage.messageSave;
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      showMsg.success(formMessage);
    } else {
      showMsg.error(createErrorMessage(result));
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);

    removeFormBuilderCss()
  };

  const editHandleView = (msg) => {
    //console.log('page ', msg);
    setOpenForm(3);
  }

  const updateForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      nome: nome,
      modelo: modeloFormulario.id,
      observacao: observacao,
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        {
          value: modeloFormulario.id,
          label: "Modelo de Formulário",
          type: "required",
        },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateForm(rowData);
    let message = StringMessage.messageEditar;
    let formMessage = StringMessage.messageErrorSave;
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      showMsg.success(message);
    } else {
      showMsg.error(formMessage);
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };
  const handleButtons = (saveButton = false) => {
    return (
      <>
        <Box className={`voltar-button-container w-100 fixed-bottom`}>
          <Button
            onClick={() => handleCancel}
            className={`cancel-btn`}
          >
            {cancel}
          </Button>
          {saveButton ? (
            <Button onClick={saveForm} className={`save-btn`}>
              Salvar
            </Button>
          ) : null}
        </Box>
      </>
    );
  };

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      default:
        break;
    }
  };

  const handleFormMenus = () => {
    return (
      <Box
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
      >
        <img
          className={`menu-grafismo-vertical d-none`}
          src={fundo_base_vertical}
        />
        <img className={`menu-s-vertical d-none`} src={s_image} />

        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>FORMULÁRIOS</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='formlist'
              handleAdd={handleAdd}
              dataTip={'Adicionar Formulario'}
              dataFor={'adicionar'}
              addText={'Adicionar'} />
          </div>
        </Box>

        <>
          <Box className={`listagem-tabela flex-fill d-12`}>
            <DATATABLE
              className={`form-list flex-fill d-12`}
              data={list}
            >
              <MDBBox alignItems="center" />
            </DATATABLE>
          </Box>
        </>
      </Box>
    );
  };

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`}>
        <Box className={`listagem-headerr d-flex flex-row pl-0`}>
          <Box className={`listagem-header `}>
            <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={`p-0`}  >
            {/* <Button onClick={() => setOpenForm(3)} className={`right-btn m-1`} >
              Editar
            </Button> */}
            <div className="allbtns">
              <Button
                onClick={handleCancel}
                className={`cancel-btn right-1 m-1`}
              >
                {back}
              </Button>
            </div>
          </Box>
        </Box>
        <Box
          className={`listagem-tabela`}
        >
          <Box
            className={`d-flex flex-column p-5 pb-3`}
          >
            <Row sm={12} className={`d-flex flex-row d-6 `}>
              <Col sm={4} className={`d-flex flex-column d-6 `}>
                <FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Nome</strong>
                  </FormLabel>
                  <FormLabel>{capFirstLetter(nome)}</FormLabel>
                </FormControl>
              </Col>
              <Col sm={4} className={`d-flex flex-column d-6 `}>
                <FormControl component="fieldset" className="mb-2">
                  <FormLabel component="legend">
                    <strong>Modelo</strong>
                  </FormLabel>
                  <FormLabel>{modeloFormulario?.codigo}</FormLabel>
                </FormControl>
              </Col>
            </Row>
          </Box>
          <Box className={`d-flex flex-column p-5 pt-0`}>
            <div id="stringpreview">
              <strong>PRÉ-VISUALIZAÇÃO DO</strong>&nbsp;FORMULÁRIO
            </div>
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
        </Box>
      </Row>
    );
  };

  const editForm = () => {
    const autoCompleteProps = {
      list: listaModelosFormularios,
      initialValue: modeloFormulario,
      setValue: handleShowHtmlForm,
      className: `select-labels`,
    };

    return (
      <Row className={`listagem  position-relative .container-fluid w-100 p-3 m-0`}>
        <Box className={`listagem-headerr d-flex flex-row pl-0`}>
          <Box className={`listagem-header `}>
            <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>
            &nbsp;FORMULÁRIO
          </Box>
          <div className="allbtns">
            <Button onClick={handleCancel} className={`cancel-btn m-1`}>
              {cancel}
            </Button>
            <Button
              onClick={saveForm}
              className={`right-btn m-1`}
              style={{ display: saveButtonVisible }}
            >
              Salvar
            </Button>
            <Button
              onClick={updateForm}
              className={`right-btn m-1`}
              style={{ display: editButtonVisible }}
            >
              Actualizar
            </Button>
          </div>
        </Box>
        <Box className={`listagem-tabela`}>
          <Box
            className={`d-flex flex-column h-0 p-5 pb-0`}
          >
            <Row className={`d-flex flex-row d-6 `}>
              <Col sm={6}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Nome:</label>
                <Input
                  type="select"
                  label="descricao"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col
                sm={6}
                className={`d-flex flex-column  justify-content-start large-font input-container-2`}
              >
                <label className={`input-label-23`}>
                  Modelo do Formulário:
                </label>
                <AutocompleteSelect {...autoCompleteProps} />
              </Col>
            </Row>
            <Row sm={12} className={`d-flex flex-column`}>
              <Col sm={12}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 `}
              >
                <label className={`large-font`}>Observação:</label>
                <textarea
                  value={observacao}
                  className={`user-img`}
                  onChange={(e) => {
                    setObservacao(e.target.value);
                  }}
                  rows="6"
                />
              </Col>
            </Row>
          </Box>
          <Box
            className={`d-flex flex-column pt-0 p-5`}
            style={{ paddingTop: "0px" }}
          >
            <Row>
              <Col sm={12}
                className={`d-flex flex-column justify-content-start large-font input-container-2 pb-100`}
              >
                <div id="stringpreview">
                  <strong>PRÉ-VISUALIZAÇÃO DO</strong>&nbsp;FORMULÁRIO
                </div>
                <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
              </Col>
            </Row>
          </Box>
        </Box>
      </Row>
    );
  };

  return start();
}
