import React, {
  useState,
  useEffect,
} from 'react';

import api from '../services/api'
import { makeStyles } from '@mui/styles';
import style from '../themes/styles/geral';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { MDBDataTable } from 'mdbreact';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockOpen from '@material-ui/icons/LockOpen';
import Lock from '@material-ui/icons/Lock';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useStyle = makeStyles(style);

export default function Utilizadores(props) {
  const classes = useStyle();
  const [list, setList] = useState([]);
  const [openForm, setOpenForm] = useState();
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [provincia, setProvincia] = useState({ nome: 'Luanda' });
  const [municipio, setMunicipio] = useState({ nome: 'Viana' });
  const [contacto, setContacto] = useState();
  const [departamento, setDepartamento] = useState(1);
  const [entidade, setEntidade] = useState();
  const [role, setRole] = useState();
  const [dados, setDados] = useState();
  const [btnQtdes, setBtnQtdes] = useState(3);
  const { actions, colunas, linhas, data } = props;

  useEffect(function () {
    let columns = [...colunas, {
      label: '',
      field: 'action',
      sort: 'asc'
    }]
    
    if (linhas) {
      Array.from(linhas).map(row => {
        row.action = 'Acção';
      });
      setDados({ columns: columns, rows: linhas });
    }
  }, [])

  const calcActionWidth = () => {
    let initial = 14;
    let add = (btnQtdes - 1) * (14 + 10);
    return `${initial + add}px`;
  }

  const start = () => {
    return handleFormMenus();
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem-tabela flex-fill d-12`}  >
        <MDBDataTable
          className={`form-list flex-fill d-12`}
          striped
          bordered
          small
          data={data}
        />
      </Box>)
  }

  return (
    start()
  );

};