
import colors from "../colors";
const boxShadow = 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset';

export default {
  container: {
    backgroundColor: colors.backgroundColor,
    margin: 0
  },
  containerM: {
    margin: 0
  },
  containerMenu: {
    top: '6%',
    height: '94%',
    zIndex: 90
  },
  menuHeader: {
    height: '6%',
  },
  box: {
    margin: '10%',
    padding: 50,
    backgroundColor: colors.backgroundColor,
    left: '25%',
    top: '25%',
    boxShadow: boxShadow,
  },
  topMenuLogo: {
    paddingTop: '10px',
    paddingBottom: '10px',
    // paddingRight: '10px',
  },
  topMenuLogoInvert: {
    filter: `brightness(0) invert(1)`,
  },
  menuContainer: {
    zIndex: 19,
    backgroundColor: colors.backgroundColor,
    bottom: 0,
    paddingTop: 30,
    overflow: 'hidden',
  },
  rightBtnAll: {

  },
  formListButton: {
    backgroundColor: colors.white,
  },
  formListText: {
    alignItems: 'left',
    fontWeight: 'bold',
    fontSize: 12,
    margin: 0,
    padding: 10,
    borderTop: `1px solid ${colors.darkGray}`,
    borderBottom: `1px solid ${colors.darkGray}`,
  },
  error: {
    backgroundColor: 'red',
  },
  mapViewer: {
    zIndex: 9000,
    backgroundColor: colors.backgroundColor,
  },
  mapViewerHead: {
    height: '4%',
    boxShadow: boxShadow,
  },
  mapViewerBody: {
    height: '96%',
    overflow: 'hidden'
  },
  mapViewerMap: {
    height: '100vh',
  },
  userContainer: {
    width: '65%'
  },
  loginContainer: {
    zIndex: 1
  },
  backButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    height: '7%',
    borderRadius: 15,
  },
  pictureModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FFFFFF',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
};
