import axios from 'axios';
import { databaseService } from './database.service';

const BASE_URL = process.env.REACT_APP_FORM_BUILDER_HOST;
const BASE_FRONTEND_URL = process.env.REACT_APP_FORM_BUILDER_FRONTEND_HOST;

axios.defaults.baseURL = BASE_URL;

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

const getDeleteForm = async (form) => {
  try {
    let result = await httpClient.post(`/delete-form`, { nome: form });

    //console.log("estou result", result.data);
    // let result = [];
    return result.data
  } catch (error) {

  }
}

const getAll = async () => {
  try {
    let response = await httpClient.get('/form-list');
    if (response.data) {
      let result = [];
      for (let index in response.data.files) {
        var content = null;//await getForm(response.data.files[index]);
        result.push({ name: response.data.files[index], content: content });
      }
      return result;
    }
  } catch (error) {

  }
  return [];
};


const getAllSorted = async () => {
  try {
    let response = await httpClient.get('/form-list-order-by-date');
    if (response.data) {
      let result = [];
      for (let index in response.data.files) {
        result.push({ name: response.data.files[index], content: false });
      }
      return result;
    }
  } catch (error) {

  }
  return [];
};

const getForm = async (nome) => {
  try {
    let response = await httpClient.get(`/get-form/${nome}`);
    console.warn('===============getForm===================')
    console.warn(response)
    if (response.data) {
      response.data.baseURL = BASE_URL;
      return response.data;
    }
  } catch (error) {

  }
  return [];
};

const getPreview = async (nome) => {
  try {
    let response = await httpClient.get(`/get-form/${nome}`);
    if (response.data) {
      response.data.baseURL = BASE_URL;
      return response.data;
    }
  } catch (error) {

  }
  return [];
};

const getFullAdress = (name) => {
  const fullAddress = `${BASE_FRONTEND_URL}/get-form/${name}`
  return fullAddress;
};

const getAssetFullAdress = (name) => {
  const fullAddress = `${BASE_FRONTEND_URL}${name}`
  return fullAddress;
};

const getAdminData = (data) => {
  return httpClient.post('/get-admin-data', data);
};


const create = (data) => {
  return httpClient.post('/', data);
};

const update = (id, data) => {
  return httpClient.put(`/${id}`, data);
};

const remove = (id) => {
  return httpClient.delete(`/${id}`);
};

const removeAll = () => {
  return httpClient.delete('/');
};

const findByTitle = (title) => {
  return httpClient.get(`/?title=${title}`);
};

const synchronize_old = async () => {
  let formData = databaseService.getFormData();
  let resp = await httpClient.post('/saveMany', formData);
  if (resp?.data?.result) {
    databaseService.resetFormData();
    return true;
  }
  return false;
};

const synchronize = async (formName, formData) => {
  // let formData = databaseService.getFormData();
  let resp = await httpClient.post(`/save/${formName}`, formData);
  if (resp?.data?.result) {
    databaseService.resetFormData();
    return true;
  }
  return false;
};

export const formBuilderApiService = {
  getAll,
  getFullAdress,
  getAssetFullAdress,
  getAdminData,
  getForm,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  synchronize,
  getAllSorted,
  getDeleteForm
};
