const formatTime = (date) => {
    var d = new Date(date)
    var hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
    var minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
    var second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
    return [hour, minute, second].join(':')
}
  
const formatDate = (date) => {
    var d = new Date(date)
    var day = '' + d.getDate()
    var month = '' + (d.getMonth() + 1)
    var year = d.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [day, month, year].join("-");
}
const formatDateReverse = (date) => {
    var d = new Date(date)
    var day = '' + d.getDate()
    var month = '' + (d.getMonth() + 1)
    var year = d.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [year,month,day].join("-");
}
const formatDateDocument = (date) => {
    const monthNames = ["","JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO",
  "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"
];
    var d = new Date(date)
    var day = '' + d.getDate()
    var month = '' + (d.getMonth() + 1)
    var year = d.getFullYear()
    var mes = (monthNames[month])
    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [day,'', 'DE','',mes,'DE', year].join(" ");
}
const formatDateInput = (date)=>{
var day = ("0" + date.getDate()).slice(-2);
var month = ("0" + (date.getMonth() + 1)).slice(-2);
return date.getFullYear() + "-" + (month) + "-" + (day);
}

const formatDateHHMMSS = (date) => {
    var d = new Date(date)
    var day = '' + d.getDate()
    var month = '' + (d.getMonth() + 1)
    var year = d.getFullYear()
    var hours = d.getHours()
    var minutes = d.getMinutes()
    var seconds = d.getSeconds()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }
    if (hours.length < 2) { hours = '0' + hours }
    if (minutes.length < 2) { minutes = '0' + minutes }
    if (seconds.length < 2) { seconds = '0' + seconds }

    return [day, month, year, hours, minutes, seconds].join("-");
}

/*const timeIsBetween = (start, end, check) => {
    return (start.hour <= end.hour)
        ? check.isBiggerThan(start) &&
        !check.isBiggerThan(end)
        : (check.isBiggerThan(start) &&
        check.isBiggerThan(end)) ||
        (!check.isBiggerThan(start) &&
        !check.isBiggerThan(end))
}

const timeLessThan = (start, end, check) => {
    return !check.isBiggerThan(start) && !check.isBiggerThan(end)
}

const timeGreaterThan = (start, check) => {
    return check.isBiggerThan(start)
}

const getTodaysDate = () => {
    return formatDate(new Date());
}*/

  
module.exports = {
    formatDate,
    formatDateHHMMSS,
    formatTime,
    formatDateInput,
    formatDateDocument,
    formatDateReverse
};
  