import React, { useState ,useEffect} from "react";
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Col, Row } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { formatDateInput } from "../../utils/formatDate";
import ReactTooltip from 'react-tooltip';
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 650,
    background: '#dae4e6',
    padding: (2, 4, 3),
  },
}));

export default function EndInquiryModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);
  const {
    cancel,
    endStart,
    openForm,
    setDataFim,
  } = props;
  useEffect(function () {
    ReactTooltip.rebuild();
  }, [])
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Row
            className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
          >
            <Box className={`listagem-headerr d-flex flex-row p-25`}>
              <Box className={`listagem-header`}>
                <strong>{`${openForm === 1 ? "CADASTRAR" : "  Terminar"}`}</strong>
                &nbsp;INQUÉRITO
              </Box>
              <div className="allbtns">
              <Button onClick={endStart} className={`right-btn m-1`} >
                  Terminar
                </Button>
                <Button
                  onClick={cancel}
                  className={`cancel-btn m-1`}
                >
                  cancel
                </Button>
              </div>
            </Box>
            <Box
              className={`listagem-tabela d-flex flex-column h-100 p-1`}
            >
              <Col sm={12} className={`d-flex flex-column d-6 p-3`}>
                <Col sm={12} className={`d-flex flex-row d-6 p-3`}>

                  <Col
                    sm={8}
                   className={`d-flex flex-row d-6 p-3`}
                  >
                     <FormControl >
                    <label >Data de término</label>
                    <Input
                      disabled={true}
                      value={formatDateInput(new Date())}
                      type="date"
                      label="Data de término"
                      disableUnderline={true}
                      autoCapitalize={"none"}
                      className={`input-2 large-font`}
                    /></FormControl>
                  </Col>
                </Col>
              </Col>
            </Box>
          </Row>
        </div>
      </Modal>
    </div>
  );
}