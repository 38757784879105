import React from "react";
import { BootstrapDialogTitle } from "./bootstrapDialogTitle";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ImagePreview from "./imagePreview";
import Camera from 'react-html5-camera-photo';
import Button from "@material-ui/core/Button";

export const BootstrapDialog = (props) => {
    const { 
        dataUri,
        openPictureModal, 
        handleClosePictureModal, 
        handleClosePictureModalReset,
        handleTakePhotoAnimationDone,
        handleCameraError,
        isFullscreen, 
        handleSendToServer,
        handleRepeatPicture,
        showCameraItem
    } = props
    return (<Dialog
        open={openPictureModal}
        onClose={handleClosePictureModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ m: 0, p: 2 }}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClosePictureModalReset}>
            Tirar foto
        </BootstrapDialogTitle>
        <DialogContent dividers>
            {
                showCameraItem  && (
                    dataUri
                    ? <ImagePreview dataUri={dataUri}
                        isFullscreen={isFullscreen}
                    />
                    : <Camera 
                        // idealResolution = {{width: 640, height: 480}}
                        // onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                        onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                        isFullscreen={isFullscreen}
                        onCameraError = { (error) => { handleCameraError(error); } }
                    />
                )
            }
        </DialogContent>
        <DialogActions>
            <Button autoFocus 
                onClick={handleRepeatPicture} 
                className={`cancel-btn m-2`} >
                Repetir
            </Button>
            <Button autoFocus 
                onClick={handleClosePictureModal} 
                className={`right-btn m-2`} >
                Selecionar
            </Button>
            {/* <Button autoFocus 
                onClick={handleSendToServer} 
                className={`right-btn m-2`} >
                Carregar foto
            </Button> */}
        </DialogActions>
    </Dialog>)
}