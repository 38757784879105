import React, {
  useState,
  useEffect,
} from 'react';

import api from '../../services/api'
import createErrorMessage from "../../utils/createErrorMessage"
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Actions from '../../components/Actions';
import validator from '../../services/validator.service'
import ConfirmBox from '../../components/ConfirmBox'
import { Col, Row } from 'react-bootstrap';
import DATATABLE from "../../components/DataTable/dataTable";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { capFirstLetter } from '../../utils/string';
import { groupBy } from '../../utils/group';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import SecurityIcon from '@mui/icons-material/Security';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import strings from '../../assets/lang/strings';
import AddButton from '../../components/AddButton';
import StringMessage from '../../utils/string-messages'
import DualBoxList from '../../components/DualBoxList';
import { handlePageChange } from '../../utils/handlePageChange';
const validation = new validator();
const userApiService = new api();

const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Perfis',
    field: 'descricao',
    sort: 'asc',
    width: 300

  },
  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 300
  }
];

export default function Perfil(props) {
  const [openForm, setOpenForm] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [titulo, setTitulo] = useState('');
  const [perfil, setPerfil] = useState('');
  const [permissoes, setPermissoes] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState();
  const [optionsPermissions, setListPermissions] = useState();
  const [back, setBack] = useState("")
  const [cancel, setCancel] = useState("")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [list, setList] = useState([]);

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();

    var listPermissions = await userApiService.getPermissions() || [];

    permissionHandle(listPermissions, 'Permissões')

    if (Array.isArray(listPermissions)) {
      listPermissions = listPermissions.map((item) => {
        var type = item.nome.split('_');
        if (type.length > 3) {
          item.type = `${item.nome.split('_')[2]}_${item.nome.split('_')[3]}`;
        } else {
          item.type = item.nome.split('_')[2];
        }
        return item;
      })

      listPermissions = Object.entries(groupBy(listPermissions, "type")).map((item) => {
        return {
          label: strings[item[0]] ?? item[0],
          options: item[1].map((subitem) => {
            return { value: subitem.codigo, label: subitem.observacao };
          })
        };
      })
    } else {
      listPermissions = []
    }

    // //console.log("setListPermissions", listPermissions)
    setListPermissions(listPermissions);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();

  const onPermissionSelected = (selected) => {
    setSelectedPermission(selected);
  }

  const saveForm = async () => {
    setOpenSpinner(true);
    const rowData = {
      codigo: codigo,
      titulo: titulo,
      permissions: selectedPermission,
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: titulo, label: 'Nome', type: 'required' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = null;
    if (codigo) {
      result = await userApiService.updateProfile(rowData);
    } else {
      result = await userApiService.saveProfile(rowData);
    }
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      showMsg.success('Dados salvos com sucesso!')
    } else {

      showMsg.error(createErrorMessage(result))
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const setData = (perfil) => {
    setCodigo(perfil?.codigo)
    setTitulo(perfil?.titulo);
    setPermissoes(perfil?.permissions);
    const filteredSelectedOptions = [...new Set(perfil?.permissions?.map(item => item.codigo))]
    //console.log({ filteredSelectedOptions })
    setSelectedPermission(filteredSelectedOptions)
  }

  const handleAdd = () => {
    setData();
    setCancel("Cancelar")
    setOpenForm(1);
  }

  const handleShow = (perfil) => {
    //console.log(perfil)
    setData(perfil)
    setBack("Voltar")
    setCancel('Cancelar')

    setOpenForm(2);
  }

  const handleEdit = (perfil) => {
    setData(perfil);
    setCancel("Cancelar")
    setOpenForm(3)
  }

  const handleDelete = (perfil) => {
    //console.log('0pronto')
    setCodigo(perfil?.codigo)
    setShowConfirm(true)
  }

  const showConfirmBox = () => {
    return (
      showConfirm ? <ConfirmBox msg={'Deseja remover este perfil?'} handleResult={deleteData} /> : null
    );
  }

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteProfile(codigo);
      if (result?.success) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessage.messageRemove)
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
  }

  const editHandleView = (msg) => {
    //console.log('page ', msg);
    setOpenForm(3);
  }

  const loadTable = async () => {
    let list = (await userApiService.getProfiles() || []).reverse();
    let rows = [];
    Object.values(list).map(l => {
      rows.push({
        num: l?.codigo,
        descricao: l?.titulo,
        action: (<Actions page="perfis" value={l} handleView={handleShow} handleEdit={handleEdit} handleDelete={handleDelete} />)
      });
    });
    setList({ columns: columns, rows: rows })

  }

  const handleNome = (titulo) => {
    let perf = perfil || {};
    perf.titulo = titulo;
    setTitulo(titulo);
    setPerfil(perf);
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>PERFIS</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='perfis'
              handleAdd={handleAdd}
              dataTip={'Adicionar Perfil'}
              dataFor={'adicionar'}
              addText={'Adicionar'} />
          </div>
        </Box>

        <Box className={`listagem-tabela flex-fill d-12`}  >
          <DATATABLE
            className={`form-list flex-fill d-12`}
            data={list}
          />
        </Box>
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                {back}
              </Button>
              <Button onClick={() => handlePageChange(editHandleView, "perfis", "edit", 'edit')} className={`right-btn m-1`} >
                Editar
              </Button>

            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column p-5 pb-3`} >
          <Col sm={12} className={`d-flex flex-row d-6 `}  >
            <Col sm={4} className={`d-flex flex-column d-6 `}  >
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend"><strong>Perfil</strong></FormLabel>
                <FormLabel>{capFirstLetter(titulo)}</FormLabel>
              </FormControl>
            </Col>
          </Col>
          <FormLabel component="legend" className={`pt-3`}><strong>Permissões</strong></FormLabel>
          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {
                permissoes?.map(item => {
                  return <ListItem key={item?.codigo}>
                    <ListItemAvatar>
                      <Avatar>
                        <SecurityIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.observacao} secondary={item.modelo} />
                  </ListItem>
                })
              }
            </List>
          </Col>
        </Box>
      </Row>
    )
  }

  const editForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>&nbsp;PERFIL
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                {cancel}
              </Button>
              <Button onClick={saveForm} className={`right-btn m-1`} >
                Salvar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column h-0 p-5`}  >
          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
              <label className={`input-label-23`} >Nome:</label>
              <Input
                type='select'
                label="descricao"
                disableUnderline={true}
                value={titulo}
                onChange={(e) => handleNome(e.target.value)}
                autoCapitalize={'none'}
                className={`input-2 large-font`}
              />
            </Col>
          </Col>
          <div style={{ width: 'inherit' }}>
            <FormLabel component="legend" className={`pt-3`}><strong>Permissões</strong></FormLabel>
            <DualBoxList
              options={optionsPermissions}
              selected={selectedPermission}
              onChange={onPermissionSelected}
            />
          </div>
        </Box >
      </Row >)
  }

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      default:
        break;
    }
  }

  return (
    start()
  );

};
