import { databaseService } from '../../services/database.service';

var currentPosition = null;

export const _setCurrentPosition = function () {
  window.navigator.geolocation.getCurrentPosition(_setPosition);
}

export const _setPosition = function (position) {
  console.log(
    `Your coordinate is latitude: ${position.coords.latitude}​​​​​​​​ 
    longitude: ${position.coords.longitude}​​​​​​​​ 
    accuracy: ${position.coords.accuracy}​​​​​​​​`
  )

  databaseService.setGeoPosition({
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    accuracy: position.coords.accuracy
  });

}

export const _getPosition = function () {
  let position = databaseService.getGeoPosition();
  if(position) return position;
  _setCurrentPosition();
  return databaseService.getGeoPosition();
}

export const newPosition = function () {
  if (!navigator.geolocation) {
    throw new Error('Fature not support')
  }
  navigator.geolocation.watchPosition(_getPosition)
}