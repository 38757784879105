import React, { useState, useEffect } from 'react';

import authService from '../services/auth';
import LoginLayout from './LoginLayout';
import AdminLayout from './AdminLayout';
import LoadingBar from '../components/LoadingBar';
import { Row } from 'react-bootstrap';

export default function Layout(props) {
    const [logged, setLogged] = useState(false);
    const [openSpinner, setOpenSpinner] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLogged(await authService.isLogged())
        }
        fetchData();
    }, []);


    const handleCloseSpinner = () => {
        setTimeout(() => {
            setOpenSpinner(false);
        }, 1000);
    };

    const handleSpinnerToggle = () => {
        setOpenSpinner(!openSpinner);
    };

    const showPages = () => {
        const props = {
            setLogged: setLogged,
            setOpenSpinner: setOpenSpinner
        }

        return logged
            ? <AdminLayout {...props} />
            : <LoginLayout {...props} />
    }

    const handleChild = () => {
        const props = {
            openSpinner,
            handleCloseSpinner
        }
        return (
            <Row className={`w-100 vh-100 p-0 m-0 d-flex flex-column justify-content-start`} >
                {
                    showPages()
                }
                <LoadingBar {...props}/>
            </Row>
        )
    };

    return handleChild()
};
