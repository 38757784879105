import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import api from '../../services/api'

import validator from '../../services/validator.service'
import Myframe from '../Myframe';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import ReactTooltip from 'react-tooltip';
import AddButton from '../../components/AddButton';
import { Col, Row } from 'react-bootstrap';
import minagrip_s from '../../assets/images/minagrip-s.png';
import SelectLabels from '../../components/SelectLabels';
import DATATABLE from "../../components/DataTable/dataTable"
import UserActions from '../../components/UserActions';
import ConfirmBox from '../../components/ConfirmBox'
import { capFirstLetter } from '../../utils/string';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { groupBy } from '../../utils/group';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import SecurityIcon from '@mui/icons-material/Security';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import strings from '../../assets/lang/strings';
import { ChangePictureActionButton } from '../../components/changePictureActionButton';
import { BootstrapDialog } from '../../components/bootstrapDialog';
import StringMessages from '../../utils/string-messages'
import DualBoxList from '../../components/DualBoxList';
import { handlePageChange } from '../../utils/handlePageChange';
const userApiService = new api();
const validation = new validator();

const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Nomes',
    field: 'nome',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Entidade',
    field: 'entidade',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Departamento',
    field: 'departamento',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Perfil',
    field: 'role',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 300
  }
];

export default function Utilizadores(props) {
  const [btnQtdes, setBtnQtdes] = useState(3);
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Formulário');
  const [openForm, setOpenForm] = useState(0);
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [morada, setMorada] = useState();
  const [bilheteNR, setbilheteNR] = useState();
  const [provincia, setProvincia] = useState({ nome: 'Luanda' });
  const [municipio, setMunicipio] = useState({ nome: 'Viana' });
  const [contacto, setContacto] = useState();
  const [departamento, setDepartamento] = useState();
  const [entidade, setEntidade] = useState();
  const [role, setRole] = useState();
  const [codigo, setCodigo] = useState();
  const [deletedAt, setDeletedAt] = useState();
  const [listaRoles, setListaRoles] = useState();
  const [listaEntidades, setListaEntidades] = useState([]);
  const [listaDepts, setListaDepts] = useState([]);
  const [listaFilteredDepts, setListaFilteredDepts] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEnable, setShowConfirmEnable] = useState(false);
  const [selectedPermissions, setSelectedPermission] = useState();
  const [optionsPermissions, setListPermissions] = useState();
  const [permissoes, setPermissoes] = useState([]);
  const [softDeleteOperation, setUserSoftDeleteOperation] = useState("desactivar");
  const [tipoDeEntidade, setTipoDeEntidade] = useState("interna");
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [dataUri, setDataUri] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [showCameraItem, setShowCameraItem] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [back, setBack] = useState("")
  const [cancel, setCancel] = useState("")
  const [filterDep, setFilterDep] = useState([])
  const [userEdit, setUserEdit] = useState([])



  useEffect(async function () {
    try {
      ReactTooltip.rebuild();
      setOpenSpinner(true);
      //console.log("tipoDeEntidade", tipoDeEntidade);
      validation.setShowMsg(showMsg.error);
      loadTable();
      let profiles = await userApiService.getProfiles() ?? [];
      let entidades = await userApiService.getEntidades() ?? [];
      let depts = await userApiService.getDepartamentos() ?? [];
      let listPermissions = await userApiService.getPermissions() || [];


      permissionHandle(profiles, 'Perfil')
      permissionHandle(entidades, 'Entidades')
      permissionHandle(depts, 'Departamentos')
      permissionHandle(listPermissions, 'Permissões')


      if (Array.isArray(profiles)) {
        profiles = profiles.map((item) => {
          return { codigo: item.codigo, nome: item.titulo }
        })
      } else {
        profiles = []
      }

      if (Array.isArray(entidades)) {
        entidades = entidades.map((item) => {
          return { codigo: item.codigo, nome: item.nome, tipo_entidade: item.tipo_entidade }
        })
      } else {
        entidades = []
      }

      if (Array.isArray(depts)) {
        depts = depts.map((item) => {
          return { codigo: item.codigo, nome: item.nome, entidade: item.entidade.codigo }
        })
      } else {
        depts = []
      }

      if (Array.isArray(listPermissions)) {
        listPermissions = listPermissions.map((item) => {
          var type = item.nome.split('_');
          if (type.length > 3) {
            item.type = `${item.nome.split('_')[2]}_${item.nome.split('_')[3]}`;
          } else {
            item.type = item.nome.split('_')[2];
          }
          return item;
        })

        listPermissions = Object.entries(groupBy(listPermissions, "type")).map((item) => {
          return {
            label: strings[item[0]] ?? item[0],
            options: item[1].map((subitem) => {
              return { value: subitem.codigo, label: subitem.observacao };
            })
          };
        })
      } else {
        listPermissions = []
      }

      setListPermissions(listPermissions);
      setListaRoles(profiles);
      setListaEntidades(entidades);
      setListaDepts(depts);

      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
    } catch (error) {
      //console.log('catch error: ', error);
      // if (error.status === 403) showMsg.error(error.message);
      setOpenSpinner(false);
    }
  }, [])

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  const onPermissionSelected = (selected) => {
    setSelectedPermission(selected);
  }

  useEffect(() => {
    //console.log('3::listas', listaDepts, listaRoles, listaEntidades);
  }, [listaDepts]);

  useEffect(() => {
    const user = userEdit;
    setData(user);
  }, [userEdit]);

  const setData = async (user) => {
    setDataUri(null)
    setCodigo(user?.codigo || null);
    setNome(user?.nome || '');
    setEmail(user?.email || '');
    setMorada(user?.morada || '');
    setbilheteNR(user?.bilheteNR || '');
    setProvincia(user?.provincia || '');
    setMunicipio(user?.municipio || '');
    setContacto(user?.contacto || '');
    setEntidade(user?.entidade);
    setDepartamento(user?.departamento);
    setRole(user?.role);
    setDeletedAt(user?.deleted_at);
    configureProfilePic(user)
    setPermissoes(user?.permissions ?? []);
    const filteredSelectedOptions = [...new Set(user?.permissions?.map(item => item.codigo))]
    setSelectedPermission(filteredSelectedOptions)
    var tipoDeEntity = listaEntidades.find((item) => item.codigo === user?.entidade.codigo);
    tipoDeEntity = tipoDeEntity?.tipo_entidade ?? 'interna';
    setTipoDeEntidade(tipoDeEntity);
    setUserEdit(user)
    if (user?.entidade) {
      setListaFilteredDepts(listaDepts.filter(depto => depto.entidade === user?.entidade.codigo))
    }
  }

  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setCancel("Cancelar")
    setSaveButtonVisible('flex')
    setEditButtonVisible('none')

  }

  const handleShow = (user) => {
    setData(user)
    setBack("Voltar")
    setCancel('Cancelar')
    setSaveButtonVisible('none')
    setOpenForm(2);

  }

  const handleEdit = (user) => {
    setData(user);
    setOpenForm(3);
    setCancel("Cancelar")
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }
  const handleSetEntidade = (obj) => {
    const { tipo_entidade, ...item } = obj;
    setEntidade(item);
    setTipoDeEntidade(tipo_entidade);
    setListaFilteredDepts(listaDepts.filter(depto => depto.entidade === item.codigo))
  }

  const handleDisabeEnable = (obj) => {
    setCodigo(obj?.codigo)
    setUserSoftDeleteOperation(obj?.deleted_at ? "enable" : "disable");
    setShowConfirm(true)
  }
  const handleEnable = (obj) => {
    setCodigo(obj?.codigo)
    setUserSoftDeleteOperation(obj?.deleted_at ? "enable" : "disable");
    setShowConfirmEnable(true)
  }
  hideSpinner();

  const showConfirmBox = () => {
    var nome = 'Usuario?'
    return (
      showConfirm ? <ConfirmBox msg={StringMessages.message4 + ` ${nome}`} handleResult={enableUserData} /> : null
    );
  }

  const showConfirmBoxEnable = () => {
    var nome = 'Usuario?'
    return (
      showConfirmEnable ? <ConfirmBox msg={StringMessages.message3 + ` ${nome}`} handleResult={disAbleUserData} /> : null
    );
  }
  const enableUserData = async (answer) => {
    const rowData = {
      codigo: codigo
    };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.activateUser(rowData);
      if (result?.success) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessages.disactivateUserSucess)
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }
  const disAbleUserData = async (answer) => {
    const rowData = {
      codigo: codigo
    };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.disActivateUser(rowData);
      if (result?.success) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessages.activateUserSucess)
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }

    setShowConfirmEnable(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const saveForm = async () => {
    setOpenSpinner(true);
    let rowData = new FormData()
    rowData.append('codigo', codigo)
    rowData.append('profile_pic', dataUri ?? profilePic)
    rowData.append('nome', nome)
    rowData.append('email', email)
    rowData.append('morada', morada)
    rowData.append('bilheteNR', bilheteNR)
    rowData.append('role', role?.codigo)
    rowData.append('entidade', entidade?.codigo)
    rowData.append('contacto', contacto)
    rowData.append('departamento', departamento?.codigo)
    rowData.append('permissions', selectedPermissions)
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: role, label: 'Perfil', type: 'required' },
        { value: nome, label: 'Nome', type: 'required' },
        { value: contacto, label: 'Contacto', type: 'number' },
        { value: email, type: 'email', label: 'email' },
        { value: departamento?.codigo, type: 'required', label: 'departamento' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveUser(rowData);
    // //console.log("RESULT", result)
    if (result?.success) {
      loadTable();
      setData()
      setShowCameraItem(false);
      setOpenPictureModal(false);
      setDataUri(null)
      setAnchorEl(null);
      setOpenForm(0);
      showMsg.success(StringMessages.messageSave)
    } else {
      let error = result?.exception?.msg
      showMsg.error(error)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }


  const updateUser = async () => {
    setOpenSpinner(true);
    let rowData = new FormData()
    rowData.append('codigo', codigo)
    rowData.append('profile_pic', dataUri ?? profilePic)
    rowData.append('nome', nome)
    rowData.append('email', email)
    rowData.append('morada', morada)
    rowData.append('bilheteNR', bilheteNR)
    rowData.append('role', role?.codigo)
    rowData.append('entidade', entidade?.codigo)
    rowData.append('contacto', contacto)
    rowData.append('departamento', departamento?.codigo)
    rowData.append('permissions', selectedPermissions)

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: role, label: 'Perfil', type: 'required' },
        { value: nome, label: 'Nome', type: 'required' },
        { value: contacto, label: 'Contacto', type: 'number' },
        { value: email, type: 'email', label: 'email' },
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateUser2(rowData);
    let message = StringMessages.messageEditar;

    if (result?.success) {
      loadTable();
      setData()
      setShowCameraItem(false);
      setOpenPictureModal(false);
      setDataUri(null)
      setAnchorEl(null);
      setOpenForm(0);
      showMsg.success(message)
    } else {
      let error = result?.exception?.msg
      showMsg.error(error)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const editHandleView = (msg) => {
    //console.log('page ', msg);
    setOpenForm(3);
  }


  const loadTable = async () => {
    let list = ((await userApiService.getUsers()) ?? []).reverse();
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: count++,
          nome: l.nome,
          morada: l.morada,
          bilheteNR: l.bilheteNR,
          entidade: l?.entidade?.nome,
          departamento: l?.departamento?.nome,
          role: l?.role?.nome,
          status: l?.status == 2 ? StringMessages.userStatus.Inactive : l?.status == 1 ? StringMessages.userStatus.Active : l?.status == 0 ? StringMessages.userStatus.Created : '',
          action: (<UserActions value={l}
            page='users'
            handleView={handleShow}
            userBlockHandle={handleEdit}
            handleDisabeEnable={handleDisabeEnable}
            handleEnableUser={handleEnable}
            usertatus={l.status}
          />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }

  const handleTitle = (params) => {
    const { title = 'Utilizadores', subtitle = 'Cadastrados', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      default:
        break;
    }
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        {showConfirmBoxEnable()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>UTILIZADORES</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='users'
              handleAdd={handleAdd}
              dataTip={'Adicionar Usuario'}
              dataFor={'adicionar'}
              addText={'Adicionar'}
            />
          </div>
        </Box>

        {iframeUrl ?
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
            {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
          :
          <>
            <Box className={`listagem-tabela flex-fill d-12`}  >
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        }
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                {back}
              </Button>
              <Button onClick={() => handlePageChange(editHandleView, "users", "edit", 'edit')} className={`right-btn m-1`} >
                Editar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column p-5 pb-3`}>
          <Col sm={12} className={`d-flex flex-column pb-3`}>
            <Col sm={12} className={`d-flex flex-md-row flex-column pb-3`}>
              <Col md={2} sm={12} className={`d-flex flex-md-row flex-column pb-3 mr-2 user-image-cont`}>
                <img className={`user-profile-img mr-2`} src={profilePic} />
              </Col>
              <Col md={10} sm={12} className={`d-flex flex-md-row flex-column pb-3`}>
                <Col sm={4} className={`d-flex flex-column d-6 `}  >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Nome</strong></FormLabel>
                    <FormLabel>{capFirstLetter(nome)}</FormLabel>
                  </FormControl>
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Contacto</strong></FormLabel>
                    <FormLabel>{capFirstLetter(contacto)}</FormLabel>
                  </FormControl>
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Morada</strong></FormLabel>
                    <FormLabel>{capFirstLetter(morada)}</FormLabel>
                  </FormControl>
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Email</strong></FormLabel>
                    <FormLabel>{email}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={4} className={`d-flex flex-column d-6 `}  >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Número do Bilhete</strong></FormLabel>
                    <FormLabel>{bilheteNR}</FormLabel>
                  </FormControl>
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Entidade</strong></FormLabel>
                    <FormLabel>{entidade?.nome}</FormLabel>
                  </FormControl>
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Departamento</strong></FormLabel>
                    <FormLabel>{departamento?.nome}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={4} className={`d-flex flex-column d-6 `}  >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend"><strong>Perfil</strong></FormLabel>
                    <FormLabel>{role?.nome}</FormLabel>
                  </FormControl>
                </Col>
              </Col>
            </Col>
          </Col>
          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <FormControl component="fieldset" className="mb-2">
              <FormLabel component="legend"><strong>Permissões</strong></FormLabel>
              <Col sm={12} className={`d-flex flex-column d-6 `}  >
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                >
                  {
                    permissoes?.map(item => {
                      return <ListItem key={item?.codigo}>
                        <ListItemAvatar>
                          <Avatar>
                            <SecurityIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.observacao} secondary={item.modelo} />
                      </ListItem>
                    })
                  }
                </List>
              </Col>
            </FormControl>
          </Col>
        </Box>
      </Row>
    )
  }

  const editForm = () => {
    const openPopOver = Boolean(anchorEl);
    const popOverId = openPopOver ? 'simple-popover' : undefined;
    const isFullscreen = true;
    var dialogProps = {
      isFullscreen,
      dataUri,
      openPictureModal,
      handleClosePictureModal,
      handleClosePictureModalReset,
      handleTakePhotoAnimationDone,
      handleCameraError,
      handleSendToServer: null,
      handleRepeatPicture,
      showCameraItem
    }

    const actionButtonProps = {
      dataUri,
      openPopOver,
      handleOpenTakeFotoModal,
      handleOpenGallery,
      onChangeSelectedImage,
      anchorEl,
      popOverId,
      handleOpenPopOver,
      handleClosePopOver,
      handleSendToServer: null,
      handleRemoveDataUri
    }
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>&nbsp; UTILIZADOR
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`}>
                {cancel}
              </Button>
              <Button onClick={saveForm} className={`right-btn m-1`} style={{ display: saveButtonVisible }} >
                Salvar
              </Button>
              <Button onClick={updateUser} className={`right-btn m-1`} style={{ display: editButtonVisible }} >
                Actualizar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row h-100 p-5`}  >
          <Col sm={3} className={`d-flex d-6 mr-4`}  >
            <div className={`user-profile-img-cont  d-flex flex-column flex-fill`} >
              <div>
                <img className={`user-profile-img mr-2 p-0`} src={profilePic} />
              </div>
              <ChangePictureActionButton {...actionButtonProps} />
            </div>
          </Col>
          <Col sm={9} className={`d-flex flex-column d-6 `}  >
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Perfil:</label>
                <SelectLabels
                  initialValue={role}
                  setValue={setRole}
                  lista={listaRoles}
                  className={`select-labels large-font`}
                />
              </Col>
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Entidade:</label>
                <SelectLabels
                  initialValue={entidade}
                  setValue={handleSetEntidade}
                  lista={listaEntidades}
                  className={`select-labels large-font`}
                />
              </Col>
            </Col>
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`}>Departamento:</label>
                <SelectLabels
                  initialValue={departamento}
                  setValue={setDepartamento}
                  lista={listaFilteredDepts}
                  className={`select-labels large-font`}
                />
              </Col>
            </Col>
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Nome:</label>
                <Input
                  type='text'
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Contacto:</label>
                <Input
                  type='text'
                  label="contacto"
                  disableUnderline={true}
                  value={contacto}
                  onChange={(e) => setContacto(e.target.value)}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Email:</label>
                <Input
                  type='email'
                  label="email"
                  disableUnderline={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
              {
                tipoDeEntidade !== "interna" &&
                <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2`} >
                  <label className={`input-label-23`} >Número do Bilhete:</label>
                  <Input
                    type='string'
                    disableUnderline={true}
                    value={bilheteNR}
                    onChange={(e) => setbilheteNR(e.target.value)}
                    autoCapitalize={'none'}
                    className={`input-2 large-font`}
                  />
                </Col>
              }
            </Col>
            {
              tipoDeEntidade !== "interna" &&
              <Col sm={12} className={`d-flex flex-row d-6`}>
                <Col sm={12} className={`d-flex flex-column`}>
                  <label className={`large-font`}>Morada:</label>
                  <textarea
                    value={morada}
                    min={4}
                    className={`user-img`}
                    onChange={(e) => {
                      setMorada(e.target.value);
                    }}
                    rows="6"
                  />
                </Col>
              </Col>
            }
            <Col sm={12} className={`pb-5`}>
              <div>
                <label className={`large-font`}>Permissões:</label>
                <DualBoxList
                  options={optionsPermissions}
                  selected={selectedPermissions}
                  onChange={onPermissionSelected}
                />
              </div>
            </Col>
          </Col>
        </Box>
        <BootstrapDialog  {...dialogProps} />
      </Row >)
  }

  const configureProfilePic = (paramUser = null) => {
    let _user = paramUser
    //console.log("user -configureProfilePic", _user)
    const exisPropPic = _user?.profile_pic
      ? userApiService.getProfilePic(_user?.profile_pic)
      : minagrip_s
    setProfilePic(exisPropPic)
  }

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClosePopOver = () => {
    setAnchorEl(null);
    configureProfilePic()
  }

  const handleOpenTakeFotoModal = () => {
    setOpenPictureModal(true);
    setShowCameraItem(true)
    setAnchorEl(null);
    setDataUri(null)
    configureProfilePic();
  }

  const handleOpenGallery = () => {
    setAnchorEl(null);
    setDataUri(null)
    configureProfilePic();
  }

  const handleClosePictureModal = () => {
    setOpenPictureModal(false)
    setShowCameraItem(false)
  }

  const handleClosePictureModalReset = () => {
    setDataUri(null)
    handleClosePictureModal()
    configureProfilePic()
  }

  const handleTakePhoto = (dataUri) => {
    try {
      createPreview(dataUri)
      setDataUri(dataUri)
    } catch (error) {
      //console.log("Error > createImage")
      //console.log(error)
    }
  }

  const handleTakePhotoAnimationDone = (dataUri) => {
    try {
      createPreview(dataUri)
      setDataUri(dataUri)
    } catch (error) {
      //console.log("Error > createImage")
      //console.log(error)
    }
  }

  const handleCameraError = (error) => {
    //console.log('handleCameraError', error);
  }

  const handleRepeatPicture = () => {
    setDataUri(null);
    configureProfilePic();
  }

  const onChangeSelectedImage = async (e) => {
    try {
      const imageBlob = await createImage(e.target.files[0])
      createPreview(imageBlob)
      setDataUri(imageBlob)
    } catch (error) {
      //console.log("Error > createImage")
      //console.log(error)
    }
  }

  const createImage = (imageBlob) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    } catch (error) {
      //console.log("Error > createImage")
      //console.log(error)
    }
  }

  const createPreview = (imageBlob) => {
    try {
      setProfilePic(imageBlob)
    } catch (error) {
      //console.log("Error > createImage")
      //console.log(error)
    }
  }

  const handleRemoveDataUri = () => {
    setDataUri(null)
    configureProfilePic();
  }

  return (
    start()
  );

};
