import React, {
  useState,
  useEffect,
} from 'react';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Toast from '../../components/Toast'
import TopbarMenu from '../../components/Menu/topbar'
import SidebarMenu from '../../components/Menu/sidebar'
import Formularios from '../Formularios'
import Questionarios from '../Questionarios'
import Perfil from '../Perfil'
import RemeterFormularios from '../RemeterFormularios'
import FormulariosRecebidos from '../FormulariosRecebidos'
import ValidarFormularios from '../ValidarFormularios'
import ActividadePrincipal from '../ActividadePrincipal';
import InfrasestruturaEmpresa from '../Infraestrutura de Empresa';
import MapViewer from '../MapViewer'
import { Col, Card, Row, Container } from 'react-bootstrap';
import icones from '../../themes/icones';
import { logout } from '../../services/database.service'
import Utilizadores from '../Utilizadores';
import Provincias from '../Provincias';
import Municipios from '../Municipios';
import Comunas from '../Comunas';
import ExplorationTypes from '../ExplorationTypes';
import SocialObjects from '../SocialObjects';
import Bairros from '../Bairros';
import Departamentos from '../Departamentos';
import Entidades from '../Entidades';
import Inqueritos from '../Inqueritos';
import Epocas from '../Epocas';
import Produtores from '../Produtores';
import TipoProdutores from '../TipoProducao';
import TipoCultura from '../TipoCultura';
import EfectivoPecuario from '../EfectivoPecuario';
import MinhaConta from '../MinhaConta';
import Permissoes from '../Permissoes';
import Dashboard from '../Dashboard';
import ChangePassword from '../ChangePassword';
import FormBuilder from '../FormBuilder';
import ReportBuilder from '../ReportBuilder';
import DesignModeScreen from '../DesignMode';
import AuditTrail from '../AuditTrails';
import NomearRegisto from '../NomeRegisto/NomearRegisto';
import NomearDirector from '../NomeDirector/index';

import api from '../../services/api'
import sleep from '../../utils/sleep';
import SubbarMenu from '../../components/Menu/subMenu';
const userApiService = new api();

export default function MapScreen(props) {
  const screenFormList = 'formlist';
  const [user, setUser] = useState(true);
  const [mapOpen, setMapOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState("home");

  const [alertShow, setAlertShow] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('error');
  const [designMode, setDesignMode] = useState(false);

  const { setLogged, setOpenSpinner } = props;
  useEffect(() => {
    async function getUserData() {
      let _user = await userApiService.getUserData();
      // //console.log('LOGGED IN USER', _user)
      //     let resp = await userApiService.synchronize();
      //     //console.log('afia dddddd');
      //     showMsg.success('Sincronização concluída');
      setUser(_user);
    }
    getUserData();
  }, [])

  const handleOpen = () => {
    setActiveScreen('menu')
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSetActiveScreen = async(screen) => {
    setActiveScreen('')
    await sleep(50)
    setActiveScreen(screen)
  }

  const getProps = () => {
    return {
      showMsg: showMsg,
      alertShow: alertShow,
      openMap: () => setMapOpen(true),
      handleClose: handleClose,
      changeScreen: handleSetActiveScreen,
      setOpenSpinner: setOpenSpinner,
      hideAlertShow: () => {
        setAlertShow(false)
      },
    }
  }

  const menuProps = {
    activeScreen: activeScreen,
    showMsg: showMsg,
    designMode: designMode,
    setDesignMode: setDesignMode,
    handleOpen: handleOpen,
    handleClose: handleClose,
    changeScreen: handleSetActiveScreen,
    setLogged: setLogged,
    user: user
  }

  const handleScreen = () => {
    switch (activeScreen) {
      case 'menu': return <SidebarMenu {...menuProps} />;
      case 'home': return <Dashboard {...getProps()} />
      case 'formlist': return <Formularios {...getProps()} />;
      case 'questlist': return <Questionarios {...getProps()} />;
      case 'perfis': return <Perfil {...getProps()} />;
      case 'permissoes': return <Permissoes {...getProps()} />;
      case 'users': return <Utilizadores {...getProps()} />;
      case 'provincias': return <Provincias {...getProps()} />;
      case 'municipios': return <Municipios {...getProps()} />;
      case 'comunas': return <Comunas {...getProps()} />;
      case 'exploration_types': return <ExplorationTypes {...getProps()} />;
      case 'social_objects': return <SocialObjects {...getProps()} />;
      case 'bairros': return <Bairros {...getProps()} />;
      case 'departamentos': return <Departamentos {...getProps()} />;
      case 'entidades': return <Entidades {...getProps()} />;
      case 'seasons': return <Epocas {...getProps()} />;
      case 'produtores': return <Produtores {...getProps()} />;
      case 'tipo de produtores': return <TipoProdutores {...getProps()} />;
      case 'actividades principal': return <ActividadePrincipal {...getProps()} />;
      case 'companies_infrastructure': return <InfrasestruturaEmpresa {...getProps()} />;
      case 'culture_types': return <TipoCultura {...getProps()} />;
      case 'effective_livestock': return <EfectivoPecuario {...getProps()} />;
      case 'inquiries': return <Inqueritos {...getProps()} />;
      case 'change_password': return <ChangePassword {...getProps()} />;
      case 'minha_conta': return <MinhaConta {...getProps()} />;
      case 'modo_design': return <DesignModeScreen {...getProps()} />;
      case 'form_builder': return <FormBuilder {...getProps()} />;
      case 'report_builder': return <ReportBuilder {...getProps()} />;
      case 'remit_form': return <RemeterFormularios {...getProps()} />;
      case 'remitted_form': return <FormulariosRecebidos {...getProps()} />;
      case 'validate_form': return <ValidarFormularios {...getProps()} />;
      case 'audit_trail': return <AuditTrail {...getProps()} />;
      case 'renomear_registo': return <NomearRegisto {...getProps()} />;
      case 'renomear_director': return <NomearDirector {...getProps()} />;
      case 'subbar_menu': return <SubbarMenu {...getProps()} />;
      // default: return handleIndex();
    }
  }

  var showMsg = {
    error: (msg) => {
      //console.log('error msg')
      setAlertShow(true);
      setAlertMsg(msg);
      setAlertType('error');
    },

    success: (msg) => {
      //console.log('success msg')
      setAlertShow(true);
      setAlertMsg(msg);
      setAlertType('success');
    }
  }

  const containerSize = designMode ? 12 : 10;

  return (
    <>
      {alertShow ? <Toast onClose={setAlertShow} type={alertType} msg={alertMsg} /> : null}
      {mapOpen ? <MapViewer closeMap={() => setMapOpen(false)} /> : null}
      <Row className={`${designMode ? `design-mode-header` : `header`} d-flex position-relative w-100 p-0 m-0`} >
        <TopbarMenu {...menuProps} />
      </Row>
      <Row className={`body d-flex flex-row w-100 p-0 m-0 mb- flex-fill `} >
        {
          !designMode &&
          <Col md={2} lg={2} className={`d-none d-md-block h-100 end-0 m-0 p-0`} >
            <SidebarMenu {...menuProps} />
          </Col>
        }
        <Col xs={12} md={containerSize} lg={containerSize} className={`map-container position-relative h-100 m-0 p-0`}  >
          <div className={`map-cover-div ${activeScreen ? '' : ' d-none '} position-absolute start-0 top-0 w-100 h-100 m-0 p-0`} >
            {handleScreen()}
          </div>
          <div className={`map-div m-0 p-0`} > </div>
        </Col>
      </Row>
    </>
  );
};