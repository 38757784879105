const compareNullDate = (data) => {
   return Date.parse(new Date()) !== Date.parse(new Date())?data:Date.parse(new Date())
}

const compareNullDateFormatted = (data) => {
    return data === null?new Date():data
 }
 

module.exports={
    compareNullDate,
    compareNullDateFormatted
}