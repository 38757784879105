import React, {
  useState,
  useEffect,
} from 'react';

import createErrorMessage from "../../utils/createErrorMessage"
import api from '../../services/api'
import style from '../../themes/styles/geral';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { Col, Row } from 'react-bootstrap';
import DATATABLE from "../../components/DataTable/dataTable"
import Actions from '../../components/Actions';
import validator from '../../services/validator.service'
import ConfirmBox from '../../components/ConfirmBox'
import Stringmessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton';


const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Tipos de Explorações',
    field: 'nome',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Acções',
    field: 'action',
    sort: 'asc',
    width: 200
  }
];

const validation = new validator();

export default function ExplorationTypes(props) {
  const [list, setList] = useState([]);
  const [openForm, setOpenForm] = useState('');
  const [nome, setNome] = useState('');
  const [observacao, setObservacao] = useState('');
  const [codigo, setCodigo] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [cancel, setCancel] = useState("Cancelar")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

  useEffect(function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])


  const setData = (dt = null) => {
    setCodigo(dt?.codigo);
    setNome(dt?.nome);
    setOpenForm(true);
  }

  const handleEdit = (prov) => {
    setData(prov);
    setOpenForm(true);
    setCancel("Cancelar")
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }
  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setCancel("Cancelar")
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  }
  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();

  const showConfirmBox = () => {
    var nome ='Tipo De Exploração?'
      return (
        showConfirm ? <ConfirmBox msg={Stringmessage.message + ` ${nome}`} handleResult={deleteData} /> : null
      );
    }

  const handleDelete = (obj) => {
    //console.log('0pronto')
    setCodigo(obj?.codigo)
    setShowConfirm(true)
  }

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteExplorationType(codigo);
      if (result) {
        loadTable();
        setOpenForm(false);
        showMsg.success(Stringmessage.messageRemove)
      } else {
        showMsg.error(result?.exception?.msg)
      }
    }
    setShowConfirm(false)
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
  }

  const saveForm = async () => {
    setOpenSpinner(true);
    const prov = { codigo: codigo, nome: nome, observacao: observacao };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' }
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveExplorationType(prov);
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(false);
      showMsg.success(Stringmessage.messageSave)
    } else {
      showMsg.error(createErrorMessage(result))
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const updateForm = async () => {
    setOpenSpinner(true);
    const prov = { codigo: codigo, nome: nome, observacao: observacao };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (!validation.handleValidation(
      [
        { value: nome, label: 'Nome', type: 'required' }
      ])) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateExplorationType(prov);
    let message = Stringmessage.messageEditar
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(false);
      showMsg.success(message)
    } else {
      showMsg.error(result?.exception?.msg)
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const loadTable = async () => {
    let list = ((await userApiService.getExplorationTypes())?? []).reverse();
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: count++,
          nome: l.nome,
          action: (<Actions page ='exploration_types' value={l} handleEdit={handleEdit} handleDelete={handleDelete} />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }

  const handleTitle = (params) => {
    const { title = 'Tipos de Exploração', subtitle = 'Cadastradas', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const start = () => {
    return openForm ? showForm() : handleFormMenus();
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
        <Box className={`listagem-header`}>
          <strong>TIPOS DE EXPLORAÇÃO</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
          <AddButton 
        page='exploration_types'
        handleAdd={handleAdd}
        dataTip={'Adicionar Tipo de Exploração'}
        dataFor={'adicionar'}
        addText={'Adicionar'}/>
          </div>
        </Box>
        <Box className={`listagem-tabela flex-fill d-12`}  >
          <DATATABLE
            className={`form-list flex-fill d-12`}
            data={list}
          />
        </Box>
      </Box >)
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >

        <Box className={`listagem-headerr d-flex flex-row p-25`}>
        <Box className={`listagem-header`}>
          <strong>CADASTRAR</strong>&nbsp;NOVO TIPO DE EXPLORAÇÃO
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
          <div className="allbtns">
          <Button onClick={() => setOpenForm(false)} className={`cancel-btn m-1`} >
              {cancel}
            </Button>
            <Button onClick={saveForm} className={`right-btn m-1`}  style={{display:saveButtonVisible}}>
              Salvar
            </Button>
            <Button onClick={updateForm} className={`right-btn m-1`} style={{display:editButtonVisible}}>
              Actualizar
            </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row h-100 p-5`}  >

          <Col sm={12} className={`d-flex flex-column d-6 `}  >
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}  >
              <Col sm={6} className={`d-flex flex-column  justify-content-start large-font input-container-2 required`} >
                <label className={`input-label-23`} >Nome:</label>
                <Input
                  type='select'
                  label="perfil"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  autoCapitalize={'none'}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
          </Col>
        </Box>
      </Row >)
  }

  return (
    start()
  );

};