import React, {
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Actions from '../../components/Actions';
import DATATABLE from "../../components/DataTable/dataTable"
import validator from '../../services/validator.service'
import api from '../../services/api'
import ConfirmBox from '../../components/ConfirmBox'
import StringMessage from '../../utils/string-messages'
import AddButton from '../../components/AddButton';
import Myframe from '../Myframe';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { formatDate, formatTime } from '../../utils/formatDate'
import { capFirstLetter } from "../../utils/string";
import { syntaxHighlight } from '../../utils/javascript.';
import { create } from '@material-ui/core/styles/createTransitions';

const validation = new validator();
const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: 'Nome',
    field: 'user',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Acção',
    field: 'action_performed',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Modulo',
    field: 'model',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Data',
    field: 'created_at',
    sort: 'asc',
    width: 300
  },
  {
    label: 'Hora',
    field: 'hour',
    sort: 'asc',
    width: 300
  },
  /* {
       label: 'Acções',
       field: 'action',
       sort: 'asc',
       width: 200
   }*/
];

export default function AuditTrails(props) {
  const [list, setList] = useState([]);
  const [openForm, setOpenForm] = useState();
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState('Auditória');
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [codigo, setCodigo] = useState("");
  const [action, setAction] = useState("");
  const [message, setMessage] = useState("");
  const [modelo, setModelo] = useState("");
  // const [message, setMessage] = useState();

  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

  useEffect(async function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, [])

  const setData = (audit = null) => {
    setCodigo(audit?.codigo);
    setMessage(audit?.message);
    setAction(audit?.action);
    setModelo(audit?.model);
    setOpenForm(true);
  }

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const loadTable = async () => {
    let list = (await userApiService.getAuditTrails()) ?? [];

    permissionHandle(list, 'Auditoria')

    if (Array.isArray(list)) {
      list.reverse();
    } else {
      list = []
    }

    let rows = [];

    if (list) {
      Object.values(list).map(l => {
        rows.push({
          num: l.codigo,
          action_performed: l.action === `Create` ? 'Criou' : l.action === `Update` ? `Actualizou` : `Apagou`,
          model: l.model === `Role` ? `Perfil` : l.model === `Entity` ? `Entidade` : `Usuário`,
          user: l?.user?.nome,
          created_at: formatDate(l?.created_at),
          hour: formatTime(l?.created_at),
          //action: (<Actions page='audit_trail' value={l} handleView={handleEdit} handleEdit={handleEdit} handleDelete={handleDelete} />)
        });
      });
      setList({ columns: columns, rows: rows })
    }
  }


  const handleTitle = (params) => {
    const { title = 'Auditória', subtitle = 'Cadastradas', mapButton = false } = params ? params : {};
    return (<Box className={`forms-title-container position-relative`} >

      <h6 className={`forms-title`}>{title}</h6>
      <label className={`forms-sub-title`}>{subtitle}</label>
    </Box>);
  }

  const handleEdit = (prov) => {
    setData(prov);
    setOpenForm(true);
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')
  }

  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  }

  const handleDelete = (obj) => {
    setCodigo(obj?.codigo)
    setShowConfirm(true)
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector('#spinningContainer');
    let spinningCurtain = document.querySelector('#spinningCurtain');

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove()
    spinningCurtain.remove()
  }

  hideSpinner();

  const showConfirmBox = () => {
    var nome = 'Bairro?'
    return (
      showConfirm ? <ConfirmBox msg={StringMessage.message + ` ${nome}`} handleResult={() => { }} /> : null
    );
  }

  const handleFormMenus = () => {
    return (
      <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>AUDITORIA</strong>&nbsp;
          </Box>
          {/* <div className="allbtns">
                        {/* <AddButton
                            page='bairros'
                            handleAdd={handleAdd}
                            dataTip={'Adicionar Bairro'}
                            dataFor={'adicionar'}
                            addText={'Adicionar'} />
                    </div> */}
        </Box>

        {iframeUrl ?
          <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
            {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
          :
          <>
            <Box className={`listagem-tabela flex-fill d-12`}  >
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        }
      </Box >
    )
  }

  const showForm = () => {
    return (
      <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >

        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>AUDITORIA</strong>&nbsp;
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
            <div className="allbtns">
              <Button onClick={() => setOpenForm(false)} className={`cancel-btn m-1`} >
                Cancelar
              </Button>

              {/* <Button onClick={saveForm} className={`right-btn m-1`} style={{ display: saveButtonVisible }}>
                                Salvar
                            </Button> */}
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column h-100 p-5`}  >
          <FormControl component="fieldset" className="mb-2">
            <FormLabel component="legend">
              <strong>Accão</strong>
            </FormLabel>
            <FormLabel>{capFirstLetter(action || "")}</FormLabel>
          </FormControl>
          <FormControl component="fieldset" className="mb-2">
            <FormLabel component="legend">
              <strong>Modulo</strong>
            </FormLabel>
            <FormLabel>{capFirstLetter(modelo || "")}</FormLabel>
          </FormControl>
          <FormControl component="fieldset" className="mb-2">
            <FormLabel component="legend">
              <strong>Valores</strong>
            </FormLabel>
            <FormLabel style={{ maxWidth: "80%", height: "100px" }}>
              <>{JSON.stringify(message, null, '\t') || ""}</>
            </FormLabel>
          </FormControl>
        </Box>
      </Row >)
  }

  const start = () => {
    return openForm ? showForm() : handleFormMenus();
  }

  return (
    start()
  );
}
