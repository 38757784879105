import { formBuilderApiService } from '../services/form_builder_api'
import { flattenObjects } from './objects';

async function holdFile({ type = 'script', href = null, src = null, async = true, rel = null }) {
    let full_address = formBuilderApiService.getAssetFullAdress(src || href);
    let query = `${type}[${src ? 'src' : 'href'}="${full_address}"]`
    let script_exists = document.querySelector(query)
    let _element = null;
    if (!script_exists) {
        _element = document.createElement(type);
        if (src) _element.src = full_address;
        if (href) _element.href = full_address;
        if (async) _element.async = async;
        if (rel) _element.rel = rel;
        _element.crossorigin = "*";
        if (type == 'link'){
          document.head.appendChild(_element);
        }else if (type == 'script'){
          document.body.appendChild(_element);
        }
    } else {
        script_exists.remove()
        script_exists = document.createElement(type);
        if (src) script_exists.src = full_address;
        if (href) script_exists.href = full_address;
        if (async) script_exists.async = async;
        if (rel) script_exists.rel = rel;
        script_exists.crossorigin = "*";
        if (type == 'link'){
          document.head.appendChild(script_exists);
        }else if (type == 'script'){
          document.body.appendChild(script_exists);
        }
    }
}

async function holdManyFiles({ files, type, async, rel }) {
    if (Array.isArray(files)) {
        files.map(file => {
            return holdFile({
                src: type.toLowerCase() === 'script' ? file : false,
                type,
                async,
                rel,
                href: type.toLowerCase() === 'link' ? file : false
            });
        })
    }
}

const setElementValueByType = (tag, elementVal) => {
    if (tag instanceof HTMLAnchorElement) {
    } else if (tag instanceof HTMLDivElement) {
    } else if (tag instanceof HTMLButtonElement) {
    } else if (tag instanceof HTMLInputElement) {
        const type = tag.getAttribute("type");
        if (type === 'number') {
            var number = Number(elementVal) ? Number(elementVal) : elementVal
            if (!tag?.classList?.contains('integer') && !tag?.classList?.contains('double')) {
                tag?.setAttribute("value", number);
            }


        } else if (type === 'radio') {
            if (tag?.value === elementVal) {
                tag?.setAttribute("checked", true);
            }
        } else if (type === 'checkbox') {
            if (elementVal?.value?.includes(tag.value)) {
                tag?.setAttribute("checked", true);
            }
            if (elementVal instanceof Object) {
            } else {
                if (tag?.value === elementVal) {
                    tag?.setAttribute("checked", true);
                }
            }
        } else {
            tag?.setAttribute("value", elementVal);
            var closest = tag?.closest(".formNewLine")?.querySelector("textarea")
            if (closest) {
                closest.innerHTML = elementVal
            }
        }
    } else if (tag instanceof HTMLTextAreaElement) {
    } else if (tag instanceof HTMLSelectElement) {
        const options = Array.from(tag.options);
        // //console.log("OPTIONS", options)
        if (options.length > 0) {
            const optionToSelect = options.find(item => item.text === elementVal);
            if (optionToSelect) {
                optionToSelect.selected = true;
                optionToSelect?.setAttribute('selected', true)
                optionToSelect?.setAttribute('selected', 'selected')
                // //console.log("TAG SELECT_TAG OPTION", optionToSelect);
                // //console.log("TAG SELECT_TAG VALUE", tag.value);
            }
        }
    } else {
    }
}

const setAlreadyFilledData = (div, dataFilled) => {
    if (dataFilled) {
        let flattened = flattenObjects(dataFilled);
        for (const key in flattened) {
            if (Object.hasOwnProperty.call(flattened, key)) {
                const elementVal = flattened[key];
                const keyP = key?.split('.')[1];
                const tags = div?.querySelectorAll(`[name="${keyP}"]`)
                for (const tag of tags) {
                    setElementValueByType(tag, elementVal);
                }
            }
        }
    }
}
export {
    setElementValueByType,
    holdFile,
    holdManyFiles,
    setAlreadyFilledData
}
