import Box from "@material-ui/core/Box";
import React, {
    useState,
    useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import api from '../../services/api'
import validator from '../../services/validator.service'
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from "@material-ui/core/Button";

const userApiService = new api();
const validation = new validator();

export default function DesignMode(props) {

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const [openForm, setOpenForm] = useState(0);

    const { changeScreen, hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

    useEffect(async function () {
        //setOpenSpinner(true);
        validation.setShowMsg(showMsg.error);
    }, [])

    const handleViewReportBuilder = () => {
        // window.open(process.env.REACT_APP_REPORT_BUILDER_HOST, '_blank');
        changeScreen('report_builder');
    }    

    const dashboard = () => {
        return (
            <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
                <Box className={`listagem-headerr d-flex flex-row p-25`}>
                <Box className={`listagem-header`}>

                    <strong>Modo de Criação</strong>
                    </Box>
                    {/* <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
                        <Button onClick={() => { setOpenForm(0); }} className={`cancel-btn m-1`} >
                            Cancelar
                        </Button>
                    </Box> */}
                </Box>
                <Box className={`listagem-tabela d-flex flex-row h-100 p-5`}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Item onClick={()=> changeScreen('form_builder')}>
                                <Button className={`listagem-tabela d-flex flex-row w-100 h-100 p-5 black`}>Criar Modelo de Formulário</Button>
                            </Item>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Item onClick={handleViewReportBuilder}>
                                <Button className={`listagem-tabela d-flex flex-row w-100 h-100 p-5 black`}>Gerar Relatórios</Button>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Row >)
    }

    const start = () => {
        switch (openForm) {
            case 0:
                return dashboard();
            default:
                break;
        }
    }

    return (
        // handleFormMenus(),
        start()
    );
}