import React, { useState, useEffect } from "react";

import api from "../../services/api";
import createErrorMessage from "../../utils/createErrorMessage"
import Myframe from "../Myframe";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { Col, Row } from "react-bootstrap";

import DATATABLE from "../../components/DataTable/dataTable";
import Actions from "../../components/Actions";
import validator from "../../services/validator.service";
import ConfirmBox from "../../components/ConfirmBox";
import StringMessage from "../../utils/string-messages";
import AddButton from "../../components/AddButton";

const validation = new validator();
const userApiService = new api();

const columns = [
  {
    label: "#",
    field: "num",
    sort: "desc",
    width: 10,
  },
  {
    label: "Províncias",
    field: "nome",
    sort: "desc",
    width: 300,
  },
  {
    label: "Sigla",
    field: "sigla",
    sort: "desc",
    width: 300,
  },
  {
    label: "Acções",
    field: "action",
    sort: "desc",
    width: 200,
  },
];

export default function Provincias(props) {
  const [formMenu, setFormMenu] = useState(false);
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState("Formulário");
  const [openForm, setOpenForm] = useState();
  const [codigo, setCodigo] = useState();
  const [nome, setNome] = useState();
  const [sigla, setSigla] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [cancel, setCancel] = useState("")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

  useEffect(function () {
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, []);

  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  hideSpinner();

  const showConfirmBox = () => {
    var nome = "Provincia?";
    return showConfirm ? (
      <ConfirmBox
        msg={StringMessage.message2 + ` ${nome}`}
        handleResult={deleteData}
      />
    ) : null;
  };

  const handleDelete = (obj) => {
    //console.log("pronto");
    setCodigo(obj?.codigo);
    setShowConfirm(true);
  };

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    if (answer) {
      let result = await userApiService.deleteAny("provinces", codigo);
      if (result?.success) {
        loadTable();
        setOpenForm(false);
        showMsg.success(StringMessage.messageRemove);
      } else {
        showMsg.error(
          result?.exception?.msg 
        );
      }
    }
    setShowConfirm(false);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const saveForm = async () => {
    setOpenSpinner(true);
    const prov = { codigo: codigo, nome: nome, sigla: sigla };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        { value: sigla, label: "Sigla", type: "required" },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.saveProvince(prov);
    if (result?.success) {
      setCodigo("");
      setNome("");
      setSigla("");
      loadTable();
      setOpenForm(false);
      showMsg.success(StringMessage.messageSave);
    } else {
      showMsg.error(createErrorMessage(result));
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const updateForm = async () => {
    setOpenSpinner(true);
    const prov = { codigo: codigo, nome: nome, sigla: sigla };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        { value: sigla, label: "Sigla", type: "required" },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateProvince(prov);
    let message = StringMessage.messageEditar;
    if (result?.success) {
      setCodigo("");
      setNome("");
      setSigla("");
      loadTable();
      setOpenForm(false);
      showMsg.success(message);
    } else {
      showMsg.error(result?.exception?.msg);
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const handleAdd = () => {
    setData();
    setCancel("Cancelar")
    setOpenForm(true);
    setEditButtonVisible("none");
    setSaveButtonVisible("flex");
  };

  const handleEdit = (prov) => {
    setData(prov);
    setOpenForm(true);
    setCancel("Cancelar")
    setSaveButtonVisible("none");
    setEditButtonVisible("flex");
  };
  const setData = (prov = null) => {
    setCodigo(prov?.codigo);
    setNome(prov?.nome);
    setSigla(prov?.sigla);
    setOpenForm(true);
  };

  const loadTable = async () => {
    let list = ((await userApiService.getProvinces())?? []).reverse();
    let rows = [];
    let count = 1;

    if (list) {
      Object.values(list).map((l) => {
        rows.push({
          num: count++,
          nome: l.nome,
          sigla: l.sigla,
          action: (
            <Actions
              page='provincias'
              value={l}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ),
        });
      });
      setList({ columns: columns, rows: rows });
    }
  };

  const handleTitle = (params) => {
    const {
      title = "Provícncias",
      subtitle = "Cadastradas",
      mapButton = false,
    } = params ? params : {};
    return (
      <Box className={`forms-title-container position-relative`}>
        <h6 className={`forms-title`}>{title}</h6>
        <label className={`forms-sub-title`}>{subtitle}</label>
      </Box>
    );
  };

  const start = () => {
    return openForm ? showForm() : handleFormMenus();
  };

  const handleFormMenus = () => {
    return (
      <Box
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
      >
        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>PROVÍNCIAS</strong>&nbsp;CADASTRADAS
          </Box>
          <div className="allbtns">
          <AddButton
                    page='provincias'  
                    handleAdd={handleAdd}
                    dataTip={'Adicionar Provincias'}
                    dataFor={'adicionar'}
                    addText={'Adicionar'}/>
          </div>
        </Box>

        {iframeUrl ? (
          <Box
            className={`form-data d--flex flex-column h-100 w-100 m-0`}
          >
            {handleTitle({
              title: iframeTitle,
              subtitle: "Preenchimento",
              mapButton: true,
            })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
        ) : (
          <>
            <Box className={`listagem-tabela flex-fill d-12`}>
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        )}
      </Box>
    );
  };

  const showForm = () => {
    return (
      <Row
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
      >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>CADASTRAR</strong>&nbsp;NOVA PROVÍNCIA
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
            <div className="allbtns">
              <Button
                onClick={() => setOpenForm(false)}
                className={`cancel-btn m-1`}
              >
                {cancel}
              </Button>
              <Button
                onClick={saveForm}
                className={`right-btn m-1`}
                style={{ display: saveButtonVisible }}
              >
                Salvar
              </Button>
              <Button
                onClick={updateForm}
                className={`right-btn m-1`}
                style={{ display: editButtonVisible }}
              >
                Actualizar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-row h-100 p-5`}>
          <Col sm={12} className={`d-flex flex-column d-6 `}>
            <Col sm={12} className={`d-flex flex-row d-6 p-1`}>
              <Col
                sm={6}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Nome:</label>
                <Input
                  type="select"
                  label="nome"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  //onKeyPress={keyPressed}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </Col>

              <Col
                sm={6}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Sigla:</label>
                <Input
                  type="text"
                  label="sigla"
                  disableUnderline={true}
                  value={sigla}
                  onChange={(e) => setSigla(e.target.value)}
                  //onKeyPress={keyPressed}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
          </Col>
        </Box>
      </Row>
    );
  };

  return start();
}
