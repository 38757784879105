import React, { useState, useEffect } from 'react';
import api from '../../services/api'
import ChangePassword from '../ChangePassword';
import { Box } from "@mui/system";
import CARDS from '../../components/HomeCards/cards'
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts'

const userApiService = new api();
export default function Dashboard(props) {
    const [user, setUser] = useState(true);
    const [pending, SetPending] = useState(0)
    const [invalidate, SetInvalidate] = useState(0)
    const [openInquiries, SetOpenInquiries] = useState(0)
    const [validate, SetValidate] = useState(0)
    const [externalEntity, SetExternalEntity] = useState(0)
    const [seriePie, setSeriePie] = useState([])
    const [series, setSeries] = useState([]);
    const [seriesSuperior, setSeriesSuperior] = useState([])
    const [hasReportedData, setHasReportedData] = useState(false)
    const [pieOptions, setPieOptions] = useState({})
    const [barOptions, setBarOptions] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const { hideAlertShow, changeScreen, openMap, showMsg } = props;

    useEffect(async () => {
      try{
        var loggedUser = await userApiService.getUserData();
        let dataGraphPie = await userApiService.getGraphsPieDataSuperior(loggedUser?.entidade?.codigo) || []
        setHasReportedData(Object.values(dataGraphPie).length)
        // //console.log('DADOS RECEBIDOS DAS ENTIDADES QUE REPORTAM', Object.values(dataGraphPie))
        const barOptions =  {
            // title: {
            //     text: "Estado dos Dados administrativos por dia",
            // },
            chart: {
                height: 0,
                type: 'line'
            },
            plotOptions: {
                bar: {
                    barHeight: '0%'
                }
            },
            colors: ['#ff9100', '#07a82c', '#ff1100'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Pendentes', 'Validados', 'Invalidados',],
                markers: {
                    fillColors: ['#ff9100', '#07a82c', '#ff1100']
                }
            }
        }

        var keys = Object.keys(dataGraphPie).map((key) => `${key[0].toUpperCase()}${key.slice(1)}`)
        const pieOptions = {
            labels: keys,
            series: Object.values(dataGraphPie),
            chart: {
                width: 380,
                type: 'pie',
            },
            colors: ['#ff1100', '#ff9100', '#07a82c'],
            dataLabels: {
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex]
                },
            },
        };
        // //console.log('grafiiiicccc', pieOptions)
        setBarOptions(barOptions)
        setPieOptions(pieOptions)
        let indicators = await userApiService.getIndicatorsData(loggedUser?.entidade?.codigo) || []
        let dataGraph = await userApiService.getGraphsData(loggedUser?.entidade?.codigo) || []
        let dataGraphEntitySuperior = await userApiService.getGraphsDataSuperior(loggedUser?.entidade?.codigo) || []
        // //console.log("INDICATORS", indicators)
        if (indicators) {
            SetPending(indicators?.adminData?.pendentes || 0)
            SetInvalidate(indicators?.adminData?.invalidados || 0)
            SetValidate(indicators?.adminData?.validados || 0)
            SetOpenInquiries(indicators?.inquiries?.abertos || 0)
            SetExternalEntity(indicators?.entities?.externas || 0)
        }

        let mappedSeries = [{
            name: 'Pendentes',
            data: []
        },
        {
            name: 'Validados',
            data: []
        },
        {
            name: 'Invalidados',
            data: []
        }]
        let mappedSeriesSuperior = [{
            name: 'Pendentes',
            data: []
        },
        {
            name: 'Validados',
            data: []
        },
        {
            name: 'Invalidados',
            data: []
        }]

        // //console.log('GRAFICO DATA dataGraph', Object.values(dataGraph))

        var mapDataCriados = Object.values(dataGraph).map(item => {
            let mapped = {
                x: item?.created_at,
                y: item?.pendentes,
            }

            return mapped
        })

        var mapDataValidados = Object.values(dataGraph).map(item => {
            let mapped = {
                x: item?.created_at,
                y: item?.validados,
            }

            return mapped
        })

        var mapDataInvalidados = Object.values(dataGraph).map(item => {
            let mapped = {
                x: item?.created_at,
                y: item?.invalidados,
            }

            return mapped
        })

        mappedSeries[0].data = mapDataCriados;
        mappedSeries[1].data = mapDataValidados;
        mappedSeries[2].data = mapDataInvalidados;
        // //console.log('GRAFICO DATA', mappedSeries)

        var mapDataCriadosEntitySuperior = Object.values(dataGraphEntitySuperior).map(item => {
            let mapped = {
                x: item?.created_at,
                y: item?.pendentes,
            }

            return mapped
        })

        var mapDataValidadosEntitySuperior = Object.values(dataGraphEntitySuperior).map(item => {
            let mapped = {
                x: item?.created_at,
                y: item?.validados,
            }

            return mapped
        })

        var mapDataInvalidadosEntitySuperior = Object.values(dataGraphEntitySuperior).map(item => {
            let mapped = {
                x: item?.created_at,
                y: item?.invalidados,
            }

            return mapped
        })

        mappedSeriesSuperior[0].data = mapDataCriadosEntitySuperior;
        mappedSeriesSuperior[1].data = mapDataValidadosEntitySuperior;
        mappedSeriesSuperior[2].data = mapDataInvalidadosEntitySuperior;

        setSeries(mappedSeries)
        setSeriesSuperior(mappedSeriesSuperior)
        let _user = await userApiService.getUserData();
        // //console.log('LOGGED IN USER', _user)
        setUser(_user);

        setSeriePie(Object.values(dataGraphPie))
      }catch(err){
        
      }
    }, []);

    const showConfirmBox = () => {
        return (
            showConfirm ? <ChangePassword /> : null
        );
    }

    const handleCards = () => {
        return (
            <>
                <br />

                <CARDS
                    pending={pending}
                    validate={validate}
                    externalEntity={externalEntity}
                    invalidate={invalidate}
                    openInquiries={openInquiries === 'undefined'?'0':openInquiries}
                />
                <Box id="chart" className={`minha-conta-container ReactApexChartContainer m-4 mb-0 pb-2`}>
                {/* <Box className={`ReactApexChartContainer mb-0 pb-2`}> */}
                    <h3 className='p-25 pt-25'>Histórico dos Dados Admistrativos por estado</h3>
                    <ReactApexChart
                        className={`listagem-tabela`}
                        options={barOptions}
                        series={series}
                        type="bar"
                        width={"100%"}
                        height={300}
                    />
                </Box>
                {
                    !hasReportedData
                    ? null
                    : <div className='row m-4 mt-2 mb-5'>
                        <Box className={`minha-conta-container  col-md-6 mb-4`}>
                            <h3 className='p-25'>Dados Admistrativos Recebidos </h3>
                            <div className='col'>
                                <Chart
                                    options={pieOptions}
                                    series={seriePie}
                                    type="pie"
                                    width={"100%"}
                                    height={300} />
                            </div>
                        </Box>
                        <Box className={`minha-conta-container col-md-6 mb-4`}>
                            <div className='col'>
                                <ReactApexChart
                                    className={`listagem-tabela`}
                                    options={barOptions}
                                    series={seriesSuperior}
                                    type="bar"
                                    width={"100%"}
                                    height={300}
                                />
                            </div>
                        </Box>
                    </div>
                }


            </>
        );
    }

    return (<div>
        {showConfirmBox()}
        {handleCards()}
    </div>)
}
