import React, {
  useState,
  useEffect,
} from 'react';
import MenuSharp from '@material-ui/icons/MenuSharp';
import logoNormal from '../../assets/images/SNIEA.png';
import logoWhite from '../../assets/images/horizontal_fundo_oscurohdpi.png';
import grafismo from '../../assets/images/banner-top.png';
// import perfil_foto from '../../assets/images/perfil_photo.png';
import { logout, addFormData, getFormData, isMapOpen } from '../../services/database.service'
import { formBuilderApiService } from '../../services/form_builder_api'
import { userApiService } from '../../services/api'
import { getFormList } from '../../services/database.service'
import { makeStyles } from '@mui/styles';
import style from '../../themes/styles/geral';
import icones from '../../themes/icones';
import Box from "@material-ui/core/Box";
import { Col, Row } from 'react-bootstrap';
import fundo_base_vertical from '../../assets/images/fundo_base-vertical.png';
import s_image from '../../assets/images/s30.png';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import NomearRegisto from '../../pages/NomeRegisto/NomearRegisto';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SubbarMenu from './subMenu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  getPolygon
}
from '../../services/database.service'
import api from "../../services/api";
import { permissionsMapped } from "../../utils/permissionsMapped";
import { removeFormBuilderCss } from "../../utils/events";
import { ToastContainer, toast } from "react-toastify";

const useStyle = makeStyles(style);

export default function TopbarMenu(props) {
  const classes = useStyle();
  const [formSubmit, setFormSubmit] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(Boolean(anchorEl));
  const [ menuItems, setMenuItems ] = useState([{isOpen: false}, {isOpen: false}]);
  const { handleOpen, changeScreen, showMsg, setLogged, designMode, setDesignMode, user } = props;
  let internetConection = window.navigator?.onLine || false;
  const userApiService = new api();

  useEffect(function () {
  }, [])

  const handleOpenMenu = (event, index) => {
    const anchor = event.currentTarget ?? event.target;
    var _menuItems = menuItems;
    _menuItems.forEach((el) => {
      el.isOpen = false;
    });
    _menuItems[index].isOpen = true;
    setMenuItems(_menuItems);
    setAnchorEl(anchor);
  };

  const handleClose = () => {
    var _menuItems = menuItems;
    _menuItems.forEach((el) => {
      el.isOpen = false;
    });
    setMenuItems(_menuItems);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    setLogged(null);
    handleClose();
  }

  const notify = () =>
    toast.error("Nao Tens Permissão Para Acessar esta Pagina!");


  const handlePageChange = async (fn, action) => {
    var loggedUser = await userApiService.getUserData();
    var pagePerm = permissionsMapped[action];
    loggedUser.allPermissionsMapped = localStorage.getItem('allPermissionsMapped').split(',')

    // var hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
    //   return pagePerm === item;
    // });


    var hasPermission = null;
    if (loggedUser?.codigo == 1){

      hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
        return pagePerm === item;
      });

    }else{

      hasPermission = loggedUser?.permissions?.filter((item) => {
        return pagePerm === item.nome;
      });
    }

    const permissionLength = hasPermission?.length;
    if (!permissionLength > 0) {
      notify();
      return;
    }

    removeFormBuilderCss()
    fn(action);
  };



  const handleShow = () => {
    // changeScreen(' subbar_menu');
    // handleClose();
  }

  const handleModoDesenho = () => {
    const customEvent = new CustomEvent("removeBootstrap", { detail: "" })
    document.dispatchEvent(customEvent)
    changeScreen(designMode ? "home" : "modo_design");
    setDesignMode(!designMode);
    handleClose();
  }

  const handleViewFormBuilder = () => {
    changeScreen('form_builder');
    handleClose();
  }

  const handleViewReportBuilder = () => {
    // window.open(process.env.REACT_APP_REPORT_BUILDER_HOST, '_blank');
    changeScreen('report_builder');
    handleClose();
  }

  const handleViewProfile = () => {
    changeScreen('minha_conta');
    handleClose();
  }

  return (
    <>
      <img className={`menu-logo`} src={designMode ? logoWhite : logoNormal} />
      <Box className={`${classes.userContainer} h-100 d-flex flex-row-reverse d-none d-md-block m-0 p-0 position-absolute end-0`} >
        <img className={`menu-logo2 position-absolute start-0 h-100 p-0`} src={grafismo} />
        <Box className={`position-absolute ww-auto d-flex end-0 h-100 p-0 pr-2 `}>
          {
            designMode &&
              <div>
                <Button
                  onClick={(el) => handleOpenMenu(el, 0)} className={`menu-btn h-100 p-0 `}
                  style={{ marginRight: '20px' }}
                >
                  <DesignServicesRoundedIcon />
                  {/* <img
                    onClick={(el) => handleOpenMenu(el, 0)}
                    className={`${classes.menuLogo234} h-100 p-2`}
                    src={icones.desenhar}
                    alt="perfilICon"
                    aria-controls="demo-customized-menu"
                    aria-haspopup="true"
                    aria-expanded={menuItems[0].isOpen ? 'true' : undefined}
                    disableElevation
                  /> */}
                  Modo de Criação
                  <ArrowDropDownIcon className={"mr-2"}/>
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  aria-expanded={menuItems[0].isOpen ? 'true' : undefined}
                  open={menuItems[0].isOpen}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleViewFormBuilder} disableRipple>
                    <PersonIcon />
                    Modelo de formulários
                  </MenuItem>
                  <MenuItem onClick={handleViewReportBuilder} disableRipple>
                    <SummarizeIcon />
                    Relatórios
                  </MenuItem>
                  <MenuItem onClick={handleModoDesenho} disableRipple>
                    <LogoutIcon />
                    Sair
                  </MenuItem>
                </Menu>
              </div>
          }
          <div>
            <Button onClick={(el) => handleOpenMenu(el, 1)} className={`menu-btn h-100 mr-2`}
              style={{ marginRight: '1px', paddingRight: '5px' }}
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={isOpen ? 'true' : undefined}
            >
              {user?.nome}&nbsp;
              {
                <img className={`${classes.menuLogo23} ${classes.topMenuLogo} h-100 mr-0`}
                  src={user?.profile_pic}
                  alt="perfilICon"
                  aria-controls="demo-customized-menu"
                  aria-haspopup="true"
                  aria-expanded={menuItems[1].isOpen ? 'true' : undefined}
                  disableElevation
                  endIcon={<KeyboardArrowDownIcon />}
                />
              }
              <ArrowDropDownIcon className={"mr-2"}/>
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={menuItems[1].isOpen}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handlePageChange(handleViewProfile, "minha_conta")} disableRipple>
                <PersonIcon />
                Minha Conta
              </MenuItem>
              <MenuItem onClick={handleModoDesenho} disableRipple>
                <BuildIcon />
                Modo de Criação
              </MenuItem>
              <MenuItem onClick={handleShow} disableRipple>
              <ArrowBackIosNewIcon /> Configuracões
                <SubbarMenu   {...props}/>
              </MenuItem>

              <MenuItem onClick={handleLogout} disableRipple>
                <LogoutIcon />
                Terminar sessão
              </MenuItem>

            </Menu>
          </div>
        </Box>
      </Box>
      <Button onClick={handleOpen} className={`${classes.menuButtonRight} h-100 position-absolute end-0 p-0 d-md-none`} >
        <MenuSharp />
      </Button>
    </>
  );
};
