import React, { useState, useEffect } from "react";

import api from "../../services/api";
import createErrorMessage from "../../utils/createErrorMessage"
import { makeStyles } from "@mui/styles";
import Myframe from "../Myframe";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { Col, Row } from "react-bootstrap";
import SelectLabels from "../../components/SelectLabels";
import DATATABLE from "../../components/DataTable/dataTable"
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InquiryActions from "../../components/InquiryActions";
import SimpleModal from "../../components/Modal/index.js"
import EndInquiryModal from "../../components/Modal/index2.js"
import validator from "../../services/validator.service";
import ConfirmBox from "../../components/ConfirmBox";
import { formatDate } from "../../utils/formatDate";
import { capFirstLetter } from "../../utils/string";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import "react-dual-listbox/lib/react-dual-listbox.css";
import StringMessage from '../../utils/string-messages'
import { jsPDF } from "jspdf";
import angola_logo from '../../assets/images/angola.png';
import gepeImage from '../../assets/images/combine_images.png'
import Grid from '@mui/material/Grid';
import { formatDateInput } from "../../utils/formatDate";
import AddButton from "../../components/AddButton";
import ReactTooltip from 'react-tooltip';
import DualBoxList from "../../components/DualBoxList";

const status = {
  PENDING: 0,
  STARTED: 1,
  FINISHED: 2
}

const statusMessage = {
  0: "Pendente",
  1: "Em progresso",
  2: "Terminado"
}

const userApiService = new api();
const columns = [
  {
    label: "#",
    field: "num",
    sort: "asc",
    width: 10,
  },
  {
    label: "Inquéritos",
    field: "nome",
    sort: "asc",
    width: 200,
  },
  {
    label: "Época",
    field: "epoca",
    sort: "asc",
    width: 200,
  },
  {
    label: "Início previsto",
    field: "inicio",
    sort: "asc",
    width: 200,
  },
  {
    label: "Término Previsto",
    field: "termino",
    sort: "asc",
    width: 200,
  },
  {
    label: "Questionários",
    field: "formularios",
    sort: "asc",
    width: 200,
  },
  {
    label: "Data de Início",
    field: "data_de_comeco",
    sort: "asc",
    width: 200,
  },
  {
    label: "Data de Término",
    field: "data_do_fim",
    sort: "asc",
    width: 300,
  },
  {
    label: "Amostra",
    field: "sample",
    sort: "asc",
    width: 100,
  },
  {
    label: "Estado",
    field: "status",
    sort: "asc",
    width: 200,
  },
  {
    label: "Acções",
    field: "action",
    sort: "asc",
    width: 200,
  },
];

const validation = new validator();

export default function Inqueritos(props) {
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState("Formulários");
  const [openForm, setOpenForm] = useState(0);
  const [codigo, setCodigo] = useState("");
  const [nome, setNome] = useState("");
  const [epoca, setEpoca] = useState("");
  const [acesso, setAcesso] = useState("");
  const [inicio, setInicio] = useState("");
  const [dataComeco, setDataComeco] = useState("");
  const [sample, setSample] = useState("");
  const [dataFim, setDataDoFim] = useState();
  const [termino, setTermino] = useState("");
  const [observacao, setObservacao] = useState("");
  const [entity, setEntity] = useState();
  const [user, setUser] = useState()
  const [listaEpocas, setListaEpocas] = useState([]);
  const [listaFormularios, setListFormularios] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [formularioOptions, setFormularioOptions] = useState([]);
  const [qrcode, setQrcode] = useState("");
  const [selectedFormularios, setSelectedFormularios] = useState();
  const [editButtonVisible, setEditButtonVisible] = useState("");
  const [saveButtonVisible, setSaveButtonVisible] = useState("");
  const [back, setBack] = useState("")
  const [cancel, setCancel] = useState("")
  const [inqEditButtonVisible, setInqEdit] = useState("")
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

  useEffect(async function () {
    ReactTooltip.rebuild()
    setOpenSpinner(true);
    validation.setShowMsg(showMsg.error);
    loadTable();
    var loggedUser = await userApiService.getUserData();
    setUser(loggedUser?.codigo)
    setEntity(loggedUser?.entidade?.codigo)
    //console.log('USERENTITY', loggedUser?.entidade?.codigo)
    let list = ((await userApiService.getEpocas()) ?? []);
    let formsList = await userApiService.getForms() || [];

    permissionHandle(list, 'Epocas')
    permissionHandle(formsList, 'Questionarios')

    if (Array.isArray(formsList)) {
      formsList = formsList.filter((form) => form.tipo === "questionario" || !form.tipo)
    } else {
      formsList = []
    }

    if (Array.isArray(list)) {
      list = list.map((item) => {
        return { codigo: item.codigo, nome: item.nome };
      });
    } else {
      list = []
    }

    // formsList = formsList.map((item) => {
    //   return { value: item.name, label: item?.nome?.toString().split('.')[0] }
    // })

    if (Array.isArray(formsList)) {
      formsList = formsList.map((item) => {
        return { value: item.codigo, label: item.nome };
      });
    } else {
      formsList = []
    }

    setListaEpocas(list);
    setFormularioOptions(formsList);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, []);

  const permissionHandle = (response, permission) => {
    if (response.status === 403) {
      showMsg.error(response.message + ' ' + permission)
    }
  }

  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  hideSpinner();

  const setData = (inq = null) => {
    let inicio = null;
    let termino = null;

    if (inq) {
      //console.log(inq)
      inicio = new Date(inq?.inicio)?.toISOString()?.substr(0, 10);
      termino = new Date(inq?.termino)?.toISOString()?.substr(0, 10);
    }

    setCodigo(inq?.codigo);
    setQrcode(inq?.qrcode);
    setNome(inq?.nome);
    setEpoca(inq?.epoca);
    setAcesso(inq?.acesso);
    setInicio(inicio);
    setTermino(termino);
    setSample(inq?.sample)
    setObservacao(inq?.observacao);
    setDataComeco(formatDate(inq?.data_de_comeco))
    setDataDoFim(formatDate(inq?.data_do_fim))
    const formsF = (inq?.formularios || []).map((form) => {
      form.count = 0;
      return form
    });

    setListFormularios(formsF);
    setSelectedFormularios(inq?.formularios?.map((item) => item.codigo));
  };

  const onFormularioSelected = (selected) => {
    setSelectedFormularios(selected);
  };

  const handleAdd = () => {
    setData();
    setOpenForm(1);
    setCancel("Cancelar")
    setEditButtonVisible('none')
    setSaveButtonVisible('flex')
  };

  const handleShow = async (inquerito) => {
    setData(inquerito);
    setCancel("Cancelar")
    setSaveButtonVisible('none')
    const data = userApiService.getElasticsearchInquiryData({
      codigo: inquerito?.codigo
    }).then((response) => {
      if (!response) {
        return;
      }
      //console.log("DATA - handleShow", response);
      const formsF = (inquerito?.formularios || []).map((form) => {
        const lowerCaseName = form?.modelo?.toLowerCase()?.split(".html")[0].replace(" ", "");
        const foundItem = response[lowerCaseName];
        //console.log("lowerCaseName", lowerCaseName);
        const findItemsCount = (foundItem ? foundItem.length : 0);
        //console.log("findItem", foundItem);
        form.count = findItemsCount;
        return form
      });
      setListFormularios(formsF);
    })
    setOpenForm(2);
    setBack("Voltar")
  };

  const handleEditIqt = (inquerito) => {
    setData(inquerito);
    setOpenForm(3);
    setCancel("Cancelar")
    setInqEdit('none')
    setSaveButtonVisible('none')
    setEditButtonVisible('flex')

  };

  const handleStartInquiry = (inquerito) => {
    setData(inquerito);
    setOpenForm(4)
    setCancel("Cancelar")
  }

  const handleEndInquiry = (inquerito) => {
    setData(inquerito);
    setOpenForm(5)
    setCancel("Cancelar")
  }
  const handleDeleteIqt = (obj) => {
    setCodigo(obj?.codigo);
    setShowConfirm(true);
  };

  const handleCancel = (evt) => {
    setData();
    setSelectedFormularios();
    handleStartInquiry()
    setOpenForm(0);
  };

  const showConfirmBox = () => {
    var nome = 'Inquérito?'
    return (
      showConfirm ? <ConfirmBox msg={StringMessage.message + ` ${nome}`} handleResult={deleteData} /> : null
    );
  }

  const deleteData = async (answer) => {
    setOpenSpinner(true);
    setTimeout(() => {
      setOpenSpinner(false);
    }, 2 * 1000);
    if (answer) {
      let result = await userApiService.deleteInquiries(codigo);
      if (result) {
        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessage.messageRemove);
      } else {
        //console.log('DELETE NOT WORKING')
        showMsg.error(
          result?.exception?.msg
        );
      }
    }
    setShowConfirm(false);
    let timer = setTimeout(() => {
      hideAlertShow();

      clearTimeout(timer);
    }, 5 * 1000);
  };

  const saveForm = async () => {
    setOpenSpinner(true);
    setCancel("Cancelar")
    setDataComeco(null)
    setDataDoFim(null)
    ////console.log("saveForm / epoca", epoca);

    const rowData = {
      codigo: codigo,
      nome: nome,
      epoca: epoca?.codigo,
      observacao: observacao,
      inicio: inicio,
      termino: termino,
      status: status.PENDING,
      formularios: selectedFormularios,
      entity: entity,
      user: user
    };

    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        { value: epoca, label: "Época" },
        { value: inicio, label: "Data de Inicio Prevista", type: "required" },
        { value: termino, label: "Data de Termino Prevista", type: "required" },
        {
          value: termino,
          label: "Data de Termino Prevista",
          value2: inicio,
          label2: "Data de Inicio Prevista",
          type: "after_or_equal",
        },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }


    let result = await userApiService.saveInquerito(rowData);
    //console.log("result", result);
    //console.log("estou aqui mano vamos que vamos")
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      setSelectedFormularios();
      showMsg.success(StringMessage.messageSave);
    } else {
      showMsg.error(
        createErrorMessage(result)
      );
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };

  const updateForm = async () => {
    setOpenSpinner(true);
    setCancel("Cancelar")
    //console.log("saveForm / epoca", epoca);
    const rowData = {
      codigo: codigo,
      nome: nome,
      epoca: epoca?.codigo,
      observacao: observacao,
      inicio: inicio,
      termino: termino,
      formularios: selectedFormularios,
      entity: entity,
      user: user
    };


    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    if (
      !validation.handleValidation([
        { value: nome, label: "Nome", type: "required" },
        { value: epoca, label: "Época" },
        { value: inicio, label: "Inicio", type: "required" },
        { value: termino, label: "Termino", type: "required" },
        {
          value: termino,
          label: "Termino",
          value2: inicio,
          label2: "Inicio",
          type: "after_or_equal",
        },
      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.updateInquerito(rowData);
    let message = StringMessage.messageEditar
    //console.log("result", result);
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      setSelectedFormularios();
      showMsg.success(message);
    } else {
      showMsg.error(
        result?.exception?.msg
      );
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  };
  const inquiryStart = async () => {
    const rowData = {
      codigo: codigo,
      data_de_comeco: formatDateInput(new Date()),
      sample: sample
    };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
    if (

      !validation.handleValidation([


        { value: sample, label: "Amostra", type: "required" }

      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.startInquiry(rowData);
    let message = StringMessage.startInquiry
    //console.log("result", result);
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      setSelectedFormularios();
      showMsg.success(message);
    } else {
      showMsg.error(
        result?.exception?.msg
      );
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }
  const endStart = async () => {
    const rowData = {
      codigo: codigo,
      data_do_fim: formatDateInput(new Date())
    };
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);
    if (
      !validation.handleValidation([

        { value: dataFim, label: "Data de término", type: "required" }

      ])
    ) {
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
      return false;
    }

    let result = await userApiService.endInquiry(rowData);
    let message = StringMessage.endInquiry
    //console.log("result", result);
    if (result?.success) {
      setData();
      loadTable();
      setOpenForm(0);
      setSelectedFormularios();
      showMsg.success(message);
    } else {
      showMsg.error(
        result?.exception?.msg
      );
    }
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }
  const allowNullDate = (data) => {
    return data === formatDate(new Date('1970-01-01T00:00:00.000Z')) ? '' : data
  }
  const loadTable = async () => {
    let list = ((await userApiService.getInqueritos()) ?? []).reverse();
    let rows = [];
    let defaultDate = ("01-1970-01-01T00:00:00.000Z")
    //console.log('DATE', Date.parse(defaultDate))
    if (list) {
      Object.values(list).map((l) => {
        rows.push({
          num: l.codigo,
          nome: l.nome,
          epoca: l.epoca.nome,
          inicio: formatDate(l.inicio),
          termino: formatDate(l.termino),
          data_de_comeco: allowNullDate(formatDate(l.data_de_comeco)),
          data_do_fim: allowNullDate(formatDate(l.data_do_fim)),
          formularios: l?.formularios?.length || 0,
          sample: l?.sample,
          status: statusMessage[l.status] ?? "Inválido",
          action: (
            <InquiryActions
              value={l}
              page='inquiries'
              handleView={handleShow}
              handleEdit={handleEditIqt}
              handleDelete={handleDeleteIqt}
              handleStart={handleStartInquiry}
              handleEnd={handleEndInquiry}
              status={l.status}
            />
          ),
        });
      });
      setList({ columns: columns, rows: rows });
    }
  };

  const handleTitle = (params) => {
    const {
      title = "Inquéritos",
      subtitle = "Cadastrados",
      mapButton = false,
    } = params ? params : {};
    return (
      <Box className={`forms-title-container position-relative`}>
        <h6 className={`forms-title`}>{title}</h6>
        <label className={`forms-sub-title`}>{subtitle}</label>
      </Box>
    );
  };

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return editForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      case 4:
        return startInquiry()
      case 5:
        return endInquiry();
      default:
        break;
    }
  };

  const handleFormMenus = () => {
    return (
      <Box
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}

      >

        {showConfirmBox()}
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header`}>
            <strong>Inquéritos</strong>&nbsp;CADASTRADOS
          </Box>
          <div className="allbtns">
            <AddButton
              page='inquiries'
              handleAdd={handleAdd}
              dataTip={'Adicionar Inquerito'}
              dataFor={'adicionar'}
              addText={'Adicionar'} />
          </div>
        </Box>

        {iframeUrl ? (
          <Box
            className={`form-data d--flex flex-column h-100 w-100 m-0`}
          >
            {handleTitle({
              title: iframeTitle,
              subtitle: "Preenchimento",
              mapButton: true,
            })}
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Box>
        ) : (
          <>
            <Box className={`listagem-tabela flex-fill d-12`}>
              <DATATABLE
                className={`form-list flex-fill d-12`}
                data={list}
              />
            </Box>
          </>
        )}
      </Box>
    );
  };



  const downloadQRCode = () => {
    var imgData = qrcode;
    var alternativeAcess = acesso === null ? '' : acesso
    var season = epoca === null ? '' : epoca
    var doc = new jsPDF();

    doc.addImage(angola_logo, "PNG", 95, 10, 20, 20, 'MEDIUM')
    doc.setFont("arial", "bold")
    doc.setFontSize(10)

    doc.text(80, 40, ' REPÚBLICA DE ANGOLA')
    doc.text(70, 45, ' MINISTÉRIO DA AGRICULTURA E PESCAS')
    doc.text(60, 50, ' GABINETE DE ESTUDOS E PLANEAMENTO ESTATÍSTICO')
    doc.text(95, 77, `${capFirstLetter(nome)}`)
    doc.text(95, 82, `${capFirstLetter(season?.nome)}`)
    doc.addImage(imgData, "PNG", 55, 84, 100, 100, null, "FAST");
    doc.text(95, 180, alternativeAcess);
    doc.addImage(gepeImage, "PNG", 70, 259, 80, 25, null, "FAST");
    doc.save(`${capFirstLetter(nome)}.pdf`);
  };

  const showForm = () => {
    return (
      <Row
        className={`listagem position-relative container-fluid w-100 p-3 m-0`}
      >
        <Box className={`listagem-headerr d-flex flex-row p-25`}>
          <Box className={`listagem-header `}>
            <strong>INFORMAÇÕES GERAIS</strong>
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
            <div className="allbtns">
              <Button
                onClick={() => setOpenForm(0)}
                className={`cancel-btn m-1`}
              >
                {back}
              </Button>
              <Button
                style={{ display: inqEditButtonVisible }}
                onClick={() => setOpenForm(3)}
                className={`right-btn m-1`}>
                editar
              </Button>

            </div>
          </Box>
        </Box>
        <Box
          className={`listagem-tabela d-flex flex-column  p-5 pb-3`}
        >
          <Row className={`d-flex flex-row d-6 pb-3`}>
            <Col sm={3} className={`d-flex flex-column d-6 `}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Nome</strong>
                </FormLabel>
                <FormLabel>{capFirstLetter(nome)}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Data de Inicio Prevista</strong>
                </FormLabel>
                <FormLabel>{inicio}</FormLabel>
              </FormControl>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Data de Término Prevista</strong>
                </FormLabel>
                <FormLabel>{termino}</FormLabel>
              </FormControl>
            </Col>
            <Col sm={3} className={`d-flex flex-column d-6 p-3`}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Epoca</strong>
                </FormLabel>
                <FormLabel>{epoca?.nome}</FormLabel>
              </FormControl>
            </Col>
            <Col sm={3} className={`d-flex flex-column d-6 p-3`}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel component="legend">
                  <strong>Observação</strong>
                </FormLabel>
                <FormLabel>{observacao}</FormLabel>
              </FormControl>
            </Col>


            <Col sm={3} className={`d-flex flex-column d-6 p-3`}>
              <FormControl component="fieldset" className="mb-2">
                <FormLabel className="text-center" component="legend">
                  <strong>QR Code</strong>
                </FormLabel>
                <img src={qrcode} alt="qrcode" id="qrCodeEl" />
                <FormLabel className="text-center" component="legend">
                  <strong>Código de acesso:</strong> {acesso}
                </FormLabel>
                <Button
                  className={`right-btn-qr m-1`}
                  onClick={downloadQRCode}
                >Download
                </Button>
              </FormControl>
            </Col>
          </Row>
          <Row className={`d-flex flex-column`}>
            <Col className={`d-flex flex-column`}>
              <FormLabel component="legend">
                <strong>Questionarios</strong>
              </FormLabel>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                {listaFormularios.map((form) => {
                  return (
                    <ListItem key={form.nome}
                      secondaryAction={
                        form.count
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={form.nome}
                        secondary={form.modelo}
                      />
                    </ListItem>
                  );
                })}
              </Grid>
              <Col sm={12} className={`d-flex flex-column d-6 p-3`}>
                <Col sm={12} className={`d-flex flex-row d-6 p-3`}>


                  <Col
                    className={`d-flex flex-row d-3 p-3`}
                  >
                    <FormControl component="fieldset" className="mb-2">
                      <FormLabel component="legend">
                        <strong>Amostra</strong>
                      </FormLabel>
                      <FormLabel>{sample}</FormLabel>
                    </FormControl>
                  </Col>
                  <Col
                    className={`d-flex flex-row d-3 p-3`}
                  >
                    <FormControl component="fieldset" className="mb-2">
                      <FormLabel component="legend">
                        <strong>Data de Início</strong>
                      </FormLabel>
                      <FormLabel>{allowNullDate(dataComeco)}</FormLabel>
                    </FormControl>
                  </Col>
                  <Col
                    className={`d-flex flex-row d-3 p-3`}
                  >
                    <FormControl component="fieldset" className="mb-2">
                      <FormLabel component="legend">
                        <strong>Data de Término</strong>
                      </FormLabel>
                      <FormLabel>{allowNullDate(dataFim)}</FormLabel>
                    </FormControl>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Row>
        </Box>
      </Row>
    );
  };
  const startInquiry = () => {

    return (
      <>
        <SimpleModal
          setSample={setSample}
          value1={sample}
          cancel={handleCancel}
          start={inquiryStart}
          openForm={openForm}
        /></>
    );
  };
  const endInquiry = () => {
    //console.log('EST', dataFim)
    return (

      <>
        <EndInquiryModal
          setDataFim={setDataDoFim}
          cancel={handleCancel}
          endStart={endStart}
          openForm={openForm}
        />
      </>
    );
  };
  const editForm = () => {
    return (
      <Row
        className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`}
      >
        <Box className={`listagem-headerr d-flex flex-md-row flex-sm-column p-25`}>
          <Box className={`listagem-header`}>
            <strong>{`${openForm === 1 ? "CADASTRAR" : "EDITAR"}`}</strong>
            &nbsp;INQUÉRITO
          </Box>
          <div className="allbtns">
            <Button
              onClick={handleCancel}
              className={`cancel-btn m-1`}
            >
              {cancel}
            </Button>
            <Button onClick={saveForm} className={`right-btn m-1`} style={{ display: saveButtonVisible }}>
              Salvar
            </Button>
            <Button onClick={updateForm} className={`right-btn m-1`} style={{ display: editButtonVisible }}>
              Actualizar
            </Button>

          </div>
        </Box>
        <Box
          className={`listagem-tabela d-flex flex-column p-1`}
        >
          <Row className={`d-flex flex-column d-6 p-3`}>
            <Col sm={12} className={`d-flex flex-md-row flex-sm-column d-6 p-3`}>
              <Col
                // sm={4}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Nome:</label>
                <Input
                  type="select"
                  label="nome"
                  disableUnderline={true}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col
                // sm={4}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Época:</label>
                <SelectLabels
                  initialValue={epoca}
                  setValue={setEpoca}
                  lista={listaEpocas}
                  className={`select-labels`}
                />
              </Col>
              <Col
                // sm={2}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Data de Inicio Prevista:</label>
                <Input
                  type="date"
                  label="inicio"
                  disableUnderline={true}
                  value={inicio}
                  onChange={(e) => setInicio(e.target.value)}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </Col>
              <Col
                // sm={2}
                className={`d-flex flex-column  justify-content-start large-font input-container-2 required`}
              >
                <label className={`input-label-23`}>Data de Término Prevista:</label>
                <Input
                  type="date"
                  label="término"
                  disableUnderline={true}
                  value={termino}
                  onChange={(e) => setTermino(e.target.value)}
                  autoCapitalize={"none"}
                  className={`input-2 large-font`}
                />
              </Col>
            </Col>
            {/* <Col sm={12} className={`d-flex flex-row d-6 p-3`}>

          </Col> */}
            <Col sm={12} className={`d-flex flex-row d-6 p-3`}>
              <Col sm={12} className={`d-flex flex-column`}>
                <label className={`input-label-23`}>Questionarios:</label>
                <DualBoxList
                  options={formularioOptions}
                  selected={selectedFormularios}
                  onChange={onFormularioSelected}
                />
              </Col>
            </Col>
            <Col sm={12} className={`d-flex flex-row d-6 p-3`}>
              <Col sm={12} className={`d-flex flex-column`}>
                <label className={`input-label-23`}>Observação:</label>
                <textarea
                  value={observacao}
                  className={`user-img`}
                  onChange={(e) => {
                    setObservacao(e.target.value);
                  }}
                  rows="6"
                />
              </Col>
            </Col>
          </Row>
        </Box>
      </Row>
    );
  };

  return start();
}
