import React, {
    useState,
    useEffect,
} from 'react';

import api from '../../services/api'
import { addFormData } from '../../services/database.service'
import { formBuilderApiService } from '../../services/form_builder_api'
import { resetCurrentPolygon } from '../../services/database.service'
import validator from '../../services/validator.service'
import { getFormList } from '../../services/database.service'
import ConfirmBox from '../../components/ConfirmBox'
import { MDBDataTable } from 'mdbreact';
import Box from "@material-ui/core/Box";
import { Col, Row } from 'react-bootstrap';
import Button from "@material-ui/core/Button";
import Actions from '../../components/Actions';
import Myframe from '../Myframe';
import fundo_base_vertical from '../../assets/images/fundo_base-vertical.png';
import s_image from '../../assets/images/s30.png';

import { databaseService } from '../../services/database.service';

const columns = [
    {
        label: '#',
        field: 'num',
        sort: 'asc',
        width: 10
    },
    {
        label: 'Nome',
        field: 'nome',
        sort: 'asc',
        width: 300
    },
    {
        label: 'Modelo',
        field: 'modelo',
        sort: 'asc',
        width: 300
    },
    {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 300
    },
    {
        label: 'Acções',
        field: 'action',
        sort: 'asc',
        width: 300
    },
];

const userApiService = new api();
const validation = new validator();
export default function ReportBuilder(props) {
    const [iframeTitle, setIframeTitle] = useState('Relatórios');
    const [formPreview, setFormPreview] = useState(false);
    const [currentForm, setCurrentForm] = useState([]);
    const [openForm, setOpenForm] = useState(0);
    const [iframeUrl, setIframeUrl] = useState(false);
    const [formSubmit, setFormSubmit] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const [tableData, setTableData] = useState({});
    const [formsList, setFormsList] = useState([]);
    const [list, setList] = useState([]);
    const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;

    const [loggedUser, setLoggedUser] = useState({});
    const [URLSearchParams, setURLSearchParams] = useState(`${process.env.REACT_APP_REPORT_BUILDER_FRONTEND_HOST}`);

    useEffect(async function () {
        setOpenSpinner(true);

        validation.setShowMsg(showMsg.error);
        loadTable();
        let list = await userApiService.getFormBuilderForms()
        // modeloFormulariosList = modeloFormulariosList.map((item) => {
        //   return { codigo: item.name, nome: item.name }
        // })
        setFormsList(list);
        async function getUserData () {
            const loggedUser = databaseService.isLogged()
            setLoggedUser(loggedUser);
            //console.log("loggedUser Entidade:", loggedUser)
            const entidade = loggedUser?.entidade?.codigo
            setURLSearchParams(`${process.env.REACT_APP_REPORT_BUILDER_FRONTEND_HOST}?filter=${entidade}`)
          }
        setTimeout(async () => {
            setOpenSpinner(false);
            await getUserData();
        }, 1000);
      
    }, [])

    const setData = (form = null) => {
        // setCodigo(form?.codigo);
        // setNome(form?.nome);
        // setModelo(form?.modelo);
        // setObservacao(form?.observacao);
        setOpenForm(3);
    }

    const handleAdd = () => {
        setData();
        setOpenForm(1);
    }

    const handleShow = async (form) => {
        let result = await userApiService.getFullAdress(form.name);
        setData(form)
        setCurrentForm(result);
        setOpenForm(2);
    }

    const handleEdit = (form) => {
        setData(form)
        setOpenForm(3);
    }

    const handleDelete = (obj) => {
        //setCodigo(obj?.codigo)
        setShowConfirm(true)
    }

    const deleteData = async (answer) => {
        // if (answer) {
        //   let result = await userApiService.deleteAny('forms', codigo);
        //   if (result?.success) {
        //     loadTable();
        //     setOpenForm(0);
        //     showMsg.success('Dados removidos com sucesso!')
        //   } else {
        //     showMsg.error(result?.exception?.msg || 'Houve erro ao remover os dados')
        //   }
        // }
        setShowConfirm(false)
    }


    const loadTable = async () => {
        let list = await userApiService.getFormBuilderForms();
        setTableData(list)
        let rows = [];
        let count = 1;
        if (list) {
            Object.values(list).map(item => {
                rows.push({
                    num: count++,
                    nome: item?.name.toString().split('.')[0],
                    model: item?.name,
                    status: item.status || 'Activo',
                    modelo: item.modelo,
                    action: (<Actions value={item} handleView={handleShow} handleEdit={handleEdit} handleDelete={handleDelete} />)
                });
            });

            setList({ columns: columns, rows: rows })
        }
    }

    const hideSpinner = () => {
        let spinningContainer = document.querySelector('#spinningContainer');
        let spinningCurtain = document.querySelector('#spinningCurtain');

        if (!spinningContainer || !spinningCurtain) return false;

        spinningContainer.remove()
        spinningCurtain.remove()
    }

    hideSpinner();

    const handleButtons = (saveButton = false) => {
        return (
            <>
                <Box className={`voltar-button-container w-100 fixed-bottom`} >
                    <Button onClick={() => setIframeUrl(null)} className={`cancel-btn`} >
                        Voltar
                    </Button>
                    {saveButton ? <Button onClick={saveForm} className={`save-btn`} >
                        Salvar
                    </Button> : null}
                </Box>
            </>
        );
    }

    const handleTitle = (params) => {
        const { title = 'Formulários', subtitle = 'Cadastrados', mapButton = false } = params ? params : {};
        return (<Box className={`forms-title-container position-relative`} >

            <h6 className={`forms-title`}>{title}</h6>
            <label className={`forms-sub-title`}>{subtitle}</label>
        </Box>);
    }

    const showConfirmBox = () => {
        return (
            showConfirm ? <ConfirmBox msg={'Deseja remover esta entidade?'} handleResult={deleteData} /> : null
        );
    }

    const handleFormMenus = () => {
        return (

            <Box className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
                <img
                    className={`menu-grafismo-vertical d-none`}
                    src={fundo_base_vertical}
                />
                <img
                    className={`menu-s-vertical d-none`}
                    src={s_image}
                />

                {showConfirmBox()}
                <Box className={`listagem-headerr d-flex flex-row p-25`}>
                <Box className={`listagem-header `}>
                    <strong>MODELO DE FORMULÁRIOS</strong>&nbsp;CADASTRADOS
                    </Box>
                    <div className="allbtns">
                    <Button onClick={handleAdd} className={`right-btn m-1`} >
                        Adicionar
                    </Button>
                    </div>
                </Box>

                {iframeUrl ?
                    <Box className={`form-data d--flex flex-column h-100 w-100 m-0`} >
                        {handleTitle({ title: iframeTitle, subtitle: 'Preenchimento', mapButton: true })}
                        <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
                        {handleButtons(true)}
                    </Box>
                    :
                    <>
                        <Box className={`form-list flex-fill`} >
                            {handleTitle()}
                            <MDBDataTable
                                className={`form-list flex-fill d-12`}
                                striped
                                bordered
                                small
                                data={list}
                            />
                        </Box>
                    </>
                }
            </Box>)
    }


    const editForm = () => {
        return (
          <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
            <Box className={`listagem-headerr d-flex flex-row p-25`}>
            <Box className={`listagem-header `}>
              <strong>CRIAR</strong>&nbsp;NOVO MODELO FORMULÁRIO
              </Box>
              <Box className={` flex-fill d-flex flex-row-reverse p-0`} >
              <div className="allbtns">
                <Button onClick={() => setOpenForm(0)} className={`cancel-btn m-1`} >
                  Cancelar
                </Button>
                </div>
              </Box>
            </Box>
            <Box className={`listagem-tabela d-flex flex-row h-100`}  >
              <iframe src={process.env.REACT_APP_FORM_BUILDER_FRONTEND_HOST} title="formbuild" width="1200" />
            </Box>
          </Row >)
    }
    

    const previewForm = () => {

        //console.log('URLSearchParams:', URLSearchParams)

        return (
            <Row className={`listagem position-relative .container-fluid h-100 w-100 p-3 m-0`} >
                <Box className={`listagem-headerr d-flex flex-row p-25`}>
                <Box className={`listagem-header`}>
                    <strong>RELATÓRIOS</strong>
                    </Box>
                    {/* <Box className={` flex-fill d-flex flex-row-reverse p-0`}  >
                        <Button onClick={() => { setOpenForm(0); }} className={`cancel-btn m-1`} >
                            Cancelar
                        </Button>
                    </Box> */}
                </Box>
                <Box className={`listagem-tabela d-flex flex-row h-100`}>
                    <iframe src={URLSearchParams} title="formbuild" style={{ width: '100%' }} />
                </Box>
            </Row >)
    }

    const saveForm = () => {
        let valid = formSubmit ? formSubmit.click() : false;
        if (!valid) return false;
        let formUrl = document.getElementById('formUrl').innerHTML;

        let response = addFormData({
            form: formUrl,
            content: {
                _data: { ...window.formData.content }
            },
            nestedModels: window.formData.nestedModels
        });

        //console.log('response', response);
        if (response) {
            resetCurrentPolygon();
            showMsg.success('Dados salvos com sucesso!')
            setIframeUrl(null)
        }
    }

    const start = () => {
        switch (openForm) {
            case 0:
                return previewForm();
            case 1:
                return editForm();
            case 2:
                return previewForm();
            case 3:
                return editForm();
            default:
                break;
        }
    }

    return (
        handleFormMenus(),
        start()
    );

}