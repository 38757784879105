
function groupParams (fields) {
  let fieldsSelect = ''
  for (const field of fields[0]) {
    fieldsSelect += `,${field}`
  }
  return fieldsSelect.substring(1)
}

const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export { 
  groupParams,
  groupBy 
};