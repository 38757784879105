import React, {
  useEffect,
  useState,
} from 'react';

import { makeStyles } from '@mui/styles'
  ;
import style from '../../themes/styles/geral';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import ReactTooltip from 'react-tooltip';
import EditIcon from '@material-ui/icons/Edit';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LockOpen from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { permissionsMapped } from "../../utils/permissionsMapped";
import { handlePageChange } from '../../utils/handlePageChange';
import { databaseService } from '../../services/database.service';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useStyle = makeStyles(style);

export default function ActionsInquiry(props) {
  const classes = useStyle();
  const [loggedUser, setLoggedUser] = useState(null);
  const {
    handleStart,
    handleEnd,
    handleEdit,
    handleEditIqt,
    handleDelete,
    handleDeleteIqt,
    handleBlock,
    handleView,
    handleDisabeEnable,
    disabled,
    status,
    value,
    page
  } = props;

  useEffect(function () {
    ReactTooltip.rebuild();
    async function getUserData () {
      const loggedUser = databaseService.isLogged()
      setLoggedUser(loggedUser);
    }

    getUserData();
  }, [])

  const showHiding = (action) => {
    var pagePerm = permissionsMapped[action];

    // const hasPermission = loggedUser?.allPermissionsMapped?.find((item) => {
    //   return pagePerm === item;
    // });

    var hasPermission = null;
    if (loggedUser?.codigo == 1){

      hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
        return pagePerm === item;
      });

    }else{

      hasPermission = loggedUser?.permissions?.filter((item) => {
        return pagePerm === item.nome;
      });
    }

    return hasPermission ? true : false;

  };

  const calcActionWidth = () => {
    let initial = 30;
    let btnQtdes = handleEdit ? 1 : 0;
    if (handleDelete) btnQtdes++;
    if (handleBlock) btnQtdes++;
    if (handleView) btnQtdes++;
    if (handleDisabeEnable) btnQtdes++;

    let add = (btnQtdes) * (initial + 10);
    return `${initial + add}px`;
  }

  const handleFormMenus = () => {
    return (
      <Box className={`forms-title-container d-flex flex-row`} >
        <ToastContainer />
        {
          handleView ? <Button onClick={function () { handlePageChange(handleView, page, "view", value) }} className={`${classes.successBtn} `} >
            <VisibilityIcon color='success' data-tip='Visualizar' data-for='visualizar' />
            <ReactTooltip id='visualizar' />
          </Button>
            : null
        }

        {showHiding(`${page}_edit`) ?
        status === 0 || status === 1
          ? <Button onClick={function () { handlePageChange(handleEdit, page, "edit", value) }} className={`${classes.infoBtn} `} >
            <EditIcon color='primary' data-tip='Editar' data-for='editar' />
            <ReactTooltip id='editar' />
          </Button>
          : null
          : null
        }

        {status === 2
          ? <Button className={`${classes.infoBtn} `} disabled={true}>
            <EditIcon />
          </Button>
          : null}
        {showHiding(`${page}_delete`) ?
        status === 0 || status === 1 ?
        <Button onClick={function () { handlePageChange(handleDelete, page, "delete", value) }} className={`remove-btn `}>
          <DeleteIcon color='error' data-tip='Apagar' data-for='delete' />
          <ReactTooltip id='delete' />
        </Button>
         : null
        : null
      }
        {status === 2 ? <Button className={`remove-btn `} disabled={true} >
          <DeleteIcon />
        </Button> : null}

        {showHiding(`${page}_start`) ?
        status === 0
          ? <Button onClick={function () { handlePageChange(handleStart, page, "start", value) }} className={`${classes.infoBtn} `} >
            <PlayCircleFilledIcon color='primary' data-tip='Iniciar Inquerito' data-for='Iniciar' />
            <ReactTooltip id='Iniciar' />
          </Button>
           : null
          : null
        }

        {showHiding(`${page}_end`) ?
        status === 1
          ? <Button onClick={function () { handlePageChange(handleEnd, page, "end", value) }} className={`${classes.infoBtn} `} data-tip='Terminar Inquerito' data-for='terminar'>
            <PowerSettingsNewIcon color='error' />
            <ReactTooltip id='terminar' />
          </Button>
           : null
          : null
        }

        {status === 2 ? <Button className={`${classes.infoBtn} `} disabled={true}>
          <PlayCircleFilledIcon />
          <ReactTooltip id='blocked' />
        </Button>
          : null}

        {handleBlock ? <Button onClick={function () { handleEdit(value) }} className={`${classes.successBtn} `} >
          <LockOpen color='primary' />
        </Button> : null}
        <ReactTooltip data-html="true" />
      </Box>)
  }

  return (
    handleFormMenus()
  );

};
