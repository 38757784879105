module.exports = {
    users: "Usuários",
    roles: "Perfis",
    seasons: "Épocas",
    provinces: "Províncias",
    municipalities: "Municípios",
    comunes: "Comunas",
    exploration_types: "Tipos de Exploração",
    social_objects: "Objecto Social",
    departments: "Departamentos",
    entities: "Entidades",
    inquiries: "Inquéritos",
    permissions: "Permissões",
    districts: "Bairros/Aldeias",
    producers: "Produtores",
    producer_type: "Tipos de Produtor",
    formlist: "Lista de Formulário",
    forms: "Formulário",
    mainActivities: "Actividade Principal",
    companies_infrastructure: "Infraestrutura de Empresa",
    my_account: "Minha Conta",
    localizacao: "Localização",
    undefined: "Questionário",
    audit_trails: "Auditorias",
    culture_types: "Tipo de Culturas",
    effective_livestock: "Efectivos Pecuária",
    roducers: "Produtores",
    admin_data: "Dados Administrativos",
    questlist: "Lista de Questionário",
    audit_trail: "Auditoria"
}
