const message = 'Tem certeza que deseja remover este'
const message2 = 'Tem certeza que deseja remover esta'
const message3 = 'Tem certeza que deseja Activar este'
const message4 = 'Tem certeza que deseja Desactivar este'
const messageEditar = 'Dados Alterados com Sucesso'
const messageErrorSave = 'Houve erro ao salvar'
const messageErrorSave2 = 'Falha ao salvar os dados!'
const messageRemove = 'Dados removidos com sucesso!'
const activateUserSucess = 'Usuario Activado com sucesso!'
const disactivateUserSucess = 'Usuario Desactivado com sucesso!'
const messageErrorActivateUser = 'Houve um erro ao Activar o Utilizador'
const messageErrorDisActivateUser = 'Houve um erro ao Disactivar o Utilizador'
const messageSave = 'Dados salvos com sucesso!'
const messageRemoveError = 'Houve erro ao remover os dados'
const validar = "Deseja validar este dado administrativo?"
const invalidar = "Deseja invalidar este dado administrativo?"
const selectFormulario = 'Selecione o Formulário que deseja preencher'
const noPreview = 'Sem pre-visualização'
const startInquiry = 'Inquerito Iniciado com Sucesso'
const errotStartInquiry = ' Falha ao Iniciar Inquerito'
const endInquiry = 'Inquerito Terminado com Sucesso'
const errotEndInquiry = ' Falha ao Terminar Inquerito'
const status = {
  Pendente: 'Pendente',
  Validado: 'Validado',
  Invalidado: 'Invalidado'
};
const formattedStSatus = {
  0: 'Pendente',
  1: 'Validado',
  2: 'Invalidado'
};
const userStatus = {
  Inactive: 'Inactivo',
  Active: 'Activo',
  Created: 'Criado'
};
module.exports = {
  message,
  message2,
  message3,
  message4,
  activateUserSucess,
  disactivateUserSucess,
  messageErrorActivateUser,
  messageErrorDisActivateUser,
  messageEditar,
  messageErrorSave,
  messageSave,
  messageRemove,
  messageRemoveError,
  messageErrorSave2,
  status,
  userStatus,
  formattedStSatus,
  validar,
  invalidar,
  selectFormulario,
  noPreview,
  startInquiry,
  errotStartInquiry,
  endInquiry,
  errotEndInquiry
};
