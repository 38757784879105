var permissionsMapped = {
  "home": "can_view_Dashboard",
  "users": "can_view_users",
  "users_create": "can_create_users",
  "users_view": "can_view_users",
  "users_edit": "can_change_users",
  "users_delete": "can_delete_users",
  "users_disable": 'can_disable_users',
  "users_enable": 'can_enable_users',
  "perfis": "can_view_roles",
  "perfis_create": "can_create_roles",
  "perfis_view": "can_view_roles",
  "perfis_edit": "can_change_roles",
  "perfis_delete": "can_delete_roles",
  "provincias": "can_view_provinces",
  "provincias_create": "can_create_provinces",
  "provincias_edit": "can_change_provinces",
  "provincias_delete": "can_delete_provinces",
  "municipios": "can_view_municipalities",
  "municipios_create": "can_create_municipalities",
  "municipios_edit": "can_change_municipalities",
  "municipios_delete": "can_delete_municipalities",
  "comunas": "can_view_comunes",
  "comunas_create": "can_create_comunes",
  "comunas_edit": "can_change_comunes",
  "comunas_delete": "can_delete_comunes",
  "bairros": "can_view_districts",
  "bairros_create": "can_create_districts",
  "bairros_edit": "can_change_districts",
  "bairros_delete": "can_delete_districts",
  "seasons":"can_view_seasons",
  "seasons_create": "can_create_seasons",
  "seasons_view": "can_view_seasons",
  "seasons_edit": "can_change_seasons",
  "seasons_delete": "can_delete_seasons",
  "exploration_types": "can_view_exploration_types",
  "exploration_types_create": "can_create_exploration_types",
  "exploration_types_edit": 'can_change_exploration_types',
  "exploration_types_delete": 'can_delete_exploration_types',
  "social_objects_create": "can_create_social_objects",
  "social_objects": "can_view_social_objects",
  "social_objects_edit": "can_change_social_objects",
  "social_objects_delete": "can_delete_social_objects",
  "departamentos": "can_view_departments",
  "departamentos_create": "can_create_departments",
  "departamentos_edit": "can_change_departments",
  "departamentos_delete": "can_delete_departments",
  "remit_form": "can_view_admin_data",
  "remit_form_view": "can_view_admin_data",
  "remit_form_edit": "can_change_admin_data",
  "remitted_form": "can_view_admin_data",
  "remitted_form_view": "can_view_admin_data",
  "validate_form": "can_view_admin_data",
  "validate_form_view": "can_view_admin_data",
  "entidades": "can_view_entities",
  "entidades_create": "can_create_entities",
  "entidades_view": "can_view_entities",
  "entidades_edit": "can_change_entities",
  "entidades_delete": 'can_delete_entities',
  "inquiries": "can_view_inquiries",
  "inquiries_create": "can_create_inquiries",
  "inquiries_view": "can_view_inquiries",
  "inquiries_start": 'can_start_inquiries',
  "inquiries_end": 'can_end_inquiries',
  "inquiries_edit": "can_change_inquiries",
  "inquiries_delete": "can_delete_inquiries",
  "formlist": "can_view_formlist",
  "formlist_create":"can_create_forms",
  "formlist_view": 'can_view_forms',
  "formlist_edit": 'can_change_forms',
  "formlist_delete": 'can_delete_forms',
  "questlist": "can_view_questlist",
  "questlist_create":"can_create_questlist",
  "questlist_view": 'can_view_questlist',
  "questlist_edit": 'can_change_questlist',
  "questlist_delete": 'can_delete_questlist',
  "form_builder_create":'can_create_forms',
  "form_builder": 'can_view_forms',
  "form_builder_view": 'can_view_forms',
  "form_builder_edit": 'can_change_forms',
  "form_builder_clone": 'can_clone_forms',
  "form_builder_delete": 'can_delete_forms',
  "permissoes": "can_view_permissions",
  "permissoes_create": "can_create_permissions",
  "permissoes_edit": "can_change_permissions",
  "permissoes_delete": "can_delete_permissions",
  "localizacao": "can_view_localizacao",
  "admin_data": "can_view_admin_data",
  "minha_conta": "can_view_my_account",
  "produtores":"can_view_roducers",
  "produtores_create":"can_create_roducers",
  "produtores_view":"can_view_roducers",
  "produtores_edit":"can_change_roducers",
  "produtores_delete":"can_delete_roducers",

  "tipo de produtores":"can_view_producer_type",
  "tipo de produtores_create":"can_create_producer_type",
  "tipo de produtores_view":"can_view_producer_type",
  "tipo de produtores_edit":"can_change_producer_type",
  "tipo de produtores_delete":"can_delete_producer_type",

  "actividades principal":"can_view_mainActivities",
  "actividades principal_create":"can_create_mainActivities",
  "actividades principal_view":"can_view_mainActivities",
  "actividades principal_edit":"can_change_mainActivities",
  "actividades principal_delete":"can_delete_mainActivities",

  "companies_infrastructure":"can_view_companies_infrastructure",
  "companies_infrastructure_create":"can_create_companies_infrastructure",
  "companies_infrastructure_view":"can_view_companies_infrastructure",
  "companies_infrastructure_edit":"can_change_companies_infrastructure",
  "companies_infrastructure_delete":"can_delete_companies_infrastructure",

  "culture_types":"can_view_culture_types",
  "culture_types_create":"can_create_culture_types",
  "culture_types_view":"can_view_culture_types",
  "culture_types_edit":"can_change_culture_types",
  "culture_types_delete":"can_delete_culture_types",
  "audit_trail":"can_view_audit_trails",

  "effective_livestock":"can_view_effective_livestock",
  "ceffective_livestock_create":"can_create_effective_livestock",
  "effective_livestock_view":"can_view_effective_livestock",
  "effective_livestock_edit":"can_change_effective_livestock",
  "effective_livestock_delete":"can_delete_effective_livestock",

  "audit_trail_view":"can_view_audit_trails",
}
module.exports = { permissionsMapped }
