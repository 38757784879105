import React, { useState, useEffect } from "react";

import api from "../../services/api";
import Myframe from "../Myframe";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SelectLabels from "../../components/SelectLabels2";
import { Col, Row } from "react-bootstrap";
import DATATABLE from "../../components/DataTable/dataTable"
import FormControl from "@mui/material/FormControl";
import Actions from "../../components/Actions";
import validator from "../../services/validator.service";
import { formatDate } from "../../utils/formatDate";
import FormLabel from '@mui/material/FormLabel';
import { capFirstLetter, getFormName } from "../../utils/string";
import { holdManyFiles, setAlreadyFilledData } from "../../utils/html";
import StringMessages, { status } from "../../utils/string-messages"
import ReactTooltip from 'react-tooltip';
import disable from "../../utils/disableInputs";

const userApiService = new api();
const columns = [
  {
    label: '#',
    field: 'num',
    sort: 'asc',
    width: 10
  },
  {
    label: "Entidades Remetente",
    field: "entidade_remetente",
    sort: "asc",
    width: 300,
  },
  {
    label: "Data de Envio",
    field: "data_de_envio",
    sort: "asc",
    width: 300,
  },
  {
    label: "Entidade Superior",
    field: "entidade_superior",
    sort: "asc",
    width: 300,
  },
  {
    label: "Data da Validação",
    field: "data_validacao",
    sort: "asc",
    width: 300,
  },
  {
    label: "Formulário",
    field: "formulario",
    sort: "asc",
    width: 300,
  },
  {
    label: "Estado",
    field: "estado",
    sort: "asc",
    width: 300,
  },
  {
    label: "Acções",
    field: "action",
    sort: "asc",
    width: 200,
  },
];

const validation = new validator();

export default function RemeterFormularios(props) {
  const [list, setList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [iframeTitle, setIframeTitle] = useState("Formulário");
  const [openForm, setOpenForm] = useState(0);
  const [codigo, setCodigo] = useState("");
  const [formularioToFill, setFormularioToFill] = useState("");
  //const [inValidStatus,setFilterInValidStatus]=useState(2)
  //const [status,setStatus] = useState(validStatus)
  const { hideAlertShow, openMap, showMsg, setOpenSpinner } = props;
  const [listaFormularios, setListFormularios] = useState([]);
  const [user, setUser] = useState()
  const [formSubmit, setFormSubmit] = useState();
  const [isWizard, setIsWizard] = useState();
  const [wizardSize, setWizardSize] = useState(1);
  const [formPage, setFormPage] = useState(1);
  const [wizardNext, setWizardNext] = useState(null);
  const [wizardPrev, setWizardPrev] = useState(null);
  const [formItemToFill, setFormItemToFill] = useState(null);
  const [formulario, setFormulario] = useState(null);
  const [formName, setFormName] = useState(null);
  const [dataDeEnvio, setDataDeEnvio] = useState(null);
  const [dataDeValidacao, setDataDeValidacao] = useState(null);
  const [entidadeSuperior, setEntidadeSuperior] = useState(null);
  const [entidadeRemetente, setEntidadeRemetente] = useState("");
  const [estado, setEstado] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [dadoAdministrativo, setDadoAdministrativo] = useState(null);
  const [currentData, setCurrentData] = useState();

  useEffect(async function () {
    ReactTooltip.rebuild();
    async function fetchData() {
      let _user = await userApiService.getUserData();
      setUser(_user);
      var formularios = Object.values(_user?.entidade?.formularios ?? []).map((item) => {
        return { codigo: item.codigo, nome: item.nome, modelo: item.modelo };
      });
      setListFormularios([...new Set(formularios)]);
    }
    setOpenSpinner(true);
    fetchData();
    validation.setShowMsg(showMsg.error);
    loadTable();
    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }, []);

  const hideSpinner = () => {
    let spinningContainer = document.querySelector("#spinningContainer");
    let spinningCurtain = document.querySelector("#spinningCurtain");

    if (!spinningContainer || !spinningCurtain) return false;

    spinningContainer.remove();
    spinningCurtain.remove();
  };

  const setData = async (dadoAdministrativo = null, disableAttributes = false) => {
    try {
      var formulario = dadoAdministrativo?.formularios[0];
      // //console.log("setData formulario", formulario?.modelo)
      let formName = getFormName(formulario?.modelo);
      var data_de_validacao = dadoAdministrativo?.data_de_validacao;
      if (data_de_validacao) {
        data_de_validacao = formatDate(data_de_validacao)
      }

      setCodigo(dadoAdministrativo?.codigo);
      setEntidadeRemetente(dadoAdministrativo?.entidade?.nome);
      setEntidadeSuperior(dadoAdministrativo?.entidade_superior?.nome);
      setFormulario(formulario?.nome);
      setDataDeEnvio(formatDate(dadoAdministrativo?.data_de_envio));
      setDataDeValidacao(data_de_validacao || "N/A");
      setEstado(dadoAdministrativo?.estado);
      setDadoAdministrativo(dadoAdministrativo);
      setFormName(formName);

      var dataFilled = null
      if (dadoAdministrativo) {
        const elasticdata = await userApiService.getAdminData({
          codigo: dadoAdministrativo?.codigo,
          form: formName
        })
        dataFilled = elasticdata?.data?.result?.hits?.hits[0]?._source
        //console.log("elasticData D", dataFilled);
        setDocumentId(elasticdata._id);
        var formToFill = handleSetFormularioToFill(formulario, dataFilled, disableAttributes);
        formToFill?.onclick();
      }
    } catch (error) {
      //console.log("error", error)
    }
  };

  const handleShow = (dadoAdministrativo) => {
    setData(dadoAdministrativo, true);
    setOpenForm(2);
  };

  const handleEdit = (dadoAdministrativo) => {
    setData(dadoAdministrativo);
    setOpenForm(3);
  };

  const handleCancel = () => {
    setIframeUrl(false)
    setFormItemToFill(null)
    setFormulario(null)
    setFormularioToFill(null)
    setData();
    setOpenForm(0)
  }

  const handleFillFormAction = () => {
    //console.log("handleFillFormAction HERE", formItemToFill)
    if (!formItemToFill) {
      showMsg.error(StringMessages.selectFormulario)
    } else {
      setOpenSpinner(true);
      formItemToFill.onclick();
      setOpenForm(1);
    }
  };
  const filterStatus = () => {
    //console.log('WORKING', status)
  };


  hideSpinner();

  const handleSetFormularioToFill = (obj, dataFilled = null, disableAttributes = false) => {
    setFormularioToFill(obj);
    const form = obj;
    let formName = form?.modelo?.toString().toUpperCase().split('.')[0];
    var item = createFormItem(form, dataFilled, disableAttributes);
    //console.log("formNameNAME", form);
    handleEditform(form);
    if (dataFilled) setCurrentData(dataFilled)
    console.error('1.currentData', currentData);
    console.error('1.1.currentData', dataFilled);


    setFormItemToFill(item)
    return item;
  };

  const handleEditform = async (form) => {
    setOpenSpinner(true);
    let formName = getFormName(form?.modelo)?.toUpperCase();
    let resp = await userApiService.getFormBuilderFormByName(form?.modelo);

    holdManyFiles({ files: resp.js, src: true, type: 'script' });
    holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet' });

    //console.log("resp", resp);

    let div = document.createElement('div');
    div.innerHTML = resp?.html?.replace("formSumit", "formSubmit");
    div.style.margin = '5px';

    let btn = div.querySelector('button[type="submit"]');
    if (!btn) {
      btn = div.querySelector('button[onclick="formSubmit()"]');
    }

    if (!btn) {
      btn = div.querySelector('button[onclick="formSumit()"]');
    }

    setFormSubmit(btn);
    if (btn) btn.style.display = 'none';

    let html = div.innerHTML

    setIframeUrl(html)
    setIframeTitle(formName)

    // setTimeout(() => {
    //   setOpenSpinner(false);
    // }, 1000);

  }

  useEffect(function () {
    // fillFields();
  }, [currentData?._Metadata]);

  const fillFields = () => {

    let interval = setInterval(function () {
      try {
        if (!currentData) return false;
        console.error('1.currentData', currentData);
        let data = currentData;
        console.error('2.currentData', data);

        Object.keys(data).map(key => {
          let inputs = document.querySelectorAll(`input.databaseField[data-model="${key}"]:not(.notDataFieldConsider), textarea.databaseField[data-model="${key}"]:not(.notDataFieldConsider), select.databaseField[data-model="${key}"]:not(.notDataFieldConsider)`);
          Array.from(inputs).map(input => {
            try {
              if (input) {
                let value = data[key][input.name];
                if (input.dataset.multiple) {
                  value = data[key][input.name].values[0];
                  data[key][input.name].values = data[key][input.name].values.slice(1, data[key][input.name].values.length);
                }
                if (input.type === 'checkbox') {
                  if (Array.isArray(value) && value.includes(input.value))
                    input.checked = true;
                } else if (input.type === 'radio' && input.dataset.value === value) {
                  input.checked = true;
                } else if (input.type === 'file') {
                } else if (input.dataset.determinant) {
                  input.value = value.length;
                } else {
                  input.value = value;
                }
              }
            } catch (error) {
              console.error(error);
            }
            return true;
          });
          return true;
        })
      } catch (error) {
        // setLoading(false);
      }

      clearInterval(interval);
      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);
    }, 3000)


  }

  const createFormItem = (form, dataFilled = null, disableAttributes = false) => {
    try {
      //console.log('FORM', form)
      // let formName = form?.modelo?.toString().toUpperCase().split('.')[0];
      let formName = getFormName(form?.modelo)?.toUpperCase();

      const formItem = {
        name: formName,
        onclick: async () => {
          let resp = await userApiService.getFormBuilderFormByName(form?.modelo);
          //console.log("resp", resp);

          holdManyFiles({ files: resp.js, src: true, type: 'script' });
          holdManyFiles({ files: resp.css, href: true, type: 'link', rel: 'stylesheet' });
          holdManyFiles({ files: resp.img, src: true, type: 'img' });
          let div = document.createElement('div');
          div.innerHTML = resp?.html?.replace("formSumit", "formSubmit");
          div.style.margin = '5px';
          let btn = div.querySelector('button[type="submit"]');
          if (!btn) {
            btn = div.querySelector('button[onclick="formSubmit()"]');
          }

          if (!btn) {
            btn = div.querySelector('button[onclick="formSumit()"]');
          }
          // //console.log("SUBMIT BUTTON", btn);
          let formNewLine = div.querySelectorAll('.formNewLine');
          for (const key in formNewLine) {
            if (Object.hasOwnProperty.call(formNewLine, key)) {
              const element = formNewLine[key];
              element.style.display = "block"
            }
          }

          let wizard = div.querySelectorAll('.formWizardPage')?.length;

          if (wizard > 0) {
            setIsWizard(wizard);
            let btnNext = div.querySelector('#btn-form-next');
            let btnPrev = div.querySelector('#btn-form-prev');

            setWizardNext(btnNext);
            setWizardPrev(btnPrev);
            setWizardSize(wizard);
            // //console.log('oha aí o wizard', btnNext, btnPrev);
            // //console.log('oha aí o wizard counter', wizard);
            if (btnPrev) btnPrev.style.display = 'none';
            if (btnNext) btnNext.style.display = 'none';
          }



          // TAKE THIS INTO A FUNCTION AT A LATER STAGE
          setAlreadyFilledData(div, dataFilled);


          setFormSubmit(btn);
          if (btn) btn.style.display = 'none';

          let html = div.innerHTML

          setIframeUrl(html)
          setIframeTitle(formName)
          setTimeout(() => {
            setOpenSpinner(false);
          }, 1000);

          let fill = false
          setTimeout(() => {
            let fillDataFunctions = document.querySelector('#formUrl');
            if (fillDataFunctions?.dataset) {
              let filldataFun = eval(fillDataFunctions.dataset.filldata)
              if (filldataFun) fill = filldataFun(dataFilled, false)

              setTimeout(() => {
                //console.log('fill ', fill);
                setOpenSpinner(false);
              }, 1000);

              // if (disableAttributes) {
              //   let inputs = div.querySelectorAll('input');
              //   let inputSelect = div.querySelectorAll('select')
              //   let textareaSelect = div.querySelectorAll('textarea')

              //   disable(inputs)
              //   disable(inputSelect)
              //   disable(textareaSelect)

              // }
              if (disableAttributes) {
                let inputs = div.querySelectorAll('input');
                let inputSelect = div.querySelectorAll('select')
                let textareaSelect = div.querySelectorAll('textarea')

                for (const key in inputs) {
                  if (Object.hasOwnProperty.call(inputs, key)) {
                    const element = inputs[key];
                    element.setAttribute("disabled", true)
                  }
                }
                for (const key in inputSelect) {
                  if (Object.hasOwnProperty.call(inputSelect, key)) {
                    const element = inputSelect[key];
                    element.setAttribute("disabled", true)
                  }
                }
                for (const key in textareaSelect) {
                  if (Object.hasOwnProperty.call(textareaSelect, key)) {
                    const element = textareaSelect[key];
                    element.setAttribute("disabled", true)
                  }
                }
              }

            }

          }, 4000);
        }
      }

      return formItem;
    } catch (error) {
      //console.log('error form', error);
    }
  };

  const stateCompare = (estado, data) => {
    if (estado == 1 || estado == 2) {
      return data
    }
    return null
  }

  const allowNullDate = (data) => {
    return data === formatDate(new Date('1970-01-01T00:00:00.000Z')) ? '' : data
  }

  const loadTable = async () => {
    // try {
    let _user = await userApiService.getUserData();
    const entidade = _user?.entidade.codigo
    let list = (await userApiService.getAdminDataByMultipleStatusAndEntidadeSuperior([0, 1, 2].join(","), entidade) || []);
    let rows = [];
    if (Array.isArray(list)) {
      list.reverse();
    } else {
      list = []
    }
    Object.values(list).map((l) => {
      const estadoFormatado = StringMessages.formattedStSatus[l?.estado] || "Pendente"
      // const estadoFormatado = l?.estado == 1
      // ? StringMessages.status.Validado
      // : StringMessages.status.Invalidado
      rows.push({
        num: l?.codigo,
        entidade_remetente: l?.entidade?.nome,
        data_de_envio: formatDate(l?.data_de_envio),
        data_validacao: allowNullDate(formatDate(l.data_de_validacao)),
        entidade_superior: l?.entidade_superior?.nome,
        formulario: l?.formularios[0]?.nome,
        estado: estadoFormatado,
        action: (
          <Actions
            page='remitted_form'
            value={l}
            handleView={handleShow}
            disabled={l?.estado}
          />
        ),
      });
    });
    setList({ columns: columns, rows: rows });
    // } catch (error) {
    //   //console.log('error:', error);
    // }


  };

  const save = async () => {
    try {
      setOpenSpinner(true);
      //console.log("formSubmit", formSubmit);
      formSubmit?.click()

      if (!window.formData) return false;
      let formUrl = document.getElementById('formUrl').innerHTML;
      // //console.log("formUrl", formUrl);
      // //console.log(window.formData.content);
      // return;
      // //console.log("form", document.getElementById('formUrl').innerHTML);
      let timer = setTimeout(() => {
        hideAlertShow();
        clearTimeout(timer);
      }, 5 * 1000);

      var data = {
        entidade: user?.entidade?.codigo,
        formulario: formularioToFill?.codigo,
        estado: 0,
        // data_de_envio: new Date(),
      }

      let result = await userApiService.saveAdminData(data);
      //console.log("saveAdminData", result);

      if (result) {
        var metadata = {
          dataType: "admin-data",
          codigo: result?.data?.insertId,
          codigoEntity: user?.entidade?.codigo,
          codigoUser: user?.codigo,
          name: user?.entidade?.nome,
          filledForm: formUrl,
          createdAt: new Date(),
        };

        var formData = window.formData.content;
        formData["_Metadata"] = metadata
        //console.log("formData", formData)
        let resp = await userApiService.synchronize(formUrl, formData);

        loadTable();
        setOpenForm(0);
        showMsg.success(StringMessages.messageSave)
        setIframeUrl(null)
      }

      setTimeout(() => {
        setOpenSpinner(false);
      }, 1000);

    } catch (error) {
      //console.log('error:',error);
    }

  }

  const update = async () => {
    setOpenSpinner(true);
    //console.log("formSubmit", formSubmit);
    formSubmit?.click()

    if (!window.formData) return false;
    //console.log("UPDATING");
    //console.log("FORMDATA", window.formData.content);
    // let formUrl = document.getElementById('formUrl').innerHTML;
    // //console.log("formUrl", formUrl);
    // //console.log(window.formData.content);
    // //console.log("form", document.getElementById('formUrl').innerHTML);
    let timer = setTimeout(() => {
      hideAlertShow();
      clearTimeout(timer);
    }, 5 * 1000);

    // var data = {
    //   entidade: user?.entidade?.codigo,
    //   formulario: formularioToFill?.codigo,
    //   estado: 0,
    //   // data_de_envio: new Date(),
    // }

    // let result = await userApiService.saveAdminData(data);
    var document = {
      "id": documentId,
      "codigo": dadoAdministrativo?.codigo,
      "form": formName?.toLowerCase(),
      "source": window.formData.content
    }

    //console.log("document", document);

    const result = await userApiService.updateElasticsearchDataByFormName(document)

    //console.log("elasticdata", result);

    if (result) {
      setData();
      loadTable();
      setOpenForm(0);
      showMsg.success(StringMessages.messageEditar)
      setIframeUrl(null)
    }

    setTimeout(() => {
      setOpenSpinner(false);
    }, 1000);
  }

  const showForm = () => {
    return (
      <Row
        className={`listagem position-relative .container-fluid w-100 p-3 m-0`}
      >
        <Box className={`listagem-header d-flex flex-row p-25`}>
          <strong>DADO ADMINISTRATIVO REMETIDO</strong>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
            <Button
              onClick={handleCancel}
              className={`cancel-btn m-1`}
            >
              Voltar
            </Button>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column  p-5 pb-3`}>
          <Row className={`d-flex flex-row pb-3`}>
            <Col sm={12} className={`d-flex flex-column `}  >
              <Row className={`d-flex flex-row p-1`}  >
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Entidade Remetente</strong>
                    </FormLabel>
                    <FormLabel>{capFirstLetter(entidadeRemetente || "")}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Entidade Superior</strong>
                    </FormLabel>
                    <FormLabel>{capFirstLetter(entidadeSuperior || "")}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >

                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Data de envio</strong>
                    </FormLabel>
                    <FormLabel>{dataDeEnvio}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Data de validação</strong>
                    </FormLabel>
                    <FormLabel>{dataDeValidacao}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Formulário</strong>
                    </FormLabel>
                    <FormLabel>{formulario}</FormLabel>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={`d-flex flex-row pb-3`}>
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Row>
        </Box>
      </Row>
    );
  };

  const editForm = () => {
    return (
      <Row
        className={`listagem position-relative .container-fluid w-100 p-3 m-0`}
      >
        <Box className={`listagem-header d-flex flex-row p-25`}>
          <strong>DADO ADMINISTRATIVO REMETIDO</strong>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
            <Button
              onClick={() => openForm === 2 ? handleEdit(dadoAdministrativo) : update()}
              className={`right-btn m-1`}
              disabled={estado}
            >
              {openForm === 2 ? "Editar" : "Actualizar"}
            </Button>
            <Button
              onClick={handleCancel}
              className={`cancel-btn m-1`}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Box className={`listagem-tabela d-flex flex-column  p-5 pb-3`}>
          <Row className={`d-flex flex-row pb-3`}>
            <Col sm={12} className={`d-flex flex-column `}  >
              <Row className={`d-flex flex-row p-1`}  >
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Entidade Remetente</strong>
                    </FormLabel>
                    <FormLabel>{capFirstLetter(entidadeRemetente || "")}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Entidade Superior</strong>
                    </FormLabel>
                    <FormLabel>{capFirstLetter(entidadeSuperior || "")}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >

                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Data de envio</strong>
                    </FormLabel>
                    <FormLabel>{formatDate(dataDeEnvio)}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Data de validação</strong>
                    </FormLabel>
                    <FormLabel>{formatDate(dataDeValidacao)}</FormLabel>
                  </FormControl>
                </Col>
                <Col sm={3} className={`d-flex flex-column  justify-content-start`} >
                  <FormControl component="fieldset" className="mb-2">
                    <FormLabel component="legend">
                      <strong>Formulário</strong>
                    </FormLabel>
                    <FormLabel>{formulario}</FormLabel>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={`d-flex flex-row pb-3`}>
            <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
          </Row>
        </Box>
      </Row>
    );
  };

  const handleTitle = (params) => {
    const {
      title = "Dados",
      subtitle = "Admistrativos",
      mapButton = false,
    } = params ? params : {};
    return (
      <Box className={`forms-title-container position-relative`}>
        <h6 className={`forms-title`}>{title}</h6>
        <label className={`forms-sub-title`}>{subtitle}</label>
      </Box>
    );
  };

  const handleFormMenus = () => {
    return (
      <Row
        className={`listagem position-relative container-fluid h-100 w-100 p-3 m-0`}
      >
        <Box className={`listagem-tabela flex-fill d-12`}>
          <h3>Formulários Recebidos</h3>
          <DATATABLE
            className={`form-list flex-fill d-12`}
            data={list}
          />
        </Box>
      </Row>
    );
  };

  const handleFillForm = () => {
    return (
      <Box
        className={`form-data d--flex flex-column h-100 w-100 m-0`}
      >
        <Box className={`listagem-headerr d-flex flex-row pl-0 pb-25 pt-25`}>
          <Box className={`listagem-header `}>
            <strong>{`${openForm === 1 ? "" : ""}`}</strong>
            PREENCHER DADOS
          </Box>
          <Box className={` flex-fill d-flex flex-row-reverse p-0`}>
            <div className="allbtns">
              <Button onClick={save} className={`right-btn m-1`}>
                Salvar
              </Button>
              <Button
                onClick={handleCancel}
                className={`cancel-btn m-1`}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Box>
        <Box className={`listagem-tabela p-3`}>
          {handleTitle({
            title: iframeTitle,
            subtitle: "Preenchimento",
            mapButton: true,
          })}
          <Myframe openMap={openMap} url={iframeUrl} src={iframeUrl} />
        </Box>
      </Box>
    );
  };

  const start = () => {
    switch (openForm) {
      case 0:
        return handleFormMenus();
      case 1:
        return handleFillForm();
      case 2:
        return showForm();
      case 3:
        return editForm();
      case 4:
        return handleFillForm();
      default:
        break;
    }
  };

  return start();
}
