import React, { useState, useEffect } from 'react';
import authService from '../../services/auth';
import MissionScreen from '../../pages/Mission';
import ChangePassword from '../../pages/ChangePassword';
import { databaseService, logout } from '../../services/database.service';
import api from "../../services/api";
import LoadingBar from '../../components/LoadingBar';
import Toast from '../../components/Toast';
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import Modal from '@material-ui/core/Modal';
import { Box, Button } from '@material-ui/core';
import { height } from '@mui/system';
const userApiService = new api();
// var notyf = new Notyf();

export default function AdminLayout(props) {
    const { setLogged, setOpenSpinner } = props;
    const [userVerif, setUserVerif] = useState(null);
    const [alertShow, setAlertShow] = useState(false);
    const [segundos, setSegundos] = useState(5);
    const timeout = 1000 * 60 * 10
    const promptTimeout = 1000 * 30
    const [openModal, setOpen] = useState(false)
    const [remaining, setRemaining] = useState(0)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 200,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const onPrompt = () => {
        setOpen(true)
        setRemaining(promptTimeout)
    }

    const onIdle = () => {
        setOpen(false)
        setRemaining(0)
        logout()
        window.location.href = "/"
    }

    const onActive = () => {
        setOpen(false)
        setRemaining(0)
    }

    const { getRemainingTime, isPrompted, reset } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive
    })

    const handleStillHere = () => {
        setOpen(false)
        reset()
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getRemainingTime, isPrompted])

    useEffect(() => {
        setOpenSpinner(true)
        async function fetchData() {
            const loggedUser = await authService.isLogged();
            const emailToCheck = await databaseService.getEmailToCheck()
            const freshUser = await userApiService.getAuthUserByEmail(emailToCheck)
            setLogged(loggedUser)
            setTimeout(() => {
                setUserVerif(freshUser?.status)
                setOpenSpinner(false)
            }, 3000);
        }

        fetchData();

        document.addEventListener("snieap-token-issue", () => {
            setAlertShow(!alertShow)
            setTimeout(() => {
                window.location.href = "/"
            }, segundos * 1000);
        })
    }, []);

    const handleSetAlertShow = () => {
        setAlertShow(!alertShow)
    }

    const handleCloseModal = () => {
        // setAlertShow(!alertShow)
    }

    const handleChild = () => {
        const props = {
            setLogged: setLogged,
            openSpinner: false,
            setOpenSpinner: setOpenSpinner
        }

        const message = `Token inválido ou expirado será redirecionado para página do login em ${segundos} segundo(s)`

        return (
            <>
                <Modal
                    className={"logging-out-modal"}
                    // style={modalStyle}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={style}>
                        <p>Terminando a sua sessão em {remaining} segundos</p>
                        <Button className='' variant="outlined" onClick={handleStillHere}>Continuar</Button>
                    </Box>
                </Modal>
                {
                    alertShow
                        ? <Toast onClose={handleSetAlertShow} type={"warning"} msg={message} />
                        : null
                }
                {
                    userVerif === undefined
                        ? <LoadingBar {...props} />
                        : userVerif === 0
                            ? <ChangePassword  {...props} />
                            : <MissionScreen {...props} />
                }
            </>
        )
    };

    return handleChild()
};
