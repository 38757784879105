const flattenObjects = (obj) => {
    const entries = Object.entries(obj);
    return Object.assign({}, ...entries.map(([prop, value]) => {
        if(typeof value === "object" && !Array.isArray(value)) {
            return Object.assign({}, ...Object.entries(value)
            .map(([key, val]) => ({[`${prop}.${key}`]: val})))
        } else {
            return {[prop]: value};
        }
    }));
}

export {
    flattenObjects
}

// {
//     "Model1": {
//         "nome": "", 
//         "idade": ""
//     },
//     "Model2": {
//         "contacto": "",
//         "email": ""
//     }
// }

// {
//     "Model1.nome": "", 
//     "Model1.idade": ""
//     "Model2.contacto": "",
//     "Model2.email": ""
// }