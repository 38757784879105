import React, { useState, useEffect } from "react";

import {
  logout,
  addFormData,
  getFormData,
} from "../../services/database.service";
import { formBuilderApiService } from "../../services/form_builder_api";
import { getFormList } from "../../services/database.service";
import icones from "../../themes/icones";
import Box from "@material-ui/core/Box";
import Formularios from "../../pages/Formularios";
import Questionarios from "../../pages/Questionarios";
import { getPolygon } from "../../services/database.service";
import minagrip_logo from "../../assets/images/minagrip-logo.png";
import insignea from "../../assets/images/angola.png";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import api from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { permissionsMapped } from "../../utils/permissionsMapped";
import { databaseService } from '../../services/database.service';
import { removeFormBuilderCss } from "../../utils/events";
import Feedback from "react-bootstrap/esm/Feedback";

export default function Menu(props) {
  const [formMenu, setFormMenu] = useState(false);
  const [formList, setFormList] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);
  const [formSubmit, setFormSubmit] = useState();
  const zonasMarcadas = getPolygon();
  let internetConection = window.navigator?.onLine || false;

  const {
    activeScreen,
    handleClose,
    changeScreen,
    setLogged,
    user
  } = props;

  const [collapseLevel2Items, setOpenLevel2Collapse] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [collapseLevel3Items, setOpenLevel3Collapse] = useState([
    false,
    false,
    false,
    false,
  ]);
  const userApiService = new api();


  useEffect(function () {
    async function getUserData() {
      const loggedUser = databaseService.isLogged()
      setLogged(loggedUser);
    }

    getUserData();

    async function holdFile({
      type = "script",
      href = null,
      src = null,
      async = true,
      rel = null,
    }) {
      let full_address = formBuilderApiService.getFullAdress(src);
      let query = `${type}[${src ? "src" : "href"}="${full_address}"]`;
      let script_exists = document.querySelector(query);
      if (!script_exists) {
        let _element = document.createElement(type);
        if (src) _element.src = full_address;
        if (href) _element.href = href;
        if (async) _element.async = async;
        if (rel) _element.rel = rel;
        _element.crossorigin = "*";
        document.head.appendChild(_element);
      }
    }

    async function holdManyFiles({ files, type, async, rel }) {
      if (Array.isArray(files)) {
        files.map((file) => {
          holdFile({
            src: type.toLowerCase() == "script" ? file : false,
            type,
            async,
            rel,
            href: type.toLowerCase() == "link" ? file : false,
          });
        });
      }
    }

    async function getForms() {
      let response = getFormList();
      let list = [];
      if (response) {
        response = response.map((form) => {
          let formName = form.name.toString().toUpperCase().split(".")[0];
          list.push({
            name: formName,
            onclick: () => {
              let resp = form.content;
              holdManyFiles({ files: resp.js, src: true, type: "script" });
              holdManyFiles({ files: resp.css, href: true, type: "link" });
              holdManyFiles({ files: resp.img, src: true, type: "img" });
              let div = document.createElement("div");
              div.innerHTML = resp.html;
              div.style.margin = "5px";
              let btn = div.querySelector("button");
              setFormSubmit(btn);
              btn.style.display = "none";

              let spinningContainer = div.querySelector("#spinningContainer");
              if (spinningContainer) spinningContainer.style.display = "none";
              let spinningCurtain = div.querySelector("#spinningCurtain");
              if (spinningCurtain) spinningCurtain.style.display = "none";
              setIframeUrl(div.innerHTML);
            },
          });
        });
        list.push({
          name: "Voltar",
          onclick: () => {
            setFormMenu(false);
          },
        });
        setFormList(list);
      }
    }

    getForms();
  }, []);

  const showHiding = (action) => {
    var pagePerm = permissionsMapped[action];

    const checkEntitys = user?.entidade?.codigo === 1 ? true: false

    if(checkEntitys === false){

    const hasPermission = user?.permissions?.find((item) => {
      return item.nome ===  pagePerm;
    });

    return hasPermission ? true : false;
  }

   return checkEntitys;

  };

  const menuList = [
    {
      show: showHiding("home"), // TO DO
      code: "home",
      icon: icones.home,
      title: "Home ",
      class: "",
      onclick: () => handlePageChange(handleForm, "home"),
    },
    {
      show: true,
      code: "cadastros gerais",
      key: 0,
      icon: icones.cadastros,
      title: "Cadastros Gerais",
      onclick: () => handleClick(0),
      items: [
        {
          show: showHiding('localizacao'),
          code: "localizacao",
          key: 1,
          icon: icones.cadastros,
          title: "Localização",
          onclick: () => handleClick(1, "third-level"),
          items: [
            {
              show: showHiding('provincias'),
              code: "provincias",
              key: 1.1,
              icon: icones.cadastros,
              title: "Províncias",
              onclick: () => handlePageChange(handleForm, "provincias"),
            },
            {
              show: showHiding('municipios'),
              code: "municipios",
              key: 1.2,
              icon: icones.cadastros,
              title: "Municípios",
              onclick: () => handlePageChange(handleForm, "municipios"),
            },
            {
              show: showHiding('comunas'),
              code: "comunas",
              key: 1.3,
              icon: icones.cadastros,
              title: "Comunas",
              onclick: () => handlePageChange(handleForm, "comunas"),
            },
            {
              show: showHiding('bairros'),
              code: "bairros",
              key: 1.4,
              icon: icones.cadastros,
              title: "Bairros",
              onclick: () => handlePageChange(handleForm, "bairros"),
            },

          ],
        },
        {
          show: showHiding('seasons'),
          code: "seasons",
          key: 4,
          icon: icones.cadastros,
          title: "Épocas",
          onclick: () => handlePageChange(handleForm, "seasons"),
        },
        {
          show: showHiding('exploration_types'),
          code: "exploration_types",
          key: 5,
          icon: icones.cadastros,
          title: "Tipo de Exploração",
          onclick: () => handlePageChange(handleForm, "exploration_types"),
        },
        {
          show: showHiding('social_objects'),
          code: "social_objects",
          key: 6,
          icon: icones.cadastros,
          title: "Objectos Sociais",
          onclick: () => handlePageChange(handleForm, "social_objects"),
        },
        {
          show: showHiding('tipo de produtores'),
          code: "tipo de produtores",
          key: 11,
          icon: icones.cadastros,
          title: "Tipo de Produção",
          onclick: () => handlePageChange(handleForm, "tipo de produtores"),
        },
        {
          show: showHiding('actividades principal'),
          code: "actividades principal",
          key: 12,
          icon: icones.cadastros,
          title: "Actividade Principal",
          onclick: () => handlePageChange(handleForm, "actividades principal"),
        },
        {
          show: showHiding('companies_infrastructure'),
          code: "companies_infrastructure",
          key: 13,
          icon: icones.cadastros,
          title: "Infraestrutura de Empresas",
          onclick: () => handlePageChange(handleForm, "companies_infrastructure"),
        },
        {
          show: showHiding('culture_types'),
          code: "culture_types",
          key: 14,
          icon: icones.cadastros,
          title: "Tipos de Cultura",
          onclick: () => handlePageChange(handleForm, "culture_types"),
        },
        {
          show: showHiding('effective_livestock'),
          code: "effective_livestock",
          key: 15,
          icon: icones.cadastros,
          title: "Efectivo Pecuário",
          onclick: () => handlePageChange(handleForm, "effective_livestock"),
        },
        {
          show: showHiding('departamentos'),
          code: "departamentos",
          key: 7,
          icon: icones.cadastros,
          title: "Departamentos",
          onclick: () => handlePageChange(handleForm, "departamentos"),
        },
        // {
        //   show: showHiding('questlist'),
        //   code: "questlist",
        //   key: 11,
        //   icon: icones.formularios,
        //   title: "Questionários",
        //   class: "",
        //   onclick: () => handlePageChange(handleForm, "questlist"),
        // },
      ],
    },
    {
      show: true,
      key: 2,
      code: "dados_de_acesso",
      icon: icones.dados_de_acesso,
      title: "Dados de Acesso",
      onclick: () => handleClick(2),
      items: [
        {
          show: showHiding("perfis"),
          code: "perfis",
          key: 2.1,
          icon: icones.dados_de_acesso,
          title: "Perfil",
          onclick: () => handlePageChange(handleForm, "perfis"),
        },
        {
          show: showHiding('users'),
          code: "users",
          key: 2.2,
          icon: icones.dados_de_acesso,
          title: "Utilizadores",
          onclick: () => handlePageChange(handleForm, "users"),
        },
        {
          show: false,
          key: 2.3,
          icon: icones.dados_de_acesso,
          title: "Permissões",
          onclick: () => handlePageChange(handleForm, "permissoes"),
        },
      ],
    },
    {
      show: showHiding('admin_data'),
      key: 3,
      code: "admin_data",
      icon: icones.dados_administrativos,
      title: "Dados Administrativos",
      class: "",
      onclick: () => handleClick(3),
      items: [
        {
          show: showHiding('formlist'),
          code: "formlist",
          key: 3.1,
          icon: icones.dados_administrativos,
          title: "Formulários",
          class: "",
          onclick: () => handlePageChange(handleForm, "formlist"),
        },
        {
          show: showHiding('remit_form'),
          code: "remit_form",
          key: 3.2,
          icon: icones.dados_administrativos,
          title: "Remeter Formulários",
          class: "",
          onclick: () => handlePageChange(handleForm, "remit_form"),
        },
        {
          show: showHiding('validate_form'),
          code: "validate_form",
          key: 3.3,
          icon: icones.dados_administrativos,
          title: "Válidar Formulários",
          class: "",
          onclick: () => handlePageChange(handleForm, "validate_form"),
        },
        {
          show: showHiding('remitted_form'),
          code: "remitted_form",
          key: 3.4,
          icon: icones.dados_administrativos,
          title: "Formulários Recebidos",
          class: "",
          onclick: () => handlePageChange(handleForm, "remitted_form"),
        },
      ],
    },
    {
      show: showHiding('entidades'),
      code: "entidades",
      key: 8,
      icon: icones.entidades,
      title: "Entidades",
      onclick: () => handlePageChange(handleForm, "entidades"),
    },
    {
      show: showHiding('inquiries'),
      code: "inquiries",
      key: 4,
      icon: icones.inqueritos,
      title: "Inquéritos",
      class: "",
      onclick: () => handleClick(4),
      items: [
        {
          show: showHiding('inquiries'),
          code: "inquiries",
          key: 9.1,
          icon: icones.inqueritos,
          title: "Listar",
          class: "",
          onclick: () => handlePageChange(handleForm, "inquiries"),
        },
        {
          show: showHiding('questlist'),
          code: "questlist",
          key: 9.2,
          icon: icones.formularios,
          title: "Questionários",
          class: "",
          onclick: () => handlePageChange(handleForm, "questlist"),
        },
      ]
    },
    // {
    //   show: showHiding("audit_trail"),
    //   code: "audit_trail",
    //   key: 16,
    //   icon: icones.minha_conta,
    //   title: "Auditória",
    //   class: "",
    //   onclick: () => handlePageChangeMyAccount(handleForm, "audit_trail"),
    // },
    {
      show: showHiding("minha_conta"),
      code: "minha_conta",
      key: 17,
      icon: icones.minha_conta,
      title: "Minha conta",
      class: "",
      onclick: () => handlePageChange(handleForm, "minha_conta"),
    },
    {
      show: showHiding('logout'),
      code: "logout",
      key: 18,
      icon: icones.logout,
      title: "Logout",
      class: "d-md-none",
      onclick: async () => handlePageChange(handleLogout, "logout"),
    },
  ];

  const mapFn = (key) => (boolean_value, i) => {
    if (key === i) {
      return !boolean_value;
    } else {
      return false;
    }
  };

  const handleClick = (key, menu_type = "second-level") => {
    // setOpenCollapse([...Array(menuItemsKeys.length)].map((val) => false));
    if (menu_type === "second-level") {
      setOpenLevel2Collapse(collapseLevel2Items.map(mapFn(key)));
    } else {
      setOpenLevel3Collapse(collapseLevel3Items.map(mapFn(key)));
    }
  };

  const saveForm = () => {
    formSubmit.click();
    let formUrl = document.getElementById("formUrl").innerHTML;
    addFormData({
      form: formUrl,
      content: window.formData.content,
      nestedModels: window.formData.nestedModels,
    });
  };

  const showInsignea = () => {
    return (
      <>
        <Box
          className={`insignea-container  d-flex d-none flex-column end-0 w-60 justify-content-center p-4`}
        >
          <img className={`menuImg`} src={insignea} />
          <img className={`menuImg w-100`} src={minagrip_logo} />
        </Box>
      </>
    );
  };

  const showMenu = () => {
    return (
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          overflowY: "scroll",
          height: "100%",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ToastContainer />
        {/* {showInsignea()} */}
        {menuList.map((menuItem) => {
          return menuItem.show ? (
            <React.Fragment key={`menu-item-${menuItem.key}`}>
              <ListItemButton
                onClick={menuItem.onclick}
                className={`menuButtonContainer ${menuItem.class} ${activeScreen === menuItem.code ? "active-item" : ""
                  }`}
              >
                {/* <img className={`menuButtonIcon`} src={menuItem.icon} /> */}
                <Icon color="primary" style={{ marginRight: "18px" }}>
                  {menuItem.icon}
                </Icon>
                <ListItemText
                  className={`menuButtonAction`}
                  primary={menuItem.title}
                />
                {menuItem?.items?.length > 0 ? (
                  collapseLevel2Items[menuItem.key] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItemButton>
              <Collapse
                in={collapseLevel2Items[menuItem.key]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div">
                  {menuItem?.items?.map((subItem) => {
                    return subItem.show ? (
                      <React.Fragment
                        key={`menu-subitem-${menuItem.key}-${subItem.key}`}
                      >
                        <ListItemButton
                          onClick={subItem.onclick}
                          className={`${activeScreen === subItem.code ? "active-item" : ""
                            }`}
                        >
                          <ListItemText
                            className={`menuButtonAction`}
                            primary={subItem.title}
                            style={{ marginLeft: "20%" }}
                          />
                          {subItem?.items?.length > 0 ? (
                            collapseLevel3Items[subItem.key] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItemButton>
                        <Collapse
                          in={collapseLevel3Items[subItem.key]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div">
                            {subItem?.items?.map((subItem2) => {
                              return subItem2.show ? (
                                <ListItemButton
                                  key={`menu-subitem-item-${subItem.key}-${subItem2.key}`}
                                  className={`${activeScreen === subItem2.code
                                      ? "active-item"
                                      : ""
                                    }`}
                                >
                                  <ListItemText
                                    primary={`${subItem2.title}`}
                                    onClick={subItem2.onclick}
                                    style={{ marginLeft: "25%" }}
                                  />
                                </ListItemButton>
                              ) : null;
                            })}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ) : null;
                  })}
                </List>
              </Collapse>
            </React.Fragment>
          ) : null;
        })}
      </List>
    );
  };

  const handleForm = (screen = null) => {
    changeScreen(screen);
    handleClose();
  };
  const notify = () =>
    toast.error("Nao Tens Permissão Para Acessar esta Pagina!");
  const handleLogout = (action = null) => {
    logout();
    setLogged(null);
  };
  const handlePageChangeMyAccount = async (fn, action) => {
    const customEvent = new CustomEvent("removeBootstrap", { detail: "" });
    document.dispatchEvent(customEvent);
    fn(action);
  };

  const handlePageChange = async (fn, action) => {
    var loggedUser = await userApiService.getUserData();
    var pagePerm = permissionsMapped[action];
    loggedUser.allPermissionsMapped = localStorage.getItem('allPermissionsMapped').split(',')

    // var hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
    //   return pagePerm === item;
    // });


    var hasPermission = null;
    if (loggedUser?.codigo == 1){

      hasPermission = loggedUser?.allPermissionsMapped?.filter((item) => {
        return pagePerm === item;
      });

    }else{

      hasPermission = loggedUser?.permissions?.filter((item) => {
        return pagePerm === item.nome;
      });
    }

    const permissionLength = hasPermission?.length;
    if (!permissionLength > 0) {
      notify();
      return;
    }

    removeFormBuilderCss()
    fn(action);
  };

  const showFormularios = () => {
    return <Formularios handleClose={handleClose} />;
  };

  return <>{formMenu ? showFormularios() : showMenu()}</>;
}
