
  
import React from 'react';
import PropTypes from 'prop-types';

// import './styles/imagePreview.css';

export const ImagePreview = ({ dataUri, isFullscreen }) => {
  let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

  return (
    <div className={`demo-image-preview ${classNameFullscreen} w-100`}>
      <img className={`w-100`} src={dataUri} />
    </div>
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.string,
  isFullscreen: PropTypes.bool
};

export default ImagePreview;