
export function login (email, password) {

    if(email != process.env.REACT_APP_USERNAME || password != process.env.REACT_APP_PASSWORD) return false;
    return {
        "data": {
          "id": 11,
          "name": "Supervisor 1",
          "email": "supervisorgeocad@gmail.com",
          "profile": {
            "id": 1,
            "description": "SUPERVISOR",
            "permissions": [
              {
                "id": 2,
                "name": null,
                "codeName": "missions",
                "description": "Missões"
              },
              {
                "id": 4,
                "name": null,
                "codeName": "distribute_tasks",
                "description": "Distribui Tarefa"
              },
              {
                "id": 1,
                "name": null,
                "codeName": "missions_indicators",
                "description": "Indicadores de Missões"
              },
              {
                "id": 3,
                "name": null,
                "codeName": "team_members",
                "description": "Membros da Equipa"
              },
              {
                "id": 5,
                "name": null,
                "codeName": "my_tasks",
                "description": "Minhas Tarefas"
              }
            ]
          },
          "leader": true
        },
        "errors": []
      };
}


export function getMockProfiles () {
  
  return {
      "data": [
        {nome: 'Teste 1', id: 1 },
        {nome: 'Teste 2', id: 2 },
        {nome: 'Teste 3', id: 3 },
        {nome: 'Teste 4', id: 4 },
        {nome: 'Teste 5', id: 5 },
        {nome: 'Teste 6', id: 6 },
        {nome: 'Teste 7', id: 7 },
      ]
    }
}