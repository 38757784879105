import { database } from '../database';
const index_users = 'users';
const index_logged = 'logged';
const index_position = 'geo_position';
const index_polygon = 'polygon';
const index_temp_polygon = 'temp_polygon';
const index_current_polygon = 'current_polygon';
const index_mark_mission = 'mark_mission';
const index_mission = 'mission';
const index_drawing_polygon = 'drawing_polygon';
const index_form_data = 'form_data';
const index_map_open = 'map_open';
const index_email_to_check = 'email_to_check';
const index_status = 'status';
const index_email = 'email';
const index_password = 'password';
const index_credentials = 'credentials';
const db = new database();

export const saveLogged = (logged) => {
  if (!logged) return false;
  db.addCollectionObject(index_users, logged.id, logged)
  db.set(index_logged, logged)
  return logged;
}

export const saveAuthCredentials = (credentials) => {
  if (!credentials) return false;
  db.set(index_credentials, credentials)
  return credentials;
}

export const getAuthCredentials = () => {
  const credentials = db.get(index_credentials)
  return credentials || {}
}

export const saveEmailToCheck = (email) => {
  if (!email) return false;
  db.set(index_email_to_check, email)
  return email;
}

export const getEmailToCheck = () => {
  return db.get(index_email_to_check)
}

export const saveLoggedUser = (logged) => {
  if (!logged) return false;
  db.set(index_logged, logged)
  return logged;
}

export const isLogged = () => {
  return db.get(index_logged);
}

export const logout = () => {
  // db.delete(index_credentials);
  db.delete(index_email_to_check);
  db.delete(index_logged);
  db.delete(index_users);
  // db.delete(index_email);
  // db.delete(index_password);
  localStorage.removeItem("access_token")
  return
}

export const setGeoPosition = (data) => {
  return data ? db.set(index_position, data) : false;
}

export const setCurrentPolygon = (data) => {
  return data ? db.set(index_current_polygon, data) : false;
}

export const getCurrentPolygon = () => {
  return db.get(index_current_polygon);
}

export const resetCurrentPolygon = () => {
  return db.delete(index_current_polygon);
}

export const isMarkMissionMode = () => {
  return db.get(index_mark_mission);
}

export const setMission = (data) => {
  disableMarkZoneMode();
  return data ? db.set(index_mission, data) : false;
}

export const getMission = () => {
  return db.get(index_mission);
}

export const enableMarkZoneMode = (data) => {
  return data ? db.set(index_mark_mission, data) : false;
}

export const disableMarkZoneMode = () => {
  return db.delete(index_mark_mission);
}

export const getGeoPosition = (data) => {
  return db.get(index_position);
}

export const setPolygon = (name) => {
  let temp = db.get(index_temp_polygon);
  if (!name || !temp) return false;
  let rowData = { name: name, polygon: db.get(index_temp_polygon) }
  const polygon = getPolygon();
  let found = false;
  if (polygon) {
    Object.values(polygon).map(pol => {
      if (pol.name == rowData.name) {
        pol.polygon = rowData.polygon;
        found = true;
        return pol;
      }
    })
  }
  if (found) {
    db.set(index_polygon, polygon);
  } else {
    db.addCollectionObject(index_polygon, false, rowData);
  }
  db.delete(index_temp_polygon);
  return true;
}

export const setTempPolygon = (data) => {
  if (!data?.type) data.type = 'polygon';
  //console.log('poly data', data)
  if (isMarkMissionMode()) {
    db.set(index_mission, data);
  }
  return data ? db.addCollectionObject(index_temp_polygon, false, data) : false;
}

export const getPolygonCoords = () => {
  try {
    let polygons = getPolygon();
    let result = [];

    for (let c in polygons) {
      result.push(polygons[c].coords)
    }

    return result;
  } catch (error) {
    //console.log('getPolygonCoords error', error)
  }
}

export const getPolygonData = () => {
  try {

    let polygon = getFirstPolygon().polygon;
    return polygon ? Object.values(polygon)[0] : null;
  } catch (error) {

  }
  return null
}

export const getTempPolygonCoords = () => {
  try {
    let polygons = getTempPolygon();
    if (!polygons) return null;
    let result = [];

    for (let c in polygons) {
      result.push(polygons[c].coords)
    }

    return result || null;
  } catch (error) {
    //console.log('getPolygonCoords error', error)
  }
}

export const getPolygon = () => {
  return db.get(index_polygon);
}

export const setMapOpen = (sinal = true) => {
  return sinal ? db.set(index_map_open, { map: true }) : db.delete(index_map_open);
}

export const isMapOpen = () => {
  return db.get(index_map_open);
}

export const getFirstPolygon = () => {
  let pol = Object.values(db.get(index_polygon));
  pol = Object.values(pol)[0];
  return pol;
}

export const getTempPolygon = () => {
  return db.get(index_temp_polygon);
}

export const setDrawingPolygon = (data) => {
  let old = getDrawingPolygon();
  //console.log('old: ', old, data)
  if (!data[0] || !data[1]) return false;

  for (let i of old) {
    if (data[0] == i[0] && data[1] == i[1]) return old;
  }
  return data ? db.addCollectionObject(index_drawing_polygon, false, data) : false;
}

export const getProfiles = () => {
  return db.get('profiles');
}

export const setProfile = (data) => {
  return data ? db.addCollectionObject('profiles', false, data) : false;
}

export const getDrawingPolygon = () => {
  let tts = db.get(index_drawing_polygon);
  let result1 = [];

  for (let c in tts) {
    if (tts[c][0] && tts[c][0]) result1.push(tts[c])
  }

  return result1;
}

export const addFormData = (data) => {
  let formData = db.get(index_form_data);
  if (formData && data) {
    for (let key of Object.keys(formData)) {
      if (data.form == formData[key].form) {
        formData[key].content.push(data.content);
        return db.set(index_form_data, formData) || true;
      }
    }
  }

  data.content = [data.content];
  return db.addCollectionObject(index_form_data, null, data) || true;
}

export const getFormData = () => {
  return db.getCollectionAll(index_form_data);
}

export const resetFormData = () => {
  return db.delete(index_form_data);
}

export const resetTempPolygon = () => {
  return db.delete(index_temp_polygon);
}

export const getFormList = () => {
  let logged = isLogged();
  if (!logged) return [];
  return logged.forms;
}

export const databaseService = {
  logout,
  saveLogged,
  saveLoggedUser,
  isLogged,
  setGeoPosition,
  getGeoPosition,
  isMarkMissionMode,
  addFormData,
  getFormData,
  resetFormData,
  getTempPolygonCoords,
  resetTempPolygon,
  getFormList,
  setMapOpen,
  isMapOpen,
  getPolygonData,
  setProfile,
  getProfiles,
  saveEmailToCheck,
  getEmailToCheck,
  getAuthCredentials,
  saveAuthCredentials,
};
