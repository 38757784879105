const compareIndex = (index) => {
    return index ?? 0
}
const compareCode = (index) => {
    let number =1
    
    return index ?? number +1
}
const compareCodeIndex = (codigo,index) => {
    
    return codigo ?? index +1
}
module.exports ={
    compareIndex,
    compareCode,
    compareCodeIndex
}